import React,{useState,useEffect} from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { fetchRoles, createRole,updateRole,deleteRole } from '../service/RoleService';
import { useHasPermission } from '../../../util/useHasPermission';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const RoleList = () => {
  const handleError = useErrorHandler()
  const {updateLoader} = useFilterContext()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const [search, setSearch] = useState('');
      const [roles, setRoles] = useState(null);
    const canAddRole = useHasPermission('add_role')
    const canEditRole = useHasPermission('edit_role')
    const canDeleteRole = useHasPermission('delete_role')
    const canAssignRolePermission = useHasPermission('assign_permissions_to_roles') 
    const [modalToggle, setModalToggle] = useState(false);
    const [modalData, setModalData] = useState(null);
    const openModal = (data=null) => { setModalToggle(true);setModalData(data)};
    const closeModal = () => { setModalToggle(false);setModalData(null) };

    const getData = async(search='') => {
        try {
          updateLoader(true)
              const params = {
                'search':search
            }
            const data = await  fetchRoles(params)
            if(data)
            {

              setRoles(data);
            }
        } catch (error) {
          handleError(error)
        }finally{
          updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        getData(search); 
    };

  const clearSearch = () => {
      setSearch('')
      getData(); 
  }

    useEffect(() => {
        if (modalData) {
            reset(modalData);
        } else {
            reset({ name: '' });
        }
    }, [modalData, reset]);



    const onSubmit = async(data) => {
        try {
            updateLoader(true)
            if(modalData)
            {
                data['id'] = modalData.id
            }
            const result = modalData ? await updateRole(data) : await createRole(data);
            if(result)
            {
                result.message && toast.success(result.message)
                closeModal()
                getData()
            }
        } catch (error) {
          handleError(error)
        }finally{
          updateLoader(false)
        }
    }

    const handleDelete = async(id) => {
        const isConfirmed = window.confirm("Are you sure you want to perform this action?");
    
      
        if (isConfirmed) {
            try {
                updateLoader(true)
                const result = await deleteRole(id)
                if(result)
                {
                    result.status && toast.success(result.message)
                    getData()
                }
            } catch (error) {
              handleError(error)
            }finally{
              updateLoader(false)
            }
        } 
    
    }

  return (
    <>
   
      <section>
        <div className='card'>
          <div className='card-body'>
          <div className='row mb-md-4'>
            <div className="col-md-4  mb-3">
              <h3>All Roles</h3>
            </div>
              <div className='col-md-4  mb-3'>
                <div className="input-group search-area">
                  <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                  <span className="input-group-text">
                    <button type="submit" className="btn" onClick={(e) => handleSearchSubmit(e)}>
                      <i className='bx bx-search'></i>
                    </button>
                      
                  </span>
                  {
                    search &&
                    <i className='bx bx-x fs-3 text-danger mt-2 ms-2 cursor-pointer' onClick={(e) => clearSearch(e)}></i>
                  }
                </div>
              </div>
              <div className="col-md-4  mb-3">
                {
                  canAddRole &&
                  <button type="button" className='btn primary-btn float-end'  onClick={() => openModal()}>Add Role</button>
                }
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='table-responsive'>
                  <table className='custom-table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Number of users</th>
                        {
                           canAssignRolePermission && 
                          <th>Assign Role Permissions</th>
                        }
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        
                     {
                        roles && roles.data && roles.data.length > 0 && roles.data.map(item => (

                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.user_count}</td>
                            {
                              canAssignRolePermission &&
                              <td><Link to={"/rolepermission/edit/"+item.id} className='color-primary'>Role Permissions</Link></td>
                            }
                            <td>
                                <div className='action-buttons'>
                                  {
                                    canEditRole && 
                                    <button className='edit-btn' onClick={() => openModal(item)}>
                                    <i className='bx bxs-edit'></i>
                                    </button>
                                  }
                                  {
                                    canDeleteRole && 
                                    <button className='del-btn' type="button" onClick={() => handleDelete(item.id)}>
                                    <i className='bx bxs-trash'></i>
                                    </button>
                                  }
                                </div>
                            </td>
                        </tr>
                        ))
                     }
                    </tbody>
                  </table>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Add Modal */}
      <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Name */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Role Name</label>
                        <input type="text" className="form-control" {...register('name', { required: true})}  />
                        {errors.name && <p className='error-msg'>Name is required</p>}
                    </div>

                    <div className="col-12"><button className="btn primary-btn" type="submit">Save</button></div>

                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default RoleList