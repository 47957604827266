
import { _get, _post, _patch, _delete } from '../Client/apiClient'; 


// Fetch all blogs
export const fetchDashboardData = async (params) => {

    const data = await _get(`/dashboard`, params);
    return data;
  
};




