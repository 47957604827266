import { BrowserRouter } from "react-router-dom"
import Routing from "./routes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routing />
        {/* React Toastify */}
          <ToastContainer />
        {/* END React Toastify */}
      </BrowserRouter>
    
    </>
  );
}

export default App;
