import React, { useState, useEffect,useRef  } from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { server } from '../../../util/Server';
import { toast } from 'react-toastify';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { AwardApplicationDetails,SaveCriteriaWeight, SaveCriteriaRemarks } from '../services/ApplicationService';
import FileUploaded from './inc/FileUploaded';
import MediaFiles from './inc/MediaFiles';
import AssignAwardJudgeModal from './inc/AssignAwardJudgeModal';
import AwardStatusUpdateModal from './inc/AwardStatusUpdateModal';
import { useHasRole } from '../../../util/useHasRole';
import TabEditor from './inc/TabEditor';
import { useErrorHandler } from '../../../hooks/useErrorHandler';


const ApplicationAwardDetails = () => {
    const { user,updateLoader } = useFilterContext()
    const navigate = useNavigate()
    const handleError = useErrorHandler()
    const { awardId, userId, applicationId } = useParams();
    const [award, setAward] = useState(null)
    const [application, setApplication] = useState(null)
    const [applicationUser, setApplicationUser] = useState(null)
    const [criterias, setCriterias] = useState([])
    const [activeTab, setActiveTab] = useState(1)
    const [answers, setAnswers] = useState([])
    const [judges, setJudges] = useState([]);
    const [weights, setWeights] = useState([]);
    const adminRole = useHasRole('admin')
    const superAdminRole = useHasRole('super_admin')
    const judgeRole = useHasRole('Judge')
    const [assessment, setAssessment] = useState(false)
 
  

    const getData = async () => {
        try {
            updateLoader(true)
            const result = await AwardApplicationDetails(awardId,userId,applicationId)
            if(result)
            {
                setAward(result.award)
                setApplication(result.application)
                setCriterias(result.criterias)
                setAnswers(result.answers)
                setApplicationUser(result.user)
                setJudges(result.judges)
                setWeights(result.weights)
            }
            
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {

        getData();
    }, [awardId, userId,applicationId]);

    useEffect(() => {
        if(application && !checkForJudge(application.judge_id) && !adminRole && !superAdminRole)
        {
            navigate('/application/all')
        }
    }, [application]);

    const checkForJudge = (judgeIds) => {
        if(!judgeIds)
        {
          return false
        }
        const judgeArray = judgeIds.split(',').map(id => parseInt(id, 10));
        return judgeArray.includes(user.id);
    }

    const segments = (text) => {
        const splitText = text.split(':').map(segment => segment.trim());
        return "<p class='pt-3 mb-0'>"+splitText[0]+"</p><p >"+(splitText[1] ? splitText[1] : '')+"</p>"; 
    
    } 

    const headingAnswer = (headingId) => {
        const answer = answers.find(answer => 
            answer.heading_id === headingId && 
            answer.application_id === application.id
        );
        return answer ?  answer.text : ''; 
    }


 

    const downloadAwardPdf = async() => {
        try{
            updateLoader(true)
            const url = `${server}/application/pdf/${award.id}/${userId}/${application.id}`;

            // Fetch the PDF file from the endpoint
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/pdf',
                'Authorization': user.token ? `Bearer ${user.token}` : null
                }
            });

            if (!response.ok) {
                toast.error('Unexpected Error')
            }else{

                // Convert the response to a blob
                const blob = await response.blob();
                
                // Create a URL for the blob
                const blobUrl = URL.createObjectURL(blob);
                
                // Create a link element
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = `${award.name}-${application.id}.pdf`; // Set the filename for the download
    
                // Append the link to the document
                document.body.appendChild(link);
                // Programmatically click the link to trigger the download
                link.click();
    
                // Remove the link from the document
                document.body.removeChild(link);
    
                // Revoke the object URL to free up memory
                URL.revokeObjectURL(blobUrl);
            }

        

        } catch (error) {
            toast.error('Error downloading the PDF')
        }finally{
            updateLoader(false)
        }
    }

    const downloadAwardFiles = async() => {
        try{
            updateLoader(true)
            const url = `${server}/application/download-zip/${award.id}/${userId}/${application.id}`;

            // Fetch the PDF file from the endpoint
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                'Authorization': user.token ? `Bearer ${user.token}` : null
                }
            });

            if (!response.ok) {
                toast.error('Unexpected Error')
            }else{

                // Convert the response to a blob
                const blob = await response.blob();
                
                // Create a URL for the blob
                const blobUrl = URL.createObjectURL(blob);
                
                // Create a link element
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = `award-files.zip`; // Set the filename for the download
                // Append the link to the document
                document.body.appendChild(link);
            
                // Programmatically click the link to trigger the download
                link.click();
    
                // Remove the link from the document
                document.body.removeChild(link);
    
                // Revoke the object URL to free up memory
                URL.revokeObjectURL(blobUrl);
            }



        } catch (error) {
            toast.error('Error downloading the PDF')
        }finally{
            updateLoader(false)
        }
    }

    const addCriteriaWeight = async(event, criteriaId) => {
        event.preventDefault();
        const weightRow = weights.find(weight => 
            weight.criteria_id === criteriaId && 
            weight.judge_id === user.id
        );
        if(weightRow && weightRow.weight > 0)
        {
            try {
                updateLoader(true)
                const formData = new FormData()
                formData.append('award_id', awardId)
                formData.append('application_id', applicationId)
                formData.append('criteria_id', criteriaId)
                formData.append('weight', weightRow.weight)
                formData.append('user_id', application.user_id)
                const result = await SaveCriteriaWeight(formData)
                if(result)
                {
                    toast.success(result.message)
                    setWeights(result.weights)
                }
           
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }else{
            toast.error('Please add Weight');
        }
        
    }


    // Handle input changes
    const handleInputChange = (criteriaId, value) => {
        setWeights(prevWeight => {
            // Check if the answer for this heading exists
            const existingAnswerIndex = prevWeight.findIndex(answer =>
                answer.criteria_id === criteriaId && answer.judge_id === user.id
            );
    
            if (existingAnswerIndex !== -1) {
                // Answer exists, so update it
                return prevWeight.map((answer, index) =>
                    index === existingAnswerIndex
                        ? { ...answer, weight: value } // Update the specific answer
                        : answer
                );
            } else {
                // Answer does not exist, so add a new one
                return [
                    ...prevWeight,
                    {
                        criteria_id: criteriaId,
                        judge_id: user.id,
                        weight: value,
                        strengths:'',
                        improvements:''
                    }
                ];
            }
        });
    };

    const judgeCriteriaWeight = (criteriaId,judge_id,type) => {
        const record = weights.find(weight => 
            weight.criteria_id === criteriaId && 
            weight.judge_id === judge_id
        );

       
        return record ? record[type] : ''; 
    }

    const handleEditorChange = (value, criteriaId,judge_id,type) => {
        const data = value;

        setWeights(prevWeight => {
            // Check if the answer for this heading exists
            const existingAnswerIndex = prevWeight.findIndex(answer =>
                answer.criteria_id === criteriaId && answer.judge_id === judge_id
            );
    
            if (existingAnswerIndex !== -1) {
                // Answer exists, so update it
                return prevWeight.map((answer, index) =>
                    index === existingAnswerIndex
                        ? { ...answer, [type]: data } // Update the specific answer
                        : answer
                );
            } else {
                // Answer does not exist, so add a new one
                return [
                    ...prevWeight,
                    {
                        criteria_id: criteriaId,
                        judge_id: judge_id,
                        weight: null,
                        strengths: type == 'strengths' ? data : '',
                        improvements:type == 'improvements' ? data : ''
                    }
                ];
            }
        });
    }

    const saveData = async(criteriaId,judge_id,type,content) => {
        const weightRow = weights.find(weight => 
            weight.criteria_id === criteriaId && 
            weight.judge_id === judge_id
        );
       
        try {
                updateLoader(true)
                const formData = new FormData()
                formData.append('award_id', awardId)
                formData.append('application_id', applicationId)
                formData.append('criteria_id', criteriaId)
                formData.append('user_id', application.user_id)
                if(type == 'strengths')
                {
                    formData.append('strengths', content)
                }
                if(type == 'improvements')
                {
                    formData.append('improvements', content)
                }
                if(type == 'closing_remarks')
                {
                    formData.append('closing_remarks', content)
                }
                // formData.append('improvements', weightRow.improvements || '')
                const result = await SaveCriteriaRemarks(formData)
                if(result)
                {
                    toast.success(result.message)
                    setWeights(result.weights)
                }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    const markAssessmentAsCompleted = async(e,criteriaId,judgeId) => {
        e.preventDefault()
        if(!assessment)
        {
            toast.error('Please mark checkbox to proceed')
        }else{

            try {
                updateLoader(true)
                    const formData = new FormData()
                    formData.append('award_id', awardId)
                    formData.append('application_id', applicationId)
                    formData.append('criteria_id', criteriaId)
                    formData.append('judge_id', judgeId)
                    formData.append('assessment', 1)
                    formData.append('user_id', application.user_id)
                    const result = await SaveCriteriaRemarks(formData)
                    if(result)
                    {
                        toast.success('Assessment marked as completed')
                        setWeights(result.weights)
                    }
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
    }

    

    return (
    <>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <h3>Application  for {award && award.name}</h3>
                    <p className='mb-0'><b>User Name:</b> {applicationUser && applicationUser.name}</p>
                    <p><b>Application Id:</b> {application && application.id}</p>
                </div>
                <div className="col-md-6 mb-4">
                {
                    award &&
                    <>
                    <AwardStatusUpdateModal award={award} appUser={applicationUser} getData={getData} handleError={handleError} updateLoader={updateLoader} />
                    <AssignAwardJudgeModal judges={judges} getData={getData} award={award} handleError={handleError} updateLoader={updateLoader} />
                   
                    <button type="button" onClick={() => downloadAwardFiles()} className='btn primary-btn float-end ms-1 mb-1'>Download Files</button>
                    <button type="button" onClick={() => downloadAwardPdf()} className='btn primary-btn float-end mb-1'>Download PDF</button>
                    </>
                }
               
                </div>

            </div>

            <div className="page">
                <div className="pcss3t pcss3t-effect-scale pcss3t-theme-1">

                    {
                        criterias && criterias.length > 0 && criterias.map((criteria, index)=> {
                            const tabIndex = index + 1;
                            return (<>
                            <input type="radio" name="pcss3t" id={"tab"+(tabIndex)} checked={activeTab == (tabIndex) ? true : false} className={"tab-content-"+(tabIndex)} onChange={() => setActiveTab(tabIndex)} />
                            <label htmlFor={"tab"+(tabIndex)}  onClick={() => setActiveTab((tabIndex))} dangerouslySetInnerHTML={{ __html: segments(criteria.text)}} />
                              
                            </>
                            )
                        })
                    }
                

                    <ul>
                        {
                            criterias && criterias.length > 0 && criterias.map((criteriaRow,index )=> {
                                const tabIndex = index + 1;
                                return(
                                <>
                                 <li className={`tab-content tab-content-${tabIndex} typography`}  key={'tab-data-'+tabIndex}>
                                    {
                                        <>
                                          {/* show form to add weight by judge uf assigned */}
                                          {
                                                judgeRole ? 
                                                <form onSubmit={(e) => addCriteriaWeight(e, criteriaRow.id)}>
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <input type="number" className='form-control' onChange={(event) => handleInputChange(criteriaRow.id,event.target.value)} placeholder='Weight %' value={judgeCriteriaWeight(criteriaRow.id,user.id,'weight')} />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <button type="submit" className='btn primary-btn'>Submit</button>
                                                        </div>
                                                    </div>
                                                </form>

                                                :
                                                <>
                                                    {/* show judge and weight */}
                                                    {
                                                        application && application.judges && application.judges.length > 0 ?
                                                        <>
                                                        <div className='table-responsive'>
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Judge</th>
                                                                        <th>Weight</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        application.judges.map(judge => (
                                                                            <tr>
                                                                                <td>{judge.name} | {judge.email}</td>
                                                                                <td>{judgeCriteriaWeight(criteriaRow.id,judge.id,'weight') ? judgeCriteriaWeight(criteriaRow.id,judge.id,'weight')+'%' : ''}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        </>
                                                        :
                                                        ''
                                                        
                                                    }
                                                    
                                                </>
                                            }
                                        
                                        {criteriaRow.headings && criteriaRow.headings.length > 0 && 
                                        criteriaRow.headings.map(heading => (
                                            <>
                                          
                                            {/* show form to add weight by judge uf assigned */}
                                            <h2>{heading.text}</h2>
                                            <p>Guidance Criteria...</p>
                                            {
                                                heading.questions && heading.questions.length > 0 && heading.questions.map(question => (
                                                    <p className='fw-bold'>{question.number}. {question.text}</p>
                                                ))
                                            }

                                                <div className='answer-div px-4 py-4 mt-4' dangerouslySetInnerHTML={{ __html:headingAnswer(heading.id)}}>
                                                       
                                                </div>
                                            </>
                                        ))}
                                        </>
                                    }

                                    {/* supporting documents */}
                                    <hr />
                                    <h4 className='mb-2'>Supporting Documents </h4>
                                    {
                                        criteriaRow.media.length > 0 &&
                                        <MediaFiles files={criteriaRow.media} />
                                    }
                                    
                                    {/* stregths */}
                                    <hr />
                                    <h4 className='mb-2'>Strengths</h4>
                                    {
                                        judgeRole && 
                                        // <TabCkEditor criteriaId={criteriaRow.id} />
                                        <TabEditor criteriaId={criteriaRow.id} user_id={user.id} type="strengths" saveData={saveData} judgeCriteriaWeight={judgeCriteriaWeight} />
                                    }
                                    {
                                        !judgeRole && application && application.judges && application.judges.length > 0 &&
                                        <>
                                        {
                                            application.judges.map(judge => (
                                                <>
                                                <p className='fw-bold'>Judge: {judge.name} | {judge.email}</p>
                                                <div className='answer-div px-4 py-4 mt-4'>
                                                    <div dangerouslySetInnerHTML={{ __html:judgeCriteriaWeight(criteriaRow.id,judge.id,'strengths')}} />   
                                                </div>
                                                
                                                </>
                                            ))
                                        }
                                        </>
                                    }

                                    <hr />
                                    <h4 className='mb-2'>Potential Improvements</h4>
                                    {
                                        judgeRole && 
                                        // <TabCkEditor criteriaId={criteriaRow.id} />
                                        <TabEditor criteriaId={criteriaRow.id} user_id={user.id} type="improvements" saveData={saveData} judgeCriteriaWeight={judgeCriteriaWeight} />
                                    }
                                    {
                                        !judgeRole && application && application.judges && application.judges.length > 0 &&
                                        <>
                                        {
                                            application.judges.map(judge => (
                                                <>
                                                <p className='fw-bold'>Judge: {judge.name} | {judge.email}</p>
                                                <div className='answer-div px-4 py-4 mt-4'>
                                                    <div dangerouslySetInnerHTML={{ __html:judgeCriteriaWeight(criteriaRow.id,judge.id,'improvements')}} />   
                                                </div>
                                                
                                                </>
                                            ))
                                        }
                                        </>
                                    }

                                
                                    
                                    {
                                        index === criterias.length - 1 ?
                                        <>
                                        <hr />
                                        <h4 className='mb-2'>Closing Remarks</h4>
                                        {
                                            judgeRole && 
                                            <>
                                            <TabEditor criteriaId={criteriaRow.id} user_id={user.id} type="closing_remarks" saveData={saveData} judgeCriteriaWeight={judgeCriteriaWeight} />

                                            <div className="form-check mt-4">
                                            <input className="form-check-input" type="checkbox" value="1" checked={assessment}  onClick={() => setAssessment(!assessment)} id="agree" />
                                            <label className="form-check-label" htmlFor="agree">Mark assessment as completed</label>
                                            </div>
                                            <button className='btn primary-btn mt-3' type="button" onClick={(e) => markAssessmentAsCompleted(e,criteriaRow.id,user.id)}>Submit</button>
                                            </>
                                        }
                                        {
                                            !judgeRole && application && application.judges && application.judges.length > 0 &&
                                            <>
                                            {
                                                application.judges.map(judge => (
                                                    <>
                                                    <p className='fw-bold'>Judge: {judge.name} | {judge.email}</p>
                                                    <div className='answer-div px-4 py-4 mt-4'>
                                                        <div dangerouslySetInnerHTML={{ __html:judgeCriteriaWeight(criteriaRow.id,judge.id,'closing_remarks')}} />   
                                                    </div>
                                                    
                                                    </>
                                                ))
                                            }
                                            </>
                                        }
                                        </>
                                        :
                                        ''
                                    }

                                    <br /><br /><br />
                                    <FileUploaded criteriaId={criteriaRow.id} applicationId={application.id} setCriterias={setCriterias} userId={userId} handleError={handleError} />

                                </li>
                                </>
                                )
                            })
                        }
                       

                        
                    </ul>
            </div>

             
            </div>

    </>
    )
}

export default ApplicationAwardDetails