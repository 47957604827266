import React,{useState,useEffect} from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { fetchPermissions, updatePermission,createPermission,deletePermission } from '../service/PermissionService';
import { useHasPermission } from '../../../util/useHasPermission';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import ReactPaginate from 'react-paginate';

const PermissionList = () => {
    const {updateLoader} = useFilterContext()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const handleError = useErrorHandler()
    const canEditPermission = useHasPermission('edit_permission')
    const canDeletePermission = useHasPermission('delete_permission')
    const canAddPermission = useHasPermission('add_permission') 
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [modalToggle, setModalToggle] = useState(false);
    const [modalData, setModalData] = useState(null);
    const openModal = (data=null) => { setModalToggle(true);setModalData(data)};
    const closeModal = () => { setModalToggle(false);setModalData(null) };


    const getData = async(pageNumber, search='') => {
        try {
            updateLoader(true)
            const params = {
              'page' :pageNumber,
              'search':search
            }
            const result = await fetchPermissions(params);
            if(result)
            {
                setData(result)
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData(page, search);
    }, [page]);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        getData(1, search); 
    };

    const clearSearch = () => {
        setSearch('')
        setPage(1);
        getData(1); 
    }

    useEffect(() => {
        if (modalData) {
            reset(modalData);
        } else {
            reset({ name: '' }); // Reset to default if no modalData
        }
    }, [modalData, reset]);

    const onSubmit = async(data) => {
        try {
            updateLoader(true)
            if(modalData)
            {
                data['id'] = modalData.id
            }
            const result = modalData ? await updatePermission(data) : await createPermission(data);
            if(result)
            {
                result.message && toast.success(result.message)
                reset();
                closeModal()
                getData()
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    const handleDelete = async(id) => {
        const isConfirmed = window.confirm("Are you sure you want to perform this action?");
        if(isConfirmed){
            try {
                updateLoader(true)
                const result = await deletePermission(id);
                if(result)
                {
                    toast.success(result.message)
                    getData()
                }
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
       
    }
  return (
    <>
      <section>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-md-4'>
                <div className="col-md-4 mb-3">
                    <h3>All Permissions</h3>
                </div>
                <div className='col-md-4 mb-3'>
                    <div className="input-group search-area">
                    <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                    <span className="input-group-text">
                        <button type="submit" className="btn" onClick={(e) => handleSearchSubmit(e)}>
                        <i className='bx bx-search'></i>
                        </button>
                        
                    </span>
                    {
                        search &&
                        <i className='bx bx-x fs-3 text-danger mt-2 ms-2 cursor-pointer' onClick={(e) => clearSearch(e)}></i>
                    }
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                {
                    canAddPermission &&
                    <button type="button" className='btn primary-btn float-end'  onClick={() => openModal()}>Add Permission</button>
                }
                </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {/* <h5 className='card-title'>List of Items</h5> */}
                <div className='table-responsive'>
                  <table className='custom-table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th className='text-end'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        
                     {
                        data && data.data && data.data.length > 0 && data.data.map(item => (

                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td className='text-end'>
                                <div className='action-buttons'>
                                    {
                                        canEditPermission &&
                                        <button className='edit-btn' type="button" onClick={() => openModal(item)}>
                                        <i className='bx bxs-edit'></i>
                                        </button>
                                    }
                                    {
                                         canDeletePermission&& 
                                        <button className='del-btn' type="button" onClick={() => handleDelete(item.id)}>
                                        <i className='bx bxs-trash'></i>
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                        ))
                     }
                    </tbody>
                  </table>
                
                </div>
              </div>

              {
                data && data.meta && data.meta.last_page > 1 &&  
                <div className="col-12">
                     <ReactPaginate
                        pageCount={data.meta.last_page}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={(event) => {
                            setPage((event.selected)+1);
                        }}
                        containerClassName={"pagination"}
                        pageClassName={"page-link"}
                        activeClassName={"active"}
                        previousLabel='<'
                        nextLabel='>'
                    />

                   
                </div>
            }
            </div>
          </div>
        </div>
      </section>

      {/* Add Modal */}
      <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{modalData ? 'Edit Permission' : 'Create Permission'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Name */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Permission Name</label>
                        {
                            modalData ? 
                            <input type="text" className="form-control" {...register('name', { required: true})} defaultValue={modalData.name}  />
                            :
                            <input type="text" className="form-control" {...register('name', { required: true})}  />
                        }
                          {errors.name && <p className='error-msg'>Name is required</p>}
                    </div>

                    <div className="col-12"><button className="btn primary-btn" type="submit">Save</button></div>

                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default PermissionList