import React,{useState} from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { fetchFeedback, saveFeedback } from '../../services/UserService';
import DropZone from '../../../../Components/DropZone';
import FilesPreview from '../../../../Components/FilesPreview';
import MediaFiles from './MediaFiles';


const FeedbackModel = ({singleUser,updateLoader,handleError}) => {
    const [images, setImages] = useState([]);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalData, setModalData] = useState([]);
    const closeModal = () => { setModalToggle(false);setModalData([])};
    const openModal = async() => { 
        try {
            updateLoader(true)
            let result = await fetchFeedback({user_id:singleUser.id})
            if(result)
            {
                setModalData(result.data || [])
            }
        } catch (error) {
            handleError(error)
        }finally{
            setModalToggle(true)
            updateLoader(false)
        }
        
    }
   

  
    const handleSubmit = async(e) => {
        e.preventDefault() 
        if(images.length > 0)
        {
            try {
                updateLoader(true)
                const formData = new FormData()
                formData.append('user_id', singleUser.id)
                formData.append('type', 'feedback')
                images.forEach(file => {
                    formData.append('feedback[]', file) 
                });
                const result = await saveFeedback(formData)
                if(result)
                {
                    toast.success(result.message)
                    setImages([])
                    closeModal()
                }
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }else{
            toast.error('Please add at least one file')
        }
    }

  return (
    <>
    
    <button className='edit-btn m-1' type="button"  onClick={() => {openModal(singleUser);}}>
    <i className='bx bxs-message-dots' ></i> Feedback
    </button>


     {/* Add Modal */}
     <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Feedback to User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MediaFiles files={modalData} type="feedback" setModalData={setModalData} updateLoader={updateLoader} handleError={handleError} />
                <form onSubmit={(e) => handleSubmit(e)}>
                    
                    <div className='col-sm-12 mb-3 mt-5'>
                        <label  className="form-label">Upload Feedback</label>
                        <DropZone setComponentFiles={setImages} componentFiles={images} allowedFileTypes={[]} multiple={true} />
                        <small>Only png, jpg, jpeg, pdf, docx are allowed</small>
                        <FilesPreview files={images} setFiles={setImages} />
                          
                    </div>

                    <div className="col-12"><button className="btn primary-btn" type="submit">Save</button></div>

                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default FeedbackModel