import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = ({id,type,userData}) => {
    const getNameInitial = (name) => name.slice(0, 2);
  return (
    <>
        <div className='card'>
            <div className='card-body'>
                <div className="card-inner p-4">
                    <div className="card-user">
                        <div className="user-avatar bg-primary1">
                            <span className='fs-5 text-uppercase'>{userData && getNameInitial(userData.name)}</span>
                        </div>
                        <div className="user-info">
                            <span className="d-block fs-5 fw-bold">{userData && userData.name}</span>
                            <span className="d-block">{userData && userData.email}</span>
                        </div>
                    </div>
                </div>
                {/* card inner */}
                <div className="card-inner p-4">
                    <div className="user-account-info py-0">
                        <h6 className="color-uppercase">Sign Up Date</h6>
                        <div className="color-primary fs-3">{userData && userData.created_at}</div>
                    </div>
                </div>
                {/* card inner */}
                <div className="card-inner p-4">
                    <ul className="link-list-menu">
                        <li className='py-3'><Link className={type === 'personal' ? 'color-primary fw-bold' : ''} to={id ? "/user/detail/"+id : "/user/detail"}>  Personal Information </Link></li>
                        <li className='py-3'><Link className={type === 'business' ? 'color-primary fw-bold' : ''} to={id ? "/user/business/"+id : "/user/business"} >Business Information</Link></li>
                        
                    </ul>
                </div>
                {/* card inner */}
            </div>{/* card body */}
        </div>{/* card */}
    </>
  )
}

export default Sidebar