import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { useFilterContext } from '../../../context/filterContext';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Sidebar from './inc/Sidebar';
import Select from 'react-select'
import { useHasPermission } from '../../../util/useHasPermission';
import { useHasRole } from '../../../util/useHasRole';
import { fetchSingleUserDetail, updateUserPersonalData } from '../services/UserService';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const UserPersonalDetail = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
    const { id } = useParams();
    const handleError = useErrorHandler()
    const {updateLoader} = useFilterContext()
    const canAssignRole = useHasPermission('assign_role_to_user')
    const isSuperAdmin = useHasRole('super_admin')
    const [userData, setUserData] = useState(null);
    const [roles, setUserRoles] = useState([]);
    const [selected, setSelected] = useState([]);
    const [multiselectData, setMultiselectData] = useState([]);

    const getData = async() => {
        try {
            updateLoader(true)
            const result = await fetchSingleUserDetail(id);
            if(result)
            {
                setUserData(result.user)
                setUserRoles(result.roles)
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    

    useEffect(() => {
        const options = roles.map(role => ({
            label: role.name,
            value: role.id
        }));
        setMultiselectData(options)
          
    }, [roles]);

    useEffect(() => {
        if(userData){
            reset({
                name: userData.name || '',
                status: userData.status || 0,
            });

            if(userData.roles.length > 0)
            {
                const selected = userData.roles.map(role => ({
                    label: role.name,
                    value: role.id
                }));
                setSelected(selected)
            }
        }
    }, [userData])

    const onSubmit = async(data) => {
        try {
            updateLoader(true)
            data['id'] = userData.id
            data['roles'] = selected.map(item => item.label);
            const result = await updateUserPersonalData(data);
            if(result)
            {
                result.message && toast.success(result.message)
                reset()
                getData()
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

  return (
    <>
    <section>
   
         <div className='row'>
            <div className="col-md-4">
                <Sidebar id={id} type="personal" userData={userData}  />
            </div>{/* left row */}
            <div className="col-md-8">
                <div className='card'>
                    <div className='card-body px-5'>
                        <h3 className='mb-5'>Personal Information</h3>
                        <form  onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-4 pt-2">
                                 Full Name
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('name', { required: true})} defaultValue={userData && userData.name}  />
                                {errors.name && <p className='error-msg'>Name is required</p>}
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>
                            <div className="col-md-4">
                                 Email
                            </div>
                            <div className="col-md-8">
                                {userData && userData.email}
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>
                            <div className="col-md-4 pt-2">
                                 Change Password
                            </div>
                            <div className="col-md-8">
                                <input type="password" className="form-control"  {...register('password')} placeholder='New Password'  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>
                            {
                                userData && userData.signature &&
                                <>
                                 <div className="col-md-4 pt-2">
                                 Signature
                                </div>
                                <div className="col-md-8">
                                    <img src={userData.signature_path} style={{width:'150px'}} alt="Signature" />
                                </div>
                                <div className="col-12">
                                    <hr className='my-5' />
                                </div>
                                
                                </>
                            }
                            {
                                isSuperAdmin &&
                                <>
                                
                                <div className="col-md-4 pt-2">
                                    Change Status
                                </div>
                                <div className="col-md-8">
                                    <select className='form-control' {...register('status', { required: true})}  defaultValue={userData && userData.status}>
                                        <option value="1">Active</option>
                                        <option value="0">InActive</option>
                                    </select>
                                    {errors.status && <p className='error-msg'>Status is required</p>}
                                </div>
                                <div className="col-12 my-4">
                                </div>
                                </>
                            }
                            {
                                    canAssignRole  &&
                                    <>
                                    
                                        <div className="col-md-4 pt-2">
                                            User Roles
                                        </div>
                                        <div className="col-md-8">
                                                <Select
                                                    options={multiselectData}
                                                    value={selected}
                                                    onChange={setSelected}
                                                    labelledBy="Select"
                                                    isMulti 
                                                />
                                        </div>
                                    </>
                            }
                            <div className="col-12 my-4"><button className="btn primary-btn" type="submit">Save</button></div>
                        </div>{/* form row */}
                        </form>
                    </div>{/* card body */}
                </div>{/* card */}
            </div>{/* right row */}
           
         </div>{/* row */}
     
 </section>

</>
  )
}

export default UserPersonalDetail