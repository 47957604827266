import React, {useState } from 'react'
import NavLinks from './NavLinks'

const Sidebar = ({setMobileSideBar,authUser}) => {
    const [openMenu, setOpenMenu] = useState(true)
   
    return (
        <>
            <div className={openMenu ? "sidebar" : "sidebar close"}>
                <div className="logo-details">
                    <i className='bx bx-menu cursor-pointer' onClick={() => setOpenMenu(!openMenu)}></i>
                    <span className="logo_name"><img src="/icon.png" alt="HBC" height="30px" /> Dashboard</span>
                </div>
                <NavLinks setMobileSideBar={setMobileSideBar} authUser={authUser} />
            </div>
        </>
    )
}

export default Sidebar