import React,{useState,useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const TaxModal = ({taxType,setTaxType,taxTypeOne,setTaxTypeOne,taxTypeTwo,setTaxTypeTwo,taxOneTotal,taxTwoTotal}) => {
    const {
        register,
        handleSubmit,
        reset
      } = useForm();

    useEffect(() => {
        reset({ taxType: taxType, 
            tax_1_code: Object.keys(taxTypeOne).length > 0 ? taxTypeOne.code : '', 
            tax_1_rate: Object.keys(taxTypeOne).length > 0 ? taxTypeOne.rate : '',

            tax_2_code: Object.keys(taxTypeTwo).length > 0 ? taxTypeTwo.code : '', 
            tax_2_rate: Object.keys(taxTypeTwo).length > 0 ? taxTypeTwo.rate : ''
        
        })
    }, [taxType,taxTypeOne,taxTypeTwo,reset]);
    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false)};
    

    const onSubmitSub = (data, e) => {
        e.preventDefault(); // Prevents the form from submitting the parent form
        setTaxType(data['taxType'])
        if(data['tax_1_code'] === '' || data['tax_1_code'] === '')
        {
            setTaxTypeOne({});
        }else{

            setTaxTypeOne(prevState => ({
                ...prevState,
                code: data['tax_1_code'] !== undefined ? data['tax_1_code'] : prevState.code,
                rate: data['tax_1_rate'] !== undefined ? data['tax_1_rate'] : prevState.rate,
            }));
        }

        if(data['tax_2_code'] === '' || data['tax_2_code'] === '')
        {
            setTaxTypeTwo({});
        }else{

            setTaxTypeTwo(prevState => ({
                ...prevState,
                code: data['tax_2_code'] !== undefined ? data['tax_2_code'] : prevState.code,
                rate: data['tax_2_rate'] !== undefined ? data['tax_2_rate'] : prevState.rate,
                }));
        }
     
        closeModal()
      
    }
  return (
    <>
    <div className="row">
        {
            (Object.keys(taxTypeOne).length === 0 && Object.keys(taxTypeTwo).length === 0) &&
            <>
            <div className="col-6">
                <p className="mb-2 cursor-pointer color-primary" onClick={() => openModal()}>Tax 0%</p>
            </div>
            <div className="col-6">
                <p className="mb-2 text-end">$0</p>
            </div>
            </>
        }
        {
            Object.keys(taxTypeOne).length > 0 &&
            <>
            <div className="col-6">
                <p className="mb-2 cursor-pointer color-primary" onClick={() => openModal()}>{taxTypeOne.code} {taxTypeOne.rate}%</p>
            </div>
            <div className="col-6">
                <p className="mb-2 text-end">${taxOneTotal}</p>
            </div>
            </> 
        }
        {
            Object.keys(taxTypeTwo).length > 0 &&
            <>
            <div className="col-6">
                <p className="mb-2 cursor-pointer color-primary" onClick={() => openModal()}>{taxTypeTwo.code} {taxTypeTwo.rate}%</p>
            </div>
            <div className="col-6">
                <p className="mb-2 text-end">${taxTwoTotal}</p>
            </div>
            </> 
        }
    </div>
    
    
    {/* Add Modal */}
    <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Tax Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="row">
                        {/* Name */}
                        <div className='col-md-12 mb-3'>
                            <label  className="form-label">Tax Type</label>
                            <select  {...register('taxType')} className='form-control'>
                                <option value="1">Inclusive</option>
                                <option value="0">Exclusive</option>
                            </select>    
                        </div>

                        <div className="col-12 mb-3">
                            <h3>First Tax Type</h3>
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Tax Code</label>
                            <input type="text" {...register('tax_1_code')} className='form-control' />
                            
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Rate (%)</label>
                            <input type="text" {...register('tax_1_rate')} className='form-control' />
                            
                        </div>

                        <div className="col-12 mb-3">
                            <h3>Second Tax Type</h3>
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Tax Code</label>
                            <input type="text" {...register('tax_2_code')} className='form-control' />
                            
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Rate (%)</label>
                            <input type="text" {...register('tax_2_rate')} className='form-control' />
                            
                        </div>


                        <div className="col-12"><button className="btn primary-btn" type="button"  onClick={handleSubmit(onSubmitSub)}>Save</button></div>
                    </div>


                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default TaxModal