
import { _get, _post, _patch, _delete } from '../../../Client/apiClient'; 


// Fetch all Team
export const fetchJudges = async (params) => {
   
        const data = await _get(`/judges_all`, params);
        return data;
   
};

export const fetchApplications = async (params) => {

        const data = await _get(`/application/all`, params);
        return data;
    
};

export const fetchJudgeApplications = async (id, params) => {
   
        const data = await _get(id && id > 0 ? `/application/applications_judge/${id}` : `/application/applications_judge`, params);
        return data;
   
};




export const fetchApplicationFilterData = async (params) => {
 
        const data = await _get(`/miscellaneous`, params);
        return data;
   
};


//judge application approve
export const JudgeApplicationApprove = async (id) => {
  
        const data = await _post(`/judge_approve/${id}`,{});
        return data;
  
};

//appoint judge to application
export const AppointApplicationJudge = async (formData) => {
   
        const data = await _post(`/appoint_judge`,formData);
        return data;
   
};

//update application status
export const updateApplicationStatus = async (formData) => {

        const data = await _post(`/application/status_update`,formData);
        return data;
   
};

//judge application approve
export const SaveCriteriaWeight = async (formData) => {
  
        const data = await _post(`/application/weight`,formData);
        return data;
   
};

export const SaveCriteriaRemarks = async (formData) => {
  
        const data = await _post(`/application/boxes`,formData);
        return data;
   
};

//judge application reject
export const CriteriaHeadingAnswerStore = async (formData) => {
  
        const data = await _post(`/application/apply/answer/store`, formData);
        return data;
   
};

//judge application reject
export const SubmitAwardApplication = async (formData) => {
   
        const data = await _post(`/application/apply/post`, formData);
        return data;
    
};

//judge application reject
export const SubmitAwardApplicationCriteriaFiles = async (formData) => {
  
        const data = await _post(`/application/apply/files/store`, formData);
        return data;
   
};

//judge application reject
export const AppointAwardJudges = async (formData) => {
 
        const data = await _post(`/appoint_judge_award`, formData);
        return data;
    
};

//judge application reject
export const UpdateAwardStatus = async (formData) => {
  
        const data = await _post(`/application/award_status_update`, formData);
        return data;
   
};


//award application details
export const AwardApplicationDetails = async (awardId,userId,applicationId) => {
   
        const data = await _get(`/application/award/${awardId}/${userId}/${applicationId}`);
        return data;
   
};

//award application apply details
export const AwardApplicationApply = async (awardId,applicationId,params) => {
    
        const data = await _get(`/application/apply/${awardId}/${applicationId}`, params);
        return data;
   
};

export const applyForAward = async (formData) => {
        const data = await _post(`/apply-for-awards`, formData);
        return data;
};



