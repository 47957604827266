import React, { useRef, useEffect,useState,useCallback } from 'react';
import JoditEditor from 'jodit-react';

const AwardApplyTabEditor = ({ headingId,headingAnswer,saveAnswerOnBlur }) => {
    const editorRef = useRef(null);
  

    const saveEditorDataOnBlur = (newContent) => {
        if (typeof saveAnswerOnBlur === 'function') {
            saveAnswerOnBlur(headingId,newContent)
        } else {
            console.error('saveData is not a function');
        }
       
    }

    const config = {
        readonly: false,
        height: 'auto',
            toolbarButtonSize: "small",
            showTooltip:false,
            removeButtons: ['hr', 'source','spellcheck','speechRecognize','aiCommands','ai_assisstant']
    };

    return (
        <>
            <JoditEditor
                ref={editorRef}
                value={headingAnswer(headingId)}
                // onChange={onChange}
                config={config}
                onBlur={newContent => saveEditorDataOnBlur(newContent)} 
            />
        </>
    );
};


export default AwardApplyTabEditor