
import { _get, _post } from '../../../Client/apiClient'; 


export const fetchUsers = async (params) => {
   
        const data = await _get(`/user/all`, params);
        return data;
   
};


export const fetchFeedback = async (params) => {
  
        const data = await _get(`/feedback/user`, params);
        return data;
   
};

export const fetchCertificates = async (params) => {
   
        const data = await _get(`/feedback/certificates`, params);
        return data;
   
};

export const fetchPressReleases = async (params) => {

        const data = await _get(`/feedback/pressRelease`, params);
        return data;
    
};


export const fetchSingleUserDetail = async (id=null) => {
   
        const data = await _get(id ? `/user/detail/${id}` : `/user/detail`);
        return data;
   
};



export const saveFeedback = async (formdata) => {
    
        const data = await _post(`/feedback/add`, formdata);
        return data;
   
};

export const updateUserPersonalData = async (formdata) => {
    
        const data = await _post(`/user/update`, formdata);
        return data;
    
};

export const updateUserBusinessData = async (formdata) => {
 
        const data = await _post(`/user/business/update`, formdata);
        return data;

   
};

export const DeleteFeedback = async (id) => {
    
        const data = await _post(`/feedback/delete/${id}`, {});
        return data;
  
};





