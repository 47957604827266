import React,{useState,useEffect} from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { Link } from 'react-router-dom';
import StatusUpdateModal from './inc/StatusUpdateModal';
import NewAwardApplicationModal from './inc/NewAwardApplicationModal';
import AssignJudgeModal from './inc/AssignJudgeModal';
import { fetchApplications,fetchApplicationFilterData,fetchJudgeApplications } from '../services/ApplicationService';
import { useHasRole } from '../../../util/useHasRole';
import { useHasPermission } from '../../../util/useHasPermission';
import { useParams } from 'react-router-dom';
import ApplicationFilterBar from './inc/ApplicationFilterBar';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import ReactPaginate from 'react-paginate';
import { buildQueryString } from '../../../Client/apiClient';
import { server } from '../../../util/Server';
import { toast } from 'react-toastify';
const ApplicationList = () => {
    const {user,updateLoader} = useFilterContext()
    const {id} = useParams()
    const handleError = useErrorHandler()
    const [applications, setApplications] = useState(null);
    const [awards, setAwards] = useState([]);
    const [judges, setJudges] = useState([]);
    const [countries, setCountries] = useState([]);
    const [search, setSearch] = useState({});
    const [page, setPage] = useState(1);
    const userRole = useHasRole('user')
    const adminRole = useHasRole('admin')
    const judgeRole = useHasRole('Judge')
    const superAdminRole = useHasRole('super_admin')
    const canAppointJudge = useHasPermission('appointment_judge')
   


    const getData = async(pageNumber, search={}) => {
        try {
          updateLoader(true)
            const searchParams = { ...search, page: pageNumber };
            const result = judgeRole || id ? await fetchJudgeApplications(id ? id : 0, searchParams) : await fetchApplications(searchParams);
            if(result)
            {
              setApplications(result)
            }
        } catch (error) {
          handleError(error)
        }finally{
          updateLoader(false)
        }
    }

  

    const getFilterData = async() => {
        try {
          const params = {
              'awards' : true,
              'judges' : true,
              'countries': true
          }
          const result = await fetchApplicationFilterData(params);
          if(result)
          {
            setAwards(result.awards)
            setJudges(result.judges)
            setCountries(result.countries)
          }
      } catch (error) {
        handleError(error)
      }
    }

    useEffect(() => {
        getData(page);
        getFilterData()
    }, []);

    useEffect(() => {
      setPage(1)
      getData(1,search);
  }, [search]);

  useEffect(() => {
    getData(page, search);
  }, [page]);

    const checkForJudge = (judgeIds) => {
      if(!judgeIds)
      {
        return false
      }
      const judgeArray = judgeIds.split(',').map(id => parseInt(id, 10));
      return judgeArray.includes(user.id);
    }

    const downloadExcel = async() => {
          try{
            updateLoader(true)
            const searchParams = buildQueryString(search)
            const url = `${server}/application/download-excel${searchParams}`;

            // Fetch the PDF file from the endpoint
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                'Authorization': user.token ? `Bearer ${user.token}` : null
                }
            });

            if (!response.ok) {
                toast.error('Unexpected Error')
            }else{

              // Convert the response to a blob
              const blob = await response.blob();
              
              // Create a URL for the blob
              const blobUrl = URL.createObjectURL(blob);
              
              // Create a link element
              const link = document.createElement('a');
              link.href = blobUrl;
              link.download = `applications.csv`; // Set the filename for the download
  
              // Append the link to the document
              document.body.appendChild(link);
          
              // Programmatically click the link to trigger the download
              link.click();
              // Remove the link from the document
              document.body.removeChild(link);
  
              // Revoke the object URL to free up memory
              URL.revokeObjectURL(blobUrl);
            }



        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

   
  return (
    <>
   
      <section>
        <div className='card'>
          <div className='card-body'>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <h3>All Applications</h3>
                    {
                      userRole &&<p className="color-primary">Please click on the award name to fill out the application</p>
                    }
                </div>
                <div className="col-md-6 mb-4">
                  {
                    userRole &&
                  <NewAwardApplicationModal awards={awards} authUser={user} updateLoader={updateLoader} getData={getData} handleError={handleError} />
                  }
                  {
                      (adminRole || superAdminRole) &&
                      <button type="button" onClick={() => downloadExcel()} className='btn primary-btn float-end ms-1'>Download Excel</button>

                  }
                </div>
                
            </div>
            <ApplicationFilterBar setSearch={setSearch} countries={countries} awards={awards} />
            <div className='row'>
              <div className='col-12'>
                {/* <h5 className='card-title'>List of Items</h5> */}
                <div className='table-responsive'>
                  <table className='custom-table'>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Awards</th>
                        <th>Apply Date</th>
                        {
                          canAppointJudge &&
                          <th>Judge</th>
                        }
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                        
                    {
                        applications && applications.data && applications.data.length > 0 && applications.data.map(item => (

                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>
                                {
                                    item.awards && item.awards.length > 0 &&  item.awards.map(award => (
                                        <Link target="_blank" to={checkForJudge(item.judge_id) || adminRole || superAdminRole ? "/application/award/"+award.id+"/"+item.user_id+"/"+item.id :   "/application/apply/"+award.id+"/"+item.id} key={award.id}><span className={`badge ${award.application_award_status && award.application_award_status == 1 ? 'bg-success' : 'bg-primary1'} m-1 p-2`} key={award.id}>{award.name}</span></Link>
                                    ))
                                }
                            </td>
                            <td>{item.created_at}</td>
                            {
                              canAppointJudge &&
                              <td>
                              <AssignJudgeModal judges={judges} application={item} getData={getData} updateLoader={updateLoader} handleError={handleError} />
                              </td>
                            }
                            <td>
                              <StatusUpdateModal application={item} getData={getData} updateLoader={updateLoader} handleError={handleError} />
                            </td>
                          
                        </tr>
                        ))
                     }
                    </tbody>
                  </table>
                
                </div>
              </div>
              {
                applications && applications.meta && applications.meta.last_page > 1 &&   
                <div className="col-12">
                      <ReactPaginate
                        pageCount={applications.meta.last_page}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={(event) => {
                            setPage((event.selected)+1);
                        }}
                        containerClassName={"pagination"}
                        pageClassName={"page-link"}
                        activeClassName={"active"}
                        previousLabel='<'
                        nextLabel='>'
                    />
                </div>
            }
            </div>
          </div>
        </div>
      </section>

     
    </>
  )
}

export default ApplicationList