import axios from 'axios';
import { server } from '../util/Server';

const BASE_URL = server;

// Create an Axios instance
const apiClient = axios.create({
  baseURL: BASE_URL,
  // headers: {
  //     'Content-Type': 'application/json',
  //     "Content-Type": "multipart/form-data"
  // },
});

// Function to get the token (you may need to adapt this to your actual token management)
const getToken = () => {
  const user = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
  if(user && user.token)
  {
    return user.token
  }
  
  return null
};

// Add a request interceptor to include the Bearer token
apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    // } else {
    //   config.headers['Content-Type'] = 'application/json';
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to construct query string
const buildQueryString = (params) => {
    // Filter out parameters with empty string values
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== '')
    );

    const query = new URLSearchParams(filteredParams).toString();
    return query ? `?${query}` : '';
};

// Define common API methods with error handling
const _get = async (url, params={}, config = {}) => {

    const queryString = buildQueryString(params);
    const response = await apiClient.get(`${url}${queryString}`, config);
    return response.data;

};

const _delete = async (url, config = {}) => {

    const response = await apiClient.delete(url, config);
    return response.data;

};

const _put = async (url, data = {}, config = {}) => {
 
    const response = await apiClient.put(url, data, config);
    return response.data;
  
};
const _patch = async (url, data = {}, config = {}) => {
 
      const response = await apiClient.patch(url, data, config);
      return response.data;
 
};

const _post = async (url, data = {}, config = {}) => {
 
      const response = await apiClient.post(url, data, config);
      return response.data;
  
};



// Export API methods
export { _get, _delete, _put, _post, _patch,buildQueryString };
