import React,{useEffect} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import axios from "axios";
import { server } from '../../../util/Server';
import { useFilterContext } from '../../../context/filterContext';
import { baseUrl } from '../../../util/Server';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import useReCaptcha from '../../../hooks/useReCaptcha';


const LoginForm = () => {
    const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha()

    const handleError = useErrorHandler()
    const navigate = useNavigate();
    const { setUserLogin, user, updateLoader } = useFilterContext();
 
    useEffect(() => {
        if (user) {
          navigate("/dashboard");
        }
    }, [user]);

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

    const onSubmit = async(data) => {
        try {
            updateLoader(true)
            const token = await generateReCaptchaToken('login_action');
            data['g-recaptcha'] = token
            const res = await axios.post(server + "/auth/login", data);
            const result = await res.data
            if(result)
            {

                setUserLogin(result);
                navigate("/dashboard");
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }
      
  return (
    <>
        <div className="fix-wrapper">
            <div className="container">
               
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6">
                        <Link to={`${baseUrl}`}> <img src="/icon.png" width="60px" className='d-block m-auto mb-3' /></Link>
                       
                        <iframe src="https://player.vimeo.com/video/730313839?h=81cee24b94" style={{width: "110%"}} height="300" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                        <div className="login-form  mb-0 h-auto pb-3 pt-3 mt-3">
                            <div className="px-5">
                                <h4 className="text-center mb-0">Sign-In</h4>
                                <p className='text-center mb-4'>Access your dashboard using your email and passcode.</p>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Email</label>
                                        <input type="email" className="form-control" placeholder="Enter your email address" {...register('email', { required: true})} />
                                        {errors.email && <p className='error-msg'>Email is required</p>}
                                    </div>
                                    <div className="mb-sm-4 mb-3 position-relative">
                                        <label className="form-label" >Passcode</label>
                                        <input type="password" placeholder='Enter your passcode' className="form-control" {...register('password', { required: true})} />
                                        {errors.password && <p className='error-msg'>Passcode is required</p>}
                                    </div>
                                    <div className="form-row d-flex flex-wrap justify-content-between mb-2">
                                        {/* <div className="form-group mb-sm-4 mb-1">
                                            <div className="form-check custom-checkbox ms-1">
                                                <input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
                                                <label className="form-check-label" for="basic_checkbox_1">Show Password</label>
                                            </div>
                                        </div> */}
                                        <div className="form-group ms-2">
                                            <Link className="text-hover" to="/password/reset">Forgot Passcode?</Link>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" disabled={!reCaptchaLoaded} className="btn primary-btn w-100">Sign In</button>
                                    </div>
                                    <p className='text-center mt-3'>New on our platform? <Link to="/register" className='color-primary'>Create an account</Link></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LoginForm