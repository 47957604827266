import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {  addOrUpdateCustomer } from '../../services/InvoiceService';

const UpdateCustomerModal = ({updateLoader,setInvoiceCustomer,handleError,invoiceCustomer,setDisplayCustomerList}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

    useEffect(() => {
        if(invoiceCustomer)
        {
            reset({name:invoiceCustomer.name,
                email:invoiceCustomer.email,
                mobile:invoiceCustomer.mobile,
                business_phone:invoiceCustomer.business_phone,
                addition_information:invoiceCustomer.addition_information,
                business_address:invoiceCustomer.business_address,
                postal_code:invoiceCustomer.postal_code,
                city:invoiceCustomer.city,
                country:invoiceCustomer.country
            })
        }
    }, [invoiceCustomer])
    

    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false)};
    
    const onSubmitCustomerItem = async(data, e) => {
        e.preventDefault(); // Prevents the form from submitting the parent form
        try {
            updateLoader(true)
            data['id'] = invoiceCustomer.id
            const result = await addOrUpdateCustomer(data);
            if(result)
            {
                result.message && toast.success(result.message)
                reset();
                closeModal()
                result.user && setInvoiceCustomer(result.user);

            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    const chooseFromExisting = () => {
        setInvoiceCustomer(null)
        setDisplayCustomerList(true)
        closeModal()
    }

  return (
    <>
    <a onClick={() => openModal()}   className="ml-2 text-primary cursor-pointer text-14"> Change</a>
    

    {/* Add Modal */}
    <Modal show={modalToggle} onHide={() => closeModal()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Update Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form >
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <label for="" className="mb-0">Name</label>
                            <input type="text" {...register('name',{required:true})} className="form-control" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <label for="" className="mb-0">Email</label>
                            <input type="email" {...register('email',{required:true})} className="form-control" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <label for="" className="mb-0">Phone Number</label>
                            <input type="text" className="form-control" {...register('mobile')} />
                        </div>
                        <div className="col-md-6 mb-2">
                            <label for="" className="mb-0">Business Number</label>
                            <input type="text" className="form-control" {...register('business_phone')}  />
                        </div>
                        <div className="col-12 mb-2">
                            <label for="" className="mb-0">Additional information</label>
                            <textarea  {...register('addition_information')} className="form-control"></textarea>
                        </div>

                        <div className="col-12 mb-3">
                            <h5>Address</h5>
                        </div>

                        <div className="col-md-8 mb-2">
                            <label for="" className="mb-0">Address 1</label>
                            <input type="text" className="form-control" {...register('business_address')} />
                        </div>
                        <div className="col-md-4 mb-2">
                            <label for="" className="mb-0">Postal Code</label>
                            <input type="text" className="form-control"  {...register('postal_code')} />
                        </div>
                        <div className="col-md-6 mb-2">
                            <label for="" className="mb-0">City</label>
                            <input type="text" className="form-control"  {...register('city')} />
                        </div>
                        <div className="col-md-6 mb-2">
                            <label for="" className="mb-0">country</label>
                            <input type="text" className="form-control"  {...register('country')} />
                        </div>

            

                        <div className="col-md-6 mt-3"><button className="btn primary-btn" type="button" onClick={handleSubmit(onSubmitCustomerItem)}>Update</button></div>
                        <div className="col-md-6 text-end mt-3"><button className="btn primary-btn-outline" type="button" onClick={() => chooseFromExisting()}>Choose Existing Customer</button></div>
                    </div>


                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}


export default UpdateCustomerModal