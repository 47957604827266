import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../Dashboard/Main'
import Header from '../Components/layout/Header'
import LoginForm from '../modules/auth/components/LoginForm'
import AuthCheck from './AuthCheck'
import { useFilterContext } from '../context/filterContext'
import ForgetPassword from '../modules/auth/components/ForgetPassword'
import RoleList from '../modules/role/components/RoleList'
import RolePermission from '../modules/role/components/RolePermission'
import PermissionList from '../modules/permission/components/PermissionList'
import UserList from '../modules/user/components/UserList'
import UserPersonalDetail from '../modules/user/components/UserPersonalDetail'
import UserBusinessInformation from '../modules/user/components/UserBusinessInformation'
import CriteriaGuideBook from '../modules/criteriaguidebook/components/CriteriaGuideBook'
import AwardsList from '../modules/awards/components/AwardsList'
import AddAward from '../modules/awards/components/AddAward'
import EditAward from '../modules/awards/components/EditAward'
import AwardCriteria from '../modules/awards/components/AwardCriteria'
import ApplicationList from '../modules/application/components/ApplicationList'
import ApplicationAwardApply from '../modules/application/components/ApplicationAwardApply'
import BlogList from '../modules/blog/components/BlogList'
import BlogAdd from '../modules/blog/components/BlogAdd'
import BlogEdit from '../modules/blog/components/BlogEdit'
import NewsList from '../modules/news/components/NewsList'
import NewsAdd from '../modules/news/components/NewsAdd'
import NewsEdit from '../modules/news/components/NewsEdit'
import TeamList from '../modules/team/components/TeamList'
import TeamAdd from '../modules/team/components/TeamAdd'
import TeamEdit from '../modules/team/components/TeamEdit'
import JudgesList from '../modules/judge/components/JudgesList'
import ApplicationAwardDetails from '../modules/application/components/ApplicationAwardDetails'
import PermissionDenied from '../errors/PermissionDenied'
import UserFeedback from '../modules/user/components/UserFeedback'
import UserCertificates from '../modules/user/components/UserCertificates'
import UserPressReleases from '../modules/user/components/UserPressReleases'
import AddInvoice from '../modules/invoices/components/AddInvoice'
import Loader from '../Components/Loader'
import ResetPassword from '../modules/auth/components/ResetPassword'
import NotFound from '../errors/NotFound'
import EditInvocie from '../modules/invoices/components/EditInvocie'
import InvoiceList from '../modules/invoices/components/InvoiceList'
import ArchivedInvoicesList from '../modules/invoices/components/ArchivedInvoicesList'
import InvoicePreview from '../modules/invoices/components/InvoicePreview'
import RegistrationForm from '../modules/auth/components/RegistrationForm'



const Routing = () => {
    const { user } = useFilterContext();


    return (
        <>
         <Loader />
        {
            user &&
            <>
                <Header />
               
            </> 
        }
            <div className={user && 'home-section'}>
                <Routes>
                    <Route path="/" element={<LoginForm />} />
                    <Route path="/register" element={<RegistrationForm />} />
                    <Route path="/password/reset" element={<ForgetPassword />} />
                    <Route path="/reset-password/:code" element={<ResetPassword />} />
                    <Route path="/dashboard" element={
                        <AuthCheck user={user} permission="no_permission">
                            <Dashboard />
                        </AuthCheck>
                    } />
                    <Route path="/roles" element={
                        <AuthCheck user={user} permission="view_roles">
                            <RoleList />
                        </AuthCheck>
                    } />
                    <Route path="/rolepermission/edit/:id" element={
                        <AuthCheck user={user} permission="assign_permissions_to_roles">
                            <RolePermission />
                        </AuthCheck>
                    } />
                    <Route path="/permissions" element={
                        <AuthCheck user={user} permission={'view_permissions'}>
                            <PermissionList />
                        </AuthCheck>
                    } />
                    <Route path="/users" element={
                        <AuthCheck user={user} permission={'view_users'}>
                            <UserList />
                        </AuthCheck>
                    } />
                     <Route path="/user/detail/:id?" element={
                        <AuthCheck user={user} permission={'no_permission'}>
                            <UserPersonalDetail />
                        </AuthCheck>
                    } />
                     <Route path="/user/business/:id?" element={
                        <AuthCheck user={user} permission={'no_permission'}>
                            <UserBusinessInformation />
                        </AuthCheck>
                    } />

                    <Route path="/criteriaguidebook" element={
                        <AuthCheck user={user} permission="no_permission">
                            <CriteriaGuideBook />
                        </AuthCheck>
                    } />

                    <Route path="/awards" element={
                        <AuthCheck user={user} permission={'view_awards'}>
                            <AwardsList />
                        </AuthCheck>
                    } />
                     <Route path="/award/add" element={
                        <AuthCheck user={user} permission={'add_award'}>
                            <AddAward />
                        </AuthCheck>
                    } />
                    <Route path="/award/edit/:id" element={
                        <AuthCheck user={user} permission={'edit_award'}>
                            <EditAward />
                        </AuthCheck>
                    } />
                    <Route path="/award/criteria/:id" element={
                        <AuthCheck user={user} permission={'criteria'}>
                            <AwardCriteria />
                        </AuthCheck>
                    } />

                    {/* application */}
                    <Route path="/application/all" element={
                        <AuthCheck user={user} permission={'view_applications'}>
                            <ApplicationList />
                        </AuthCheck>
                    } />
                    <Route path="/application/apply/:awardId/:applicationId" element={
                        <AuthCheck user={user} permission="no_permission">
                            <ApplicationAwardApply />
                        </AuthCheck>
                    } />
                    <Route path="/application/award/:awardId/:userId/:applicationId" element={
                        <AuthCheck user={user} permission="no_permission">
                            <ApplicationAwardDetails />
                        </AuthCheck>
                    } />
                    <Route path="/application/applications_judge/:id?" element={
                        <AuthCheck user={user} permission="no_permission">
                            <ApplicationList />
                        </AuthCheck>
                    } />
                    
                    {/* invocies */}
                    <Route path="/invoice" element={
                        <AuthCheck user={user}  permission={'view_invoices'}>
                            <InvoiceList />
                        </AuthCheck>
                    } />
                    <Route path="/invoice/archive/list" element={
                        <AuthCheck user={user}  permission={'view_invoices'}>
                            <ArchivedInvoicesList />
                        </AuthCheck>
                    } />
                    <Route path="/invoice/create" element={
                        <AuthCheck user={user}  permission={'add_invoices'}>
                            <AddInvoice />
                        </AuthCheck>
                    } />

                    <Route path="/invoice/:id" element={
                        <AuthCheck user={user}  permission={'add_invoices'}>
                            <InvoicePreview />
                        </AuthCheck>
                    } />
                    <Route path="/invoice/:id/edit" element={
                        <AuthCheck user={user}  permission={'edit_invoice'}>
                            <EditInvocie />
                        </AuthCheck>
                    } />

                    {/* blogs */}
                    <Route path="/blog" element={
                        <AuthCheck user={user} permission={'view_blogs'}>
                            <BlogList />
                        </AuthCheck>
                    } />
                    <Route path="/blog/create" element={
                        <AuthCheck user={user}  permission={'add_blog'}>
                            <BlogAdd />
                        </AuthCheck>
                    } />
                    <Route path="/blog/edit/:id" element={
                        <AuthCheck user={user} permission={'edit_blog'}>
                            <BlogEdit />
                        </AuthCheck>
                    } />


                    {/* News */}
                    <Route path="/news" element={
                        <AuthCheck user={user} permission={'view_news'}>
                            <NewsList />
                        </AuthCheck>
                    } />
                    <Route path="/news/create" element={
                        <AuthCheck user={user} permission={'view_news'}>
                            <NewsAdd />
                        </AuthCheck>
                    } />
                    <Route path="/news/edit/:id" element={
                        <AuthCheck user={user} permission={'view_news'}>
                            <NewsEdit />
                        </AuthCheck>
                    } />

                    {/* Team */}
                    <Route path="/team" element={
                        <AuthCheck user={user} permission={'view_teams'}>
                            <TeamList />
                        </AuthCheck>
                    } />
                    <Route path="/team/create" element={
                        <AuthCheck user={user} permission={'add_team'}>
                            <TeamAdd />
                        </AuthCheck>
                    } />
                    <Route path="/team/edit/:id" element={
                        <AuthCheck user={user} permission={'edit_team'}>
                            <TeamEdit />
                        </AuthCheck>
                    } />

                    <Route path="/judges_all" element={
                        <AuthCheck user={user} permission={'view_judges'}>
                            <JudgesList />
                        </AuthCheck>
                    } />

                    <Route path="/feedback/user" element={
                        <AuthCheck user={user} permission={'no_permission'}>
                            <UserFeedback />
                        </AuthCheck>
                    } />
                    <Route path="/feedback/certificates" element={
                        <AuthCheck user={user} permission={'no_permission'}>
                            <UserCertificates />
                        </AuthCheck>
                    } />
                     <Route path="/feedback/pressRelease" element={
                        <AuthCheck user={user} permission={'no_permission'}>
                            <UserPressReleases />
                        </AuthCheck>
                    } />


                    <Route path="/403" element={<PermissionDenied />} />
                    <Route path="/404" element={<NotFound />} />
                    <Route path="/:any" element={<NotFound />} />
                  
                </Routes>
            </div>
        </>
    )
}

export default Routing