import React, { useCallback, useEffect, useState } from 'react';
import pdf from '../img/pdf.png'
import doc from '../img/doc.png'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
//   border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  position: 'relative' // Position relative to place the remove button
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: '100%',
  height: 'auto'
};

const removeButton = {
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: 'red',
  color: 'white',
  border: 'none',
  borderRadius: '50%',
  width: '23px',
  height: '23px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

const FilesPreview = ({files, setFiles}) => {


    const removeImage = (fileToRemove) => {
        setFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    };


    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
        <span style={removeButton} onClick={() => removeImage(file)}>X</span>
        <div style={thumbInner}>
            {file.type.startsWith('image/') ? (
            <img
                src={file.preview}
                style={{ display: 'block', width: '100%', height: 'auto' }}
                onLoad={() => { URL.revokeObjectURL(file.preview); }}
            />
            ) : (file.type === 'application/pdf' ? 
                <>
                <img
                    src={pdf}
                    style={{ display: 'block', width: '100%', height: 'auto' }}
                />
                {file.name} vgfg
                </>
            :
            (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? 
                <img
                    src={doc}
                    style={{ display: 'block', width: '100%', height: 'auto' }}
                />
                :
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>File</span>
            </div>
            )
            )}
        </div>
        </div>
    ));

//   useEffect(() => {
//     // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
//     return () => files.forEach(file => URL.revokeObjectURL(file.preview));
//   }, [files]);

  return (
    <section>
      <div style={thumbsContainer}>
        {thumbs}
      </div>
    </section>
  );
};

export default FilesPreview;
