

const filterReducer = (state, action) => {
    switch (action.type) {
        case "API_ERROR":
            return {
                ...state,
                isError:true
            };

        case "USER_LOGIN":
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(action.payload));
            return{
                ...state,
                user:action.payload
            }

        case "USER_LOGOUT":
            localStorage.removeItem('user');
            return{
                ...state,
                user:null,
            }
        
        case "LOADER":
            return{
                ...state,
                isLoading:action.payload,
            }
    
        default:
            return state;
    }
}

export default filterReducer;