
import { createContext, useContext, useReducer } from "react";
import reducer from '../reducer/filterReducer';
import axios from "axios";
import { server } from "../util/Server";

const FilterContext = createContext();

const initialState = {
    isError: false,
    user: (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null,
    isLoading: false

}

export const FilterContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);


    //set customer login
    const setUserLogin = (data) => {
        dispatch({ type: 'USER_LOGIN', payload: data });
    }

    //unset customer login
    const unsetUserLogin = () => {
        axios({
            method: "post",
            url:
                server + "/auth/logout",
            headers: {
                "Content-Type": "application/json",
                Authorization: state.user.token ? `Bearer ${state.user.token}` : "",
            },
        })
        .then(function (result) {
            dispatch({ type: 'USER_LOGOUT' });
        })
        .catch(function (error) {
            dispatch({ type: 'USER_LOGOUT' });
        });
        
    }

    const clearAuth = () => {
        dispatch({ type: 'USER_LOGOUT' });
    }

    const updateLoader = (value) => {
        dispatch({ type: 'LOADER', payload: value });
    }

    return (
        <FilterContext.Provider value={{ ...state, dispatch, setUserLogin, unsetUserLogin, clearAuth,updateLoader }}>
            {children}
        </FilterContext.Provider>
    )

}

//custom hook
export const useFilterContext = () => {
    return useContext(FilterContext);
}
