import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { fetchJudges,JudgeApplicationApprove,JudgeApplicationReject } from '../services/JudgeService';
import { useFilterContext } from '../../../context/filterContext';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import ReactPaginate from 'react-paginate';
import JudgeDetailModal from './JudgeDetailModal';

const JudgesList = () => {
  const handleError = useErrorHandler()
  const {updateLoader} = useFilterContext()
    const [judges, setJudges] = useState(null);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const getData = async(pageNumber, search='') => {
        try {
          updateLoader(true)
            const params = {
                'page' :pageNumber,
                'search':search
            }
            const data = await fetchJudges(params)
            setJudges(data);
        } catch (error) {
          handleError(error)
        }finally{
          updateLoader(false)
        }
    }

    useEffect(() => {
        getData(page, search);
    }, [page]);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        getData(1, search); 
    };

    const clearSearch = () => {
        setSearch('')
        setPage(1);
        getData(1); 
    }

    const updateStatus = async(id, status) => {
        try {
          updateLoader(true)
            const result = (status === 'approve') ? await JudgeApplicationApprove(id) : await JudgeApplicationReject(id)
            if(result)
            {
                result.message && toast.success(result.message)
                getData(1); 
            }
        } catch (error) {
          handleError(error)
        }finally{
          updateLoader(false)
        }
    }
  return (
    <>
    
      <section>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-4'>
            <div className="col-md-4 mb-3">
                <h3>All Judges Applications</h3>
            </div>
            <div className="col-md-4 mb-3">
            </div>
              <div className='col-md-4 mb-3'>
                <div className="input-group search-area">
                  <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                  <span className="input-group-text">
                    <button type="submit" className="btn" onClick={(e) => handleSearchSubmit(e)}>
                      <i className='bx bx-search'></i>
                    </button>
                      
                  </span>
                  {
                    search &&
                    <i className='bx bx-x fs-3 text-danger mt-2 ms-2 cursor-pointer' onClick={(e) => clearSearch(e)}></i>
                  }
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='table-responsive'>
                  <table className='custom-table'>
                    <thead>
                      <tr>
                        <th>Company</th>
                        <th>Industry</th>
                        <th>Name</th>
                        <th>Job</th>
                        <th>Email</th>
                        {/* <th>Phone</th> */}
                        {/* <th>Country</th> */}
                        {/* <th>URL</th> */}
                        {/* <th>Comments</th> */}
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        
                     {
                        judges && judges.data && judges.data.length > 0 && judges.data.map(item => (

                        <tr key={item.id}>
                            <td>{item.company}</td>
                            <td>{item.industry}</td>
                            <td>{item.name}</td>
                            <td>{item.job}</td>
                            <td>{item.email}</td>
                            {/* <td>{item.phone}</td> */}
                            {/* <td>{item.country}</td> */}
                            {/* <td>{item.url}</td> */}
                            {/* <td>{item.comments}</td> */}
                            <td>{item.status}</td>
                            <td>
                                <div className='action-buttons'>
                                <Link className='edit-btn m-1'to={item.resume} target='_blank'>
                                     View Resume
                                    </Link>
                                  <JudgeDetailModal detail={item} />
                                    
                                    {
                                        item.status === 'pending' &&
                                        <>
                                            <button className='edit-btn m-1' type="button" onClick={() => updateStatus(item.id,'approve')}>
                                                Approve
                                            </button>
                                            <button className='del-btn m-1' type="button" onClick={() => updateStatus(item.id,'reject')}>
                                                Reject
                                            </button>
                                        </>
                                    }
                                   
                                </div>
                            </td>
                        </tr>
                        ))
                     }
                    </tbody>
                  </table>
                
                </div>
              </div>

            {
                judges && judges.meta && judges.meta.last_page > 1 && 
                <div className="col-12">
                     <ReactPaginate
                        pageCount={judges.meta.last_page}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={(event) => {
                            setPage((event.selected)+1);
                        }}
                        containerClassName={"pagination"}
                        pageClassName={"page-link"}
                        activeClassName={"active"}
                        previousLabel='<'
                        nextLabel='>'
                    />
                </div>
            }
            </div>
          </div>
        </div>
      </section>

     
    </>
  )
}

export default JudgesList