import React,{useEffect} from 'react'
import { Link,useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import { baseUrl, server } from '../../../util/Server';
import { useFilterContext } from '../../../context/filterContext';
import axios from 'axios';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const ResetPassword = () => {
    const handleError = useErrorHandler()
    const navigate = useNavigate();
    const {code} = useParams()
    const { updateLoader,user,setUserLogin } = useFilterContext();
    useEffect(() => {
        if (user) {
          navigate("/dashboard");
        }
    }, [user]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

      useEffect(() => {
        if(!code)
        {
            navigate('/')
        }
        if(code){
            reset({
                code: code || ''
            });
        }
    }, [code])
    const onSubmit = async(data) => {
        try {
            updateLoader(true)
            const res = await axios.post(server + "/reset-password", data);
            const result = await res.data
            setUserLogin(result);
            navigate("/dashboard");
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }

    }
  return (
    <>
        <div className="fix-wrapper">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6">
                        <Link to={`${baseUrl}`}> <img src="/icon.png" width="60px" className='d-block m-auto mb-5' /></Link>
                        <div className="login-form card mb-0 h-auto pb-3 pt-3">
                            <div className="card-body px-5">
                                <h4 className="text-center mb-4">Reset Password</h4>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Code</label>
                                        <input type="text" className="form-control" placeholder="Code" {...register('code', { required: true})} />
                                        {errors.code && <p className='error-msg'>Code is required</p>}
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Password</label>
                                        <input type="password" className="form-control" placeholder="password" {...register('password', { required: true})} />
                                        {errors.password && <p className='error-msg'>Password is required</p>}
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Confirm Password</label>
                                        <input type="password" className="form-control" placeholder="Confirm Password" {...register('password_confirmation', { required: true})} />
                                        {errors.password_confirmation && <p className='error-msg'>Confirm Password is required</p>}
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn primary-btn w-100">Reset Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ResetPassword