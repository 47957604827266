import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import Select from 'react-select'
import { AppointApplicationJudge } from '../../services/ApplicationService';

const AssignJudgeModal = ({judges,application,getData,updateLoader,handleError}) => {
    const [selected, setSelected] = useState([]);
    const [multiselectData, setMultiselectData] = useState([]);
    const [modalToggle, setModalToggle] = useState(false);
    const openModal = (data=null) => { 
        setModalToggle(true);
        if(application.judges && application.judges.length > 0)
        {

            const options = application.judges.map(judge => ({
                label: judge.name,
                value: judge.id
            }));
            setSelected(options)
        }
    };
    const closeModal = () => { setModalToggle(false) };
 

    useEffect(() => {
        const options = judges.map(judge => ({
            label: judge.name,
            value: judge.id
        }));
        setMultiselectData(options)
          
    }, [judges]);

    const handleSubmit = async(e) => {
        e.preventDefault()
        if(selected.length > 0)
        {

            const judge_id = selected.map(item => item.value);
            const application_id = application.id;
            try {
                updateLoader(true)
                const result = await AppointApplicationJudge({
                    'judge_id' : judge_id,
                    'application_id' : application_id
                });
                if(result)
                {
                    result.message && toast.success(result.message)
                    closeModal()
                    getData()
                }
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
    }


    
    
  return (
    <>
     <span className={`badge rounded-pill p-2 bg-primary1 cursor-pointer`} onClick={() => openModal()}>Judges</span>
    
     {/* Add Modal */}
     <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Select Judge</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e) => handleSubmit(e)}>
                    {/* Name */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Appoint Judge</label>
                        <Select
                            options={multiselectData}
                            value={selected}
                            onChange={setSelected}
                            labelledBy="Select"
                            isMulti 
                        />
                    </div>

                    <div className="col-12"><button className="btn primary-btn" type="submit">Appoint</button></div>

                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default AssignJudgeModal