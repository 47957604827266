import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { useFilterContext } from '../../../context/filterContext';
import { toast } from 'react-toastify';
import { fetchRolePermissions, updateRolePermissions } from '../service/RoleService';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const RolePermission = () => {
    const handleError = useErrorHandler()
    const { id } = useParams();
    const {updateLoader} = useFilterContext()
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([])

    const getData = async() => {
        try {
            updateLoader(true)
            const result = await fetchRolePermissions(id);
            if(result)
            {
                setRole(result.role)
                setPermissions(result.permissions)
            }
        } catch (error) {
            handleError(error)
            
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, [id]);

    useEffect(() => {
        if(role)
        {
            const ids = role.role_permissions.map(item => item.id);
            setSelectedPermissions(ids);
        }
    }, [role]);

    const handlePermissionChange = (permissionId) => {
        setSelectedPermissions((prevSelected) => {
            if (prevSelected.includes(permissionId)) {
                // Remove the permission ID if it's already selected
                return prevSelected.filter((permId) => permId !== permissionId);
            } else {
                // Add the permission ID if it's not selected
                return [...prevSelected, permissionId];
            }
        });
    };
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            updateLoader(true)
            const result = await updateRolePermissions({
                'role_id' : id,
                'permissions' : selectedPermissions
            });
            if(result)
            {
                setRole(result.role)
                result.message && toast.success(result.message)
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

  return (
    <>
         
         {/* <Breadcrumb activeItem='Edit Role Permissions' prevItem={['Roles', '/roles']} /> */}
         <section>
        <div className='card'>
          <div className='card-body'>
          <h3 className="mb-4">Edit Role Permissions</h3>
            <form className='p-2' onSubmit={(e) => handleSubmit(e)}>
              <div className='row'>
                <div className='col-sm-6 mb-3'>
                  <label  className="form-label">Role Name</label>
                  <input type="email" className="form-control" readOnly value={role ? role.name : ''} />
                </div>
                
                <div className="col-sm-12">
                    <label className='d-block'>Permissions</label>
                    {
                        permissions && permissions.length > 0 && permissions.map(item => (

                            <div className="form-check d-inline-block me-3 mb-3" key={item.id}>
                                <input className="form-check-input" type="checkbox" value={item.id} id="permission_{item.id}"   
                                checked={selectedPermissions.includes(item.id)}
                                onChange={() => handlePermissionChange(item.id)}
                                />
                                <label className="form-check-label" htmlFor="permission_{item.id}">
                                {item.name}
                                </label>
                            </div>
                        ))
                    }
                </div>

                <div className="col-sm-12">
                    <button className='btn primary-btn' type="submit">
                      Update
                    </button>
                </div>
                {/* row */}
              </div>
            </form>
          </div>
        </div>
      </section>
    
    </>
  )
}

export default RolePermission