import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useForm,Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { addDeposit } from '../../services/InvoiceService';

const DepositModal = ({invoiceData,getData,handleError,updateLoader}) => {
    const {
        register,
        handleSubmit,
        reset,
        watch, setValue, getValues,control,
        formState: { errors },
      } = useForm();

      useEffect(() => {
        if(invoiceData)
        {
            reset({
                deposit_per: invoiceData ? invoiceData.deposit_per : 0,
                deposit_amount: invoiceData ? invoiceData.deposit_amount : 0,
                deposit_date: invoiceData ? invoiceData.deposit_date : '',
            })
        }
      }, [invoiceData]);
    
      // Watch percentage input to calculate amount dynamically
      const percentage = watch('deposit_per');


    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false);reset()};

    React.useEffect(() => {
        const totalValue = invoiceData.total; // Example total value, replace with your actual value
        if (percentage && percentage <= 100 && percentage >= 0) {
          const calculatedAmount = ((totalValue * percentage) / 100).toFixed(0);
          setValue('deposit_amount', calculatedAmount);
        }
      
      }, [percentage, setValue,invoiceData]);
    

    const onSubmitDepositForm = async(data, e) => {
        e.preventDefault(); // Prevents the form from submitting the parent form
    
        
        try {
            updateLoader(true)
            data['id'] = invoiceData.id
            const result = await addDeposit(data);
            if(result)
            {
                result.message && toast.success(result.message)
                reset();
                closeModal()
                getData()

            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }
    return (
        <>
        {
            (invoiceData.deposit_per && invoiceData.deposit_amount) ?
            <>
            <p className='mb-0'>Deposit Request ( ${invoiceData.deposit_amount} )</p>
            <Link to="#"  onClick={() => openModal()} className="color-primary"><b>Edit</b></Link>
            </>
            :
            (invoiceData.status !== 3 ?  <Link to="#"  onClick={() => openModal()} className="color-primary">Request A Deposit</Link> :  <p >Request A Deposit</p>)
           

        }
        
    
        {/* Add Modal */}
        <Modal show={modalToggle} onHide={() => closeModal()} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Request a deposit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form >
                        <div className="row">
                           <div className="col-6 mb-3">
                            <b>Total Amount:</b>
                           </div>
                           <div className="col-6 text-end mb-3">
                            ${invoiceData.total}
                           </div>
                          
                           <div className="col-md-5 mb-2">
                            <label for="" className="mb-0">Percentage</label>
                            <Controller
                                name="deposit_per"
                                control={control}
                                rules={{ required: 'Percentage is required' }}
                                render={({ field }) => (
                                    <input
                                    type="number"
                                    id="deposit_per"
                                    {...field}
                                    placeholder="Enter percentage"
                                    min="0"
                                      className='form-control'
                                    />
                                )}
                            />
                              {errors.deposit_per && <p className='error-msg'>Percentage is required</p>}
                        </div>
                        <div className="col-md-2 mb-2">
                            <p className="mb-0 mt-4 text-center font-weight-bold h4">=</p>
                        </div>
                        <div className="col-md-5 mb-2">
                            <label for="" className="mb-0">Amount (in $)</label>
                            <Controller
                                name="deposit_amount"
                                control={control}
                                render={({ field }) => (
                                    <input
                                    type="number"
                                    id="deposit_amount"
                                    {...field}
                                    placeholder="Calculated amount"
                                    readOnly
                                    className='form-control'
                                    />
                                )}
                                />
                        </div>
                        <div className="col-md-5 mb-2">
                            <label for="" className="mb-0">Due Date</label>
                            <input type="date" {...register('deposit_date',{required:true})}  className="form-control"  />
                            {errors.deposit_date && <p className='error-msg'>Date is required</p>}
                        </div>
    
                            <div className="col-12"><button className="btn primary-btn" type="button" onClick={handleSubmit(onSubmitDepositForm)}>Save</button></div>
                        </div>
    
    
                    </form>
                </Modal.Body>
            </Modal>
        </>
      )
}

export default DepositModal