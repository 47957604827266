import React,{useState} from 'react'
import { Modal } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas'
import { saveUserSignature } from '../../services/InvoiceService';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const SignatureModal = ({handleError, updateLoader,user,setUserLogin}) => {
    const [canvasSignature, setCanvasSignature] = useState('')
    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false);clearHandler()};

    const clearHandler = () => {
        canvasSignature.clear()
    }

    const saveHandler = async() => {
        if(canvasSignature)
        {
            updateLoader(true)
            const res = canvasSignature.getTrimmedCanvas().toDataURL('image/png')
            const formData = new FormData()
            formData.append('signed', res)
            try {
                const result = await saveUserSignature(formData)
                if(result)
                {
                    toast.success(result.message)
                    if(result.signature)
                    {
                        const updatedUser = {
                            ...user,
                            signature: result.signature
                        };
                        setUserLogin(updatedUser)
                    }
                    closeModal()
                }
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }else{
            toast.error('Please add signature to proceed')
        }
      
    }

  return (
    <>
        <p className='mb-0 '><span  onClick={() => openModal()} className='cursor-pointer color-primary'>Change Signature</span>
        {
            user && user.signature && 
            <Link to={user.signature} target='_blank' className='color-primary'> | View Signature</Link>
        }
        </p>
        {/* Add Modal */}
        <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Add Signature</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               
                <div className='siganture-div'>
                    <SignatureCanvas 
                    ref={(ref) => { setCanvasSignature(ref) }}
                    penColor='black' backgroundColor='white'
                    canvasProps={{className: 'sigCanvas'}} />
                </div>
                <div className=' text-center'>

                <button className='btn primary-btn me-2 mt-3' type="button" onClick={() => saveHandler()}>
                    Save
                </button>
                <button className='btn danger-btn mt-3' type="button" onClick={() => clearHandler()}>
                    Clear
                </button>
                </div>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default SignatureModal