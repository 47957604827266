import React, { useState,useEffect } from 'react'
import { useForm } from 'react-hook-form';
import Select from 'react-select'


const ApplicationFilterBar = ({setSearch,countries,awards}) => {
    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [awardOptions, setAwardOptions] = useState([]);
    const [selectedAwards, setSelectedAwards] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();


      useEffect(() => {
        //dropdown options for country select
        const options = countries.map(country => ({
            label: country.country_name_short_en,
            value: country.country_name_short_en
        }));
        setCountryOptions(options)
          
    }, [countries]);

    useEffect(() => {
        //dropdown options for country select
        const options = awards.map(award => ({
            label: award.name,
            value: award.id
        }));
        setAwardOptions(options)
          
    }, [awards]);

      const onSubmit = async(data) => {
     
        if(selectedCountry && selectedCountry.value)
        {
            data['country'] = selectedCountry.value
        }
    
        if(selectedAwards.length > 0)
        {
            const awardIds = selectedAwards.map(item => item.value);
            data['award_filter'] = awardIds
        }
        setSearch(data)
    }

    const clearFilter = () => {
        reset()
        setSelectedAwards([])
        setSelectedCountry(null)
        setSearch({})

    }
  return (
    <>
    
        <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row'>
                <div className="col-md-3 mb-3">
                    <input type="text" className="form-control" {...register('search')} placeholder="Search..." />
                </div>
                <div className="col-md-3 mb-3">
                    <select className='form-control' {...register('status_filter')}>
                      <option value="">--select status--</option>
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </select>
                </div>
                <div className="col-md-3 mb-3">
                        <Select
                            options={awardOptions}
                            value={selectedAwards}
                            onChange={setSelectedAwards}
                            labelledBy="select"
                            isMulti 
                        />
                </div>
                
                <div className="col-md-3 mb-3">
                        <Select
                            options={countryOptions}
                            value={selectedCountry}
                            onChange={setSelectedCountry}
                            labelledBy="select"
                        />
                </div>
                <div className="col-md-3 mb-3">
                    <button type="submit" className='btn primary-btn'>Search</button>
                    <button type="button" onClick={() => clearFilter()} className='btn info-btn ms-2'>Reset</button>
                </div>
              
              </div>
        </form>
    
    
    </>
  )
}

export default ApplicationFilterBar