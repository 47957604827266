import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { server } from '../../../../util/Server';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AppointAwardJudges } from '../../services/ApplicationService';
import Select from 'react-select'

const AssignAwardJudgeModal = ({judges,getData,award,handleError,updateLoader}) => {
    const [selected, setSelected] = useState([]);
    const [multiselectData, setMultiselectData] = useState([]);
    const [modalToggle, setModalToggle] = useState(false);

    const openModal = (data=null) => { 
        setModalToggle(true);
        if(award.judge)
        {

            const idArray = award.judge.split(',').map(id => parseInt(id, 10));
    
            // Step 2: Create a mapping of judges by ID for easy lookup
            const judgesById = judges.reduce((acc, judge) => {
            acc[judge.id] = judge;
            return acc;
            }, {});
    
            // Step 3: Map the IDs to the desired format
            const selectedState = idArray.map(id => {
            const judge = judgesById[id];
            return judge ? { label: judge.name, value: judge.id } : null;
            }).filter(item => item !== null); // Remove any null values if an ID is not found

            setSelected(selectedState)
        }
    };
    const closeModal = () => { setModalToggle(false) };
 

    useEffect(() => {
        const options = judges.map(judge => ({
            label: judge.name,
            value: judge.id
        }));
        setMultiselectData(options)
          
    }, [judges]);

    const handleSubmit = async(e) => {
        e.preventDefault()
        if(selected.length > 0)
        {

            const judge_id = selected.map(item => item.value);
            const award_id = award.id;
            try {
                updateLoader(true)
                const result = await AppointAwardJudges({
                    'judge_id' : judge_id,
                    'award_id' : award_id
                })
    
                if(result)
                {
                    toast.success(result.message)
                    getData()
                    closeModal()
    
                }
            } catch (error) {
               handleError(error)
            }finally{
                updateLoader(false)
            }
        }
        
    }
  return (
    <>
     <button type="button" onClick={() => openModal()} className='btn primary-btn float-end ms-1 mb-1'>Judges</button>
   
    {/* Add Modal */}
    <Modal show={modalToggle} onHide={() => closeModal()} size="md">
           <Modal.Header closeButton>
               <Modal.Title>Select Judge</Modal.Title>
           </Modal.Header>
           <Modal.Body>
               <form onSubmit={(e) => handleSubmit(e)}>
                   {/* Name */}
                   <div className='col-sm-12 mb-3'>
                       <label  className="form-label">Appoint Judge</label>
                       <Select 
                           options={multiselectData}
                           value={selected}
                           onChange={setSelected}
                           labelledBy="Select"
                           isMulti 
                       />
                   </div>

                   <div className="col-12"><button className="btn primary-btn" type="submit">Appoint</button></div>

               </form>
           </Modal.Body>
       </Modal>
   </>
  )
}

export default AssignAwardJudgeModal