
import { _get, _post, _patch, _delete } from '../../../Client/apiClient'; 



export const fetchAwards = async (params) => {
 
        const data = await _get(`/award`, params);
        return data;
   
};


export const fetchSingleAward = async (id) => {
  
        const data = await _get(`/award/edit/${id}`);
        return data;
   
};

export const fetchAwardCriteria = async (id) => {
   
        const data = await _get(`/award/criteria/${id}`);
        return data;
  
};


export const createAward = async (formData) => {
    
        const data = await _post('/award/store',formData);
        return data;
   
};


export const updateAward = async (id,formData) => {
    
        const data = await _post(`award/update/${id}`, formData);
        return data;
   
};

export const AddOrUpdateAwardCriteria = async (id,formData) => {
   
        const data = await _post(`award/criteria_update/${id}`, formData);
        return data;
   
};


export const deleteAward = async (id) => {
   
      const data = await _delete(`/award/delete/${id}`);
      return data;
  
};


