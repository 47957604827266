import React from 'react'
import pdf from '../../../../img/pdf.png'
import doc from '../../../../img/doc.png'
import { Link } from 'react-router-dom';
import { DeleteFeedback } from '../../services/UserService';
import { toast } from 'react-toastify';

const MediaFiles = ({files,type,setModalData,updateLoader,handleError}) => {
    const getFileIcon = (file, extension) => {
        if (extension === 'pdf') {
          return pdf; // Return image icon for image files
        } else if (extension === 'docx') {
          return doc; // Return PDF icon for PDF files
        } else {
          return file
        }
      
    };

    const getFileExtension = (url) => {
        // Extract the file name from the URL
        const fileName = url.split('/').pop();
        
        // Get the file extension from the file name
        const extension = fileName.split('.').pop();
        
        return extension;
    };

    const deleteImage = async(id) => {
      const isConfirmed = window.confirm("Are you sure you want to perform this action?");
      if(isConfirmed)
      {
          try {
              updateLoader(true)
              const result = await DeleteFeedback(id)
              if(result)
              {
                toast.success(result.message)
                setModalData(result.data || [])

              }
          } catch (error) {
              handleError(error)
          }finally{
              updateLoader(false)
          }
      }
    }

  return (
    <div className="media-files">
        {files.length > 0 ? (
        files.map((file, index) => {
            const extension = getFileExtension(file[type+"_file"])
            const fileIcon = getFileIcon(file[type+"_file"],extension);
            
            return (
                <div key={index} className="d-inline-block m-2 position-relative">
                <Link to={file[`${type}_file`]} target="_blank" className='d-block'>
                  <img src={fileIcon} alt="File Icon" width="80px" />
                </Link>
                
                {/* Delete Icon */}
                <button 
                  className="position-absolute end-0 btn btn-danger btn-sm py-1 px-2"
                  style={{ margin: '5px',top:'-10px' }} 
                  onClick={() => deleteImage(file.id)}
                 
                >
                  <i className='bx bxs-trash-alt' style={{color:'#fff'}}></i>
                </button>
              </div>
            );
        })
        ) : (
        <p>No files uploaded.</p>
        )}
    </div>
  )
}

export default MediaFiles