import React,{useState,useEffect} from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { Link } from 'react-router-dom';
import FeedbackModel from './inc/FeedbackModel';
import { fetchUsers } from '../services/UserService';
import PressReleaseModal from './inc/PressReleaseModal';
import CertificatesModal from './inc/CertificatesModal';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import ReactPaginate from 'react-paginate';

const UserList = () => {
  const handleError = useErrorHandler()
    const {updateLoader} = useFilterContext()

    const [users, setUsers] = useState(null);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');


    const getData = async(pageNumber, search='') => {
        try {
          updateLoader(true)
          const params = {
              'page' :pageNumber,
              'search':search
          }
          const data = await fetchUsers(params)
          setUsers(data);
        } catch (error) {
          handleError(error)
        }finally{
          updateLoader(false)
        }
    }

    useEffect(() => {
      getData(page, search);
    }, [page]);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        getData(1, search); 
    };

    const clearSearch = () => {
        setSearch('')
        setPage(1);
        getData(1); 
    }

    const getRoleNames = (roles) => {
        return roles.map(role => role.name).join(', ')
    }

    const hasRole = (roles) => {
      return roles.some(p => p.name === 'Judge');
    };
  return (
    <>
      <section>
        <div className='card'>
          <div className='card-body'>
          <div className='row mb-md-4'>
              <div className="col-md-4 mb-3">
                <h3>User Lists</h3>
              </div>
              <div className="col-md-4"></div>
              <div className='col-md-4 mb-3'>
                <div className="input-group search-area">
                  <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                  <span className="input-group-text">
                    <button type="submit" className="btn" onClick={(e) => handleSearchSubmit(e)}>
                      <i className='bx bx-search'></i>
                    </button>
                      
                  </span>
                  {
                    search &&
                    <i className='bx bx-x fs-3 text-danger mt-2 ms-2 cursor-pointer' onClick={(e) => clearSearch(e)}></i>
                  }
                </div>
              </div>
              
            </div>
            <div className='row'>
              <div className='col-12'>
                {/* <h5 className='card-title'>List of Items</h5> */}
                <div className='table-responsive'>
                  <table className='custom-table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>SignUp date</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                        
                     {
                        users && users.data && users.data.length > 0 && users.data.map(item => (

                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{getRoleNames(item.roles)}</td>
                            <td>{item.created_at}</td>
                            <td><span className={`badge ${item.status === 1 ? 'bg-success' : 'bg-danger'}`}>{item.status_text}</span></td>
                            <td className=''>
                                <div className='action-buttons'>
                                    <Link to={`/user/detail/${item.id}`} className='edit-btn m-1'>
                                    <i className='bx bx-expand'></i> View
                                    </Link>

                                    <FeedbackModel singleUser={item} updateLoader={updateLoader} handleError={handleError} />
                                    <PressReleaseModal  singleUser={item} updateLoader={updateLoader} handleError={handleError} />
                                    <CertificatesModal singleUser={item} updateLoader={updateLoader} handleError={handleError} />
                                </div>
                            </td>
                            <td className='text-no-wrap'>
                                <div className='action-buttons'>
                                  {
                                    hasRole(item.roles) &&
                                    <Link className='del-btn' to={`/application/applications_judge/${item.id}`} >
                                     Assigned Applications
                                    </Link>
                                  }
                                </div>
                            </td>
                        </tr>
                        ))
                     }
                    </tbody>
                  </table>
                
                </div>
              </div>
              {
                users && users.meta && users.meta.last_page > 1 && 
                <div className="col-12">
                   <ReactPaginate
                        pageCount={users.meta.last_page}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={(event) => {
                            setPage((event.selected)+1);
                        }}
                        containerClassName={"pagination"}
                        pageClassName={"page-link"}
                        activeClassName={"active"}
                        previousLabel='<'
                        nextLabel='>'
                    />
                </div>
            }
            </div>
          </div>
        </div>
      </section>
     
    </>
  )
}

export default UserList