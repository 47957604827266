import React from 'react'
import { Navigate } from "react-router-dom";
const AuthCheck = ({user,permission,children}) => {
    const hasPermission = (permission) => {
        return user.permissions.some(p => p.name === permission);
    };


    if (!user) {
        return <Navigate to="/" replace />;
    }else if(permission !== 'no_permission' && !hasPermission(permission))
    {
        return <Navigate to="/403" replace />;
    }

    return children;
}

export default AuthCheck