import React,{useState} from 'react'
import { toast } from 'react-toastify';
import { useNavigate,Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { server } from '../../../../util/Server';
import { applyForAward } from '../../services/ApplicationService';

const NewAwardApplicationModal = ({awards,authUser,updateLoader,getData,handleError}) => {
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false) };

    
    const onSubmit = async(data) => {
        try {
            updateLoader(true)
            data['name'] = authUser.name 
            data['email'] = authUser.email 
            const result = await applyForAward(data);
            if(result)
            {
                getData()
                result.message && toast.success(result.message)
                reset();
                closeModal()
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

  return (
    <>
    
    <button type="button" className='btn primary-btn float-end' onClick={() => openModal()}>Apply For Award</button>
    
     {/* Add Modal */}
     <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Apply for Award</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Name */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Please Select Awards *</label>
                        {
                            awards && awards.length > 0 && awards.map(award => (
                                <div className="form-check" key={award.id}>
                                    <input className="form-check-input" type="checkbox" value={award.id} {...register('awards')} id={"award-"+award.id} />
                                    <label className="form-check-label" htmlFor={"award-"+award.id}>
                                        {award.name}
                                    </label>
                                </div>

                            ))
                        }
                    </div>

                    <div className="col-12"><button className="btn primary-btn" type="submit">Submit</button></div>

                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default NewAwardApplicationModal