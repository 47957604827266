import React,{useState,} from 'react'
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DropZone from '../../../Components/DropZone';
import { createNews } from '../services/NewsService';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FilesPreview from '../../../Components/FilesPreview';
import { useFilterContext } from '../../../context/filterContext';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const NewsAdd = () => {
    const handleError = useErrorHandler()
    const {updateLoader} = useFilterContext()
    const [images, setImages] = useState([]);
    const [description, setDescription] = useState('');
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

    const onSubmit = async(data) => {
        if(images.length === 0)
        {
            toast.error('News Image is required');
        }else if(description === '')
        {
            toast.error('News Description is required');
        }else{
            try {
                updateLoader(true)
                const formData = new FormData();
                formData.append('title', data.title);
                formData.append('description', description);
                formData.append('date_added', data.date_added);
                if(images && images[0])
                {
                    formData.append('image', images[0]);
                }
    
                const result = await createNews(formData)
                if(result)
                {
                    result.message && toast.success(result.message)
                    reset();
                    setDescription('')
                    setImages([])
                }
            
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
        
    }

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setDescription(data);
    };
  return (
    <>
    
      <section>
        <div className='card'>
          <div className='card-body'>
          <div className="row">
        <div className="col-md-6 mb-4">
            <h3>Add News</h3>
        </div>
        <div className="col-md-6 mb-4">
        </div>
        
    </div>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className='row'>
                    {/* Name */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">News Title</label>
                        <input type="text" className="form-control" {...register('title', { required: true})} />
                        {errors.title && <p className='error-msg'>Title is required</p>}
                    </div>
                    
                    {/* Description */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Description</label>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={description}
                            onChange={handleEditorChange}
                        />
                    </div>
                    {/* Award Image */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">News Image</label>
                        {
                            images.length < 1 &&
                            <>
                            <DropZone setComponentFiles={setImages} componentFiles={images} allowedFileTypes={['image/png','image/jpg','image/jpeg']}/>
                            <small>Only png, jpg, jpeg are allowed</small>
                            </>
                        }
                        <FilesPreview files={images} setFiles={setImages} />
                    </div>
                      {/* Date */}
                      <div className='col-md-6 mb-3'>
                        <label  className="form-label">Date</label>
                        <input type="datetime-local" className="form-control" {...register('date_added', { required: true})}  />
                        {errors.date_added && <p className='error-msg'>Date is required</p>}
                    </div>

                    <div className="col-12"><button className="btn primary-btn" type="submit">Save</button></div>

                </div>
            </form>
          </div>
        </div>
      </section>

      
    </>
  )
}

export default NewsAdd