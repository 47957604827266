import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useHasRole } from '../../util/useHasRole'

const NavLinks = ({setMobileSideBar,authUser}) => {
    const [activeNav, setActiveNav] = useState()
    const [dropdownMenu, setDropdownMenu] = useState('')
    const isJudge = useHasRole('Judge')
    const isSuperAdmin = useHasRole('super_admin')

    const location = useLocation();
    let pathName = location.pathname;

    useEffect(() => {
        setActiveNav(pathName)
    }, [pathName])


    

    const showMenu = (menu) => {
        if (dropdownMenu !== menu) {
            setDropdownMenu(menu);
        }
        if (dropdownMenu === menu) {
            setDropdownMenu('')
        }
    }
    
    const hasPermission = (permission) => {
        return authUser.permissions.some(p => p.name === permission);
    };
    return (
        <>
            <ul className="side-navlinks nav-links">
                <li className={activeNav === '/dashboard' ? "pt-3 active" : 'pt-3'}>
                    <Link to="/dashboard" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                        <i className='bx bx-grid-alt'></i>
                        <span className="link_name">Dashboard</span>
                    </Link>
                </li>
                {
                    !isSuperAdmin &&
                    <li className={activeNav === '/user/business' ? 'active' : ''}>
                        <Link to="/user/business" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                            <i className='bx bx-collection'></i>
                            <span className="link_name">Profile</span>
                        </Link>
                    </li>
                }
                {
                    authUser && hasPermission('view_roles') && 
                    <li className={activeNav === '/roles' ? 'active' : ''}>
                        <Link to="/roles" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                            <i className='bx bx-collection'></i>
                            <span className="link_name">Roles</span>
                        </Link>
                    </li>
                }
                {
                    authUser && hasPermission('view_permissions') && 
                    <li className={activeNav === '/permissions' ? 'active' : ''}>
                        <Link to="/permissions" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                            <i className='bx bx-collection'></i>
                            <span className="link_name">Permissions</span>
                        </Link>
                    </li>
                }
                {
                    authUser && hasPermission('view_users') && 
                    <li className={activeNav && activeNav.startsWith('/user') ? 'active' : ''}>
                        <Link to="/users" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                            <i className='bx bx-collection'></i>
                            <span className="link_name">Users</span>
                        </Link>
                    </li>
                }
                <li className={activeNav === '/criteriaguidebook' ? 'active' : ''}>
                    <Link to="/criteriaguidebook" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                        <i className='bx bx-collection'></i>
                        <span className="link_name">Criteria Guidebook</span>
                    </Link>
                </li>
                {
                   authUser && hasPermission('view_awards') && 
                    <li className={activeNav && activeNav.startsWith('/award') ? 'active' : ''}>
                        <Link to="/awards" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                            <i className='bx bx-collection'></i>
                            <span className="link_name">Awards</span>
                        </Link>
                    </li>
                }
                {
                    authUser && hasPermission('view_applications') &&  
                    <li className={activeNav && activeNav.startsWith('/application') ? 'active' : ''}>
                        <Link to="/application/all" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                            <i className='bx bx-collection'></i>
                            <span className="link_name">Applications</span>
                        </Link>
                    </li>
                }
                 {
                    authUser && hasPermission('view_invoices') &&
                    <li className={activeNav && activeNav.startsWith('/invoice') ? 'active' : ''}>
                        <Link to="/invoice" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                            <i className='bx bx-list-ul'></i>
                            <span className="link_name">Invoices</span>
                        </Link>
                    </li>
                }
                {
                    authUser && isJudge && 
                    <li className={activeNav === '/application/applications_judge' ? 'active' : ''}>
                        <Link to="/application/applications_judge" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                            <i className='bx bx-collection'></i>
                            <span className="link_name">Judge Applications</span>
                        </Link>
                    </li>
                }
                {
                     authUser && hasPermission('view_judges') &&
                    <li className={activeNav === '/judges_all' ? 'active' : ''}>
                        <Link to="/judges_all" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                            <i className='bx bx-collection'></i>
                            <span className="link_name">Join Our Judge Applications</span>
                        </Link>
                    </li>
                }
                {
                    authUser && hasPermission('view_blogs') && 
                    <li className={dropdownMenu === 'blog' || activeNav === '/blog' ? 'showMenu active' : ''}>
                        <div className="icon-link" onClick={() => showMenu('blog')}>
                            <Link to="#">
                                <i className='bx bx-collection'></i>
                                <span className="link_name">Blogs</span>
                            </Link>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu">
                            <li><Link to="/blog" onClick={() => setMobileSideBar(false)}>All Blogs</Link></li>
                            {
                                hasPermission('add_blog') &&
                                <li><Link to="/blog/create" onClick={() => setMobileSideBar(false)}>Add Blog</Link></li>
                            }
                        </ul>
                    </li>
                }

                {
                    authUser && hasPermission('view_news') && 
                    <li className={dropdownMenu === 'news' || activeNav === '/news' ? 'showMenu active' : ''}>
                        <div className="icon-link" onClick={() => showMenu('news')}>
                            <Link to="#">
                                <i className='bx bx-collection'></i>
                                <span className="link_name">News</span>
                            </Link>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu">
                            <li><Link to="/news" onClick={() => setMobileSideBar(false)}>All News</Link></li>
                            {
                                hasPermission('add_news') &&
                                <li><Link to="/news/create" onClick={() => setMobileSideBar(false)}>Add News</Link></li>    
                            }
                        </ul>
                    </li>
                }

                {
                    authUser && hasPermission('view_teams') &&
                    <li className={dropdownMenu === 'team' || activeNav === '/team' ? 'showMenu active' : ''}>
                        <div className="icon-link" onClick={() => showMenu('team')}>
                            <Link to="#">
                                <i className='bx bx-collection'></i>
                                <span className="link_name">Teams</span>
                            </Link>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu">
                            <li><Link to="/team" onClick={() => setMobileSideBar(false)}>All Teams</Link></li>
                            {
                                hasPermission('add_team') &&
                                <li><Link to="/team/create" onClick={() => setMobileSideBar(false)}>Add Team</Link></li>
                            }
                        </ul>
                    </li>
                }

                {
                    !isSuperAdmin &&
                    <>
                        <li className={activeNav === '/feedback/user' ? 'active' : ''}>
                            <Link to="/feedback/user" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                                <i className='bx bx-collection'></i>
                                <span className="link_name">Feedback</span>
                            </Link>
                        </li>

                        <li className={activeNav === '/feedback/certificates' ? 'active' : ''}>
                                <Link to="/feedback/certificates" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                                    <i className='bx bx-collection'></i>
                                    <span className="link_name">HBC Certificates</span>
                                </Link>
                        </li>
                        <li className={activeNav === '/feedback/pressRelease' ? 'active' : ''}>
                                <Link to="/feedback/pressRelease" onClick={() => {setDropdownMenu(''); setMobileSideBar(false)}}>
                                    <i className='bx bx-collection'></i>
                                    <span className="link_name">Press Release</span>
                                </Link>
                        </li>
                    </>
                }
                
            </ul>
        </>
    )
}

export default NavLinks