import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { useFilterContext } from '../../../context/filterContext';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Sidebar from './inc/Sidebar';
import { fetchSingleUserDetail, updateUserBusinessData } from '../services/UserService';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const UserBusinessInformation = () => {
  const {
    register,
    handleSubmit,
    reset
  } = useForm();
  const handleError = useErrorHandler()
const { id } = useParams();
const {updateLoader} = useFilterContext()
const [userData, setUserData] = useState(null);
const [initiatives, setInitiatives] = useState([]);

const getData = async() => {
    try {
        updateLoader(true)
        const result = await fetchSingleUserDetail(id);
        if(result)
        {

            setUserData(result.user)
            setInitiatives(result.initiatives)
        }
    } catch (error) {
        handleError(error)
    }finally{
        updateLoader(false)
    }
}

useEffect(() => {
    getData();
}, []);

useEffect(() => {
    if(userData){
        reset({
            organization: userData.organization || '',
            ceo: userData.ceo || '',
            nomination: userData.nomination || '',
            contact_person: userData.contact_person || '',
            mobile: userData.mobile || '',
            business_nature: userData.business_nature || '',
            business_address: userData.business_address || '',
            business_phone: userData.business_phone || '',
            postal_code: userData.postal_code || '',
            city: userData.city || '',
            country: userData.country || '',
            reference: userData.reference || '',
            head_office_location: userData.head_office_location || '',
            branches_number: userData.branches_number || '',
            employees_number: userData.employees_number || '',
            representative: userData.representative || '',
            initiatives: userData.initiatives || []
        });


    }
}, [userData])

const onSubmit = async(data) => {
    try {
        updateLoader(true)
        data['id'] = userData.id
        const result = await updateUserBusinessData(data);
        if(result)
        {
            result.message && toast.success(result.message)
            reset()
            getData()
        }
    } catch (error) {
        handleError(error)
    }finally{
        updateLoader(false)
    }
}
  return (
    <>


    <section>
   
         <div className='row'>
            <div className="col-md-4">
                <Sidebar id={id} type="business" userData={userData}   />
            </div>{/* left row */}
            <div className="col-md-8">
                <div className='card'>
                    <div className='card-body px-5'>
                        <h3 className='mb-5'>Business Informations</h3>
                        <form  onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-4 pt-2">
                              Name of your Organization:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('organization')} defaultValue={userData && userData.organization}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>

                            <div className="col-md-4 pt-2">
                            General Manager/ CEO Name:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('ceo')} defaultValue={userData && userData.ceo}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>


                            <div className="col-md-4 pt-2">
                            Nomination Name:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('nomination')} defaultValue={userData && userData.nomination}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>


                            <div className="col-md-4 pt-2">
                            Contact Person:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('contact_person')} defaultValue={userData && userData.contact_person}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>


                            <div className="col-md-4 pt-2">
                            Mobile Number:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('mobile')} defaultValue={userData && userData.mobile}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            Business Activity (Nature of the Business):
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('business_nature')} defaultValue={userData && userData.business_nature}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            Business Address:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('business_address')} defaultValue={userData && userData.business_address}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            Phone Number:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('business_phone')} defaultValue={userData && userData.business_phone}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            Postal Code:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('postal_code')} defaultValue={userData && userData.postal_code}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            City:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('city')} defaultValue={userData && userData.city}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            Country:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('country')} defaultValue={userData && userData.country}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>


                          
                            <div className="col-md-4 pt-2">
                            Location of your Head Office:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('head_office_location')} defaultValue={userData && userData.head_office_location}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            Total Number of Branches:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('branches_number')} defaultValue={userData && userData.branches_number}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            Total Number of Employees:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('employees_number')} defaultValue={userData && userData.employees_number}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            How did you hear about HBC International Awards Foundation?
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('reference')} defaultValue={userData && userData.reference}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-4 pt-2">
                            HBC Representative Name:
                            </div>
                            <div className="col-md-8">
                                <input type="text" className="form-control" {...register('representative')} defaultValue={userData && userData.representative}  />
                            </div>
                            <div className="col-12">
                                <hr className='my-5' />
                            </div>



                            <div className="col-md-12 pt-2">
                            Please select which of the following initiatives has been implemented in your organization:
                            </div>
                            <div className="row">
                                {
                                    initiatives && initiatives.length > 0 && initiatives.map((initiative, index )=> (
                                        <div className="form-check col-md-6" key={index}>
                                            <input className="form-check-input" type="checkbox" value={initiative} {...register('initiatives')} id={"initiative-"+index} />
                                            <label className="form-check-label" htmlFor={"initiative-"+index}>
                                                {initiative}
                                            </label>
                                        </div>
        
                                    ))
                                }
                            </div>
                            
                          
                            <div className="col-12 my-4"><button className="btn primary-btn" type="submit">Save</button></div>
                        </div>{/* form row */}
                        </form>
                    </div>{/* card body */}
                </div>{/* card */}
            </div>{/* right row */}
           
         </div>{/* row */}
     
 </section>

</>
  )
}

export default UserBusinessInformation