import React, { useEffect, useState,useCallback } from 'react'
import { createNewInvoice, fetchCreateInvoiceData, fetchCustomers, fetchItems } from '../services/InvoiceService'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form';
import AddNewItemModal from './inc/AddNewItemModal';
import ShippingAndDiscountModal from './inc/ShippingAndDiscountModal';
import TaxModal from './inc/TaxModal';
import { useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../../context/filterContext';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import DropZone from '../../../Components/DropZone';
import FilesPreview from '../../../Components/FilesPreview';
import AddNewCustomerModal from './inc/AddNewCustomerModal';
import UpdateCustomerModal from './inc/UpdateCustomerModal';
import SignatureModal from './inc/SignatureModal';
function debounce(func, delay) {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

const AddInvoice = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate()
    const handleError = useErrorHandler()
    const {updateLoader,user,setUserLogin} = useFilterContext()
    //customer
    const [displayCustomerList, setDisplayCustomerList] = useState(false)
    const [customerList, setCustomerList] = useState(null)
    const [invoiceCustomer, setInvoiceCustomer] = useState(null)
    const [customerQuery, setCustomerQuery] = useState('')
    //items
    const [displayItemsList, setDisplayItemsList] = useState(false)
    const [invoiceItems, setInvoiceItems] = useState([])
    const [itemsList, setItemsList] = useState(null)
    const [itemQuery, setItemQuery] = useState('')
    //invoice
    const [subtotal, setSubtotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [amountDue, setAmountDue] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [shipping, setShipping] = useState(0)
    //tax
    const [taxType, setTaxType] = useState(1)
    const [taxTypeOne, setTaxTypeOne] = useState({})
    const [taxTypeTwo, setTaxTypeTwo] = useState({})
    const [taxOneTotal, setTaxOneTotal] = useState(0)
    const [taxTwoTotal, setTaxTwoTotal] = useState(0)
    //images
    const [images, setImages] = useState([])
    //signature
    const [signature, setSignature] = useState(false)

    const getCustomers = async() => {
        try {
            const result = await fetchCustomers({'flag':'latest'})
            if(result)
            {
                setCustomerList(result)
            }
        } catch (error) {
            handleError(error)
        }   
    }

    const fetchCustomerSearchResults = useCallback(debounce(async (query) => {
      
        try {
            const result = await fetchCustomers({'flag':'latest','search':query})
            if(result)
            {
                setCustomerList(result)
                setDisplayCustomerList(true)
            }
        } catch (error) {
            handleError(error)
        } 
      }, 300), []);

    useEffect(() => {
    if (customerQuery) {
        fetchCustomerSearchResults(customerQuery);
    } else {
        getCustomers();
    }
    }, [customerQuery, fetchCustomerSearchResults]);

    const getItems = async() => {
        try {
            const result = await fetchItems({'flag':'latest'})
            if(result)
            {
                setItemsList(result)
            }
        } catch (error) {
            handleError(error)
        }   
    }

    const fetchItemsSearchResults = useCallback(debounce(async (query) => {
      
        try {
            const result = await fetchItems({'flag':'latest','search':query})
            if(result)
            {
                setItemsList(result)
                setDisplayItemsList(true)
            }
        } catch (error) {
            handleError(error)
        } 
      }, 300), []);

    useEffect(() => {
    if (itemQuery) {
        fetchItemsSearchResults(itemQuery);
    } else {
        getItems();
    }
    }, [itemQuery, fetchItemsSearchResults]);

    const getInvoiceData = async() => {
        try {
            updateLoader(true)
            const result = await fetchCreateInvoiceData()
            if(result)
            {
                reset({ invoice_no: result.invoice_number, invoice_date:result.date , due_date:result.due_date })
            }
        } catch (error) {
            handleError(error)
        }  finally{
            updateLoader(false)
        } 
    }


    useEffect(() => {
        //make api call to fetch latest customer
        getCustomers()
        getItems()
        getInvoiceData()
    }, []);

    const addToInvoiceItems = (item) => {
        const taxRate = taxTypeOne.rate ? parseFloat(taxTypeOne.rate) : 0; 
        const taxOneValue = (item.price * taxRate)/100; 

        const taxRate2 = taxTypeTwo.rate ? parseFloat(taxTypeTwo.rate) : 0; 
        const taxTwoValue = (item.price * taxRate2)/100; 

        const newItem = {
            item_name: item.item_name,
            product_code: item.product_code,
            original_price: null,
            price: item.price,
            discount:0,
            quantity: 1,
            total_price: item.price,
            unit_type: item.unit_type,
            description: item.description,
            tax_one_status:taxOneValue > 0 ? 1 : null,
            tax_one_value:taxOneValue.toFixed(0),
            tax_two_status:taxTwoValue > 0 ? 1 : null,
            tax_two_value:taxTwoValue.toFixed(0),
            item_id: item.id

        }
        setInvoiceItems((prevItems) => [...prevItems, newItem]);
        setDisplayItemsList(!displayItemsList)
    }


    const removeItemFromInvoiceList = (index) => {
        setInvoiceItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };

    const updateFields = (index, newValues) => {
        setInvoiceItems((prevItems) =>
          prevItems.map((item, i) =>
            i === index ? { ...item, ...newValues } : item
          )
        );
    };

    const handleItemFieldValueUpdate = (index, field, value) => {
        
        const item = invoiceItems.find((item, itemIndex )=> 
            itemIndex == index
        );

       
        if(field == 'quantity')
            {
                    const total_price = (item.price-item.discount)*value
                    const taxRate = taxTypeOne.rate ? parseFloat(taxTypeOne.rate) : 0; 
                    const taxOneValue = ((total_price * taxRate)/100).toFixed(0); 
            
                    const taxRate2 = taxTypeTwo.rate ? parseFloat(taxTypeTwo.rate) : 0; 
                    const taxTwoValue = ((total_price * taxRate2)/100).toFixed(0); 

                    const NewValues = {
                        [field]: value,
                        total_price:total_price,
                        tax_one_status:taxOneValue > 0 ? 1 : null,
                        tax_one_value:taxOneValue,
                        tax_two_status:taxTwoValue > 0 ? 1 : null,
                        tax_two_value:taxTwoValue,
                    }
                    updateFields(index, NewValues)
            }
            else if(field == 'price')
            {
                    const total_price = item.quantity*(value-item.discount)
                    const taxRate = taxTypeOne.rate ? parseFloat(taxTypeOne.rate) : 0; 
                    const taxOneValue = ((total_price * taxRate)/100).toFixed(0); 
            
                    const taxRate2 = taxTypeTwo.rate ? parseFloat(taxTypeTwo.rate) : 0; 
                    const taxTwoValue = ((total_price * taxRate2)/100).toFixed(0); 

                    const NewValues = {
                        [field]: value,
                        total_price:total_price,
                        tax_one_status:taxOneValue > 0 ? 1 : null,
                        tax_one_value:taxOneValue,
                        tax_two_status:taxTwoValue > 0 ? 1 : null,
                        tax_two_value:taxTwoValue,
                    }
                    updateFields(index, NewValues)
            }
            else if(field == 'discount')
            {
                    const total_price = item.quantity*(item.price-value)
                    const taxRate = taxTypeOne.rate ? parseFloat(taxTypeOne.rate) : 0; 
                    const taxOneValue = ((total_price * taxRate)/100).toFixed(0); 
            
                    const taxRate2 = taxTypeTwo.rate ? parseFloat(taxTypeTwo.rate) : 0; 
                    const taxTwoValue = ((total_price * taxRate2)/100).toFixed(0); 

                    const NewValues = {
                        [field]: value,
                        total_price:total_price,
                        tax_one_status:taxOneValue > 0 ? 1 : null,
                        tax_one_value:taxOneValue,
                        tax_two_status:taxTwoValue > 0 ? 1 : null,
                        tax_two_value:taxTwoValue,
                    }
                    updateFields(index, NewValues)
            }
        else{
            const NewValues = { field: value}
            updateFields(index, NewValues)
        }
       
    }

    useEffect(() => {
        const taxOneRate = taxTypeOne.rate ? parseFloat(taxTypeOne.rate) / 100 : 0; 
        const taxTwoRate = taxTypeTwo.rate ? parseFloat(taxTypeTwo.rate) / 100 : 0;
    
        const updatedItems = invoiceItems.map(item => {
            const taxOneValue = item.total_price * taxOneRate; 
            const taxTwoValue = item.total_price * taxTwoRate; 
            return {
                ...item,
                tax_one_status:taxOneValue > 0 ? 1 : null,
                tax_one_value:taxOneValue.toFixed(0),
                tax_two_status:taxTwoValue > 0 ? 1 : null,
                tax_two_value:taxTwoValue.toFixed(0),
            };
          });
          
        setInvoiceItems(updatedItems);
    }, [taxTypeOne,taxTypeTwo]);

    useEffect(() => {
        
        if(invoiceItems.length > 0)
        {
          
            
            //calculate total
            const subtotal = invoiceItems.reduce((total, item) => total + parseFloat(item.total_price), 0)
            let total = subtotal
            const shippingCost  = parseFloat(shipping) || 0
            const discountCost  = parseFloat(discount) || 0

            //calculate total tax one
            let taxOneTotal = 0
            let taxTwoTotal = 0
            if(Object.keys(taxTypeOne).length > 0 || Object.keys(taxTypeTwo).length > 0){
                invoiceItems.map(item => {
                    taxOneTotal += item.tax_one_value ? parseInt(item.tax_one_value) : 0; 
                    taxTwoTotal += item.tax_two_value ? parseInt(item.tax_two_value) : 0;
                });

                
                setTaxOneTotal(taxOneTotal)
                total += parseFloat(taxOneTotal)

                
                setTaxTwoTotal(taxTwoTotal)
                total += parseFloat(taxTwoTotal)
            
            }
            //calculate total tax two
            if(shippingCost && shippingCost > 0)
            {
                total += shippingCost
            }
            if(discountCost && discountCost > 0)
            {
                total -= discountCost
            }

            setSubtotal(subtotal)
            setTotal(total)
            setAmountDue(total)
        }else{
            setSubtotal(0)
            setTotal(0)
            setAmountDue(0)
            setTaxTwoTotal(0)
            setTaxOneTotal(0)
        }
    }, [invoiceItems,discount,shipping]);

    const onSubmitMain = async(data) => {
        
        if(!invoiceCustomer)
        {
            toast.error('Please select customer to proceed!')
        }
        else if(invoiceItems.length == 0){
            toast.error('Please add atleast one invoice item to proceed!')
        }
        else{
            try {
                // updateLoader(true)
                data['user_id'] = invoiceCustomer.id
                data['tax_type'] = taxType
                data['total'] = total
                data['subtotal'] = subtotal
                data['amount_due'] = amountDue
                data['total_discount'] = discount 
                data['shipping_cost'] = shipping
                if(signature)
                {
                    data['admin_signature_status'] = 1
                }
                if(Object.keys(taxTypeOne).length > 0)
                {
                    data['tax_code_one'] = taxTypeOne.code
                    data['tax_rate_one'] = taxTypeOne.rate
                }
                if(Object.keys(taxTypeTwo).length > 0)
                {
                    data['tax_code_two'] = taxTypeTwo.code
                    data['tax_rate_two'] = taxTypeTwo.rate
                }
               
                data['items'] = invoiceItems
                if(images.length > 0)
                {
                    images.forEach((image, index) => {
                        data[`images[${index}]`]= image;
                    });
                }

            
                const result = await createNewInvoice(data);
                if(result)
                {
                    result.message && toast.success(result.message)
                    if(result.invoice)
                    {
                        navigate('/invoice/'+result.invoice.id)
                    }

                }
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
    }
  
  return (
    <>
    
    <section>
        <div className="card">
            <div className="card-body">
                <form  onSubmit={handleSubmit(onSubmitMain)}>
                    <div className="row">
                        <div className="col-md-6">
                            <h4>Create Invoice</h4>
                        </div>
                        <div className="col-md-6 text-end">
                            <button className='btn primary-btn' type="submit" >Save</button>
                        </div>
                        <div className="col-md-6">
                            {
                                !invoiceCustomer &&
                                <div className="input-container customer-input-container w-100 mt-4" >
                                    <input type="text" className="form-control" id="customer-input"
                                        placeholder="Search Customer"  value={customerQuery}
                                        onChange={(e) => setCustomerQuery(e.target.value)} />
                                    <span className="icon-button customer-icon-button" onClick={() => setDisplayCustomerList(!displayCustomerList)}>
                                        <i className='bx bx-chevron-down fs-4'></i>
                                    </span>
                                    {
                                        displayCustomerList &&
                                        <div className="list-container customer">
                                            <ul id="customer-list">
                                                {
                                                    customerList && customerList.data && customerList.data.length > 0 && customerList.data.map(customerItem => (
                                                        <li onClick={() => {setInvoiceCustomer(customerItem);setDisplayCustomerList(false)}}>{customerItem.name}</li>
                                                    ))
                                                }
                                               <AddNewCustomerModal updateLoader={updateLoader} setInvoiceCustomer={setInvoiceCustomer} handleError={handleError} setDisplayCustomerList={setDisplayCustomerList} />
                                            </ul>
                                        </div>
                                    }

                                {/* customer-input-container  */}
                                </div> 
                            }
                            {
                                invoiceCustomer &&
                                <div className="customer-data mt-4">
                                    <h4 className="mb-0"><span className='fs-3'>{invoiceCustomer.name}</span>
                                    <UpdateCustomerModal updateLoader={updateLoader} setInvoiceCustomer={setInvoiceCustomer} handleError={handleError} invoiceCustomer={invoiceCustomer} setDisplayCustomerList={setDisplayCustomerList} />
                                    </h4>
                                    <div className="customer_info">
                                        <p className="mb-0">{invoiceCustomer.email}</p>
                                        {
                                            invoiceCustomer.business_address && invoiceCustomer.city && invoiceCustomer.postal_code && invoiceCustomer.country &&
                                            <p>{invoiceCustomer.business_address}, {invoiceCustomer.city}, {invoiceCustomer.postal_code}, {invoiceCustomer.country}</p>
                                        }
                                    </div>
                                {/* selected customer  */}
                                </div> 
                            }
                        </div>
                        {/* right col */}
                        <div className="col-md-6">
                            <label for="" className="mb-0">Invoice #</label>
                            <input type="text" className="form-control mb-2" {...register('invoice_no', { required: true})} />

                            <label for="" className="mb-0">Purchase Order #</label>
                            <input type="text" className="form-control mb-2" {...register('purchase_order_no')}/>

                            <label for="" className="mb-0">Date</label>
                            <input type="date" {...register('invoice_date', { required: true})} className="form-control mb-2" />

                            <label for="" className="mb-0">Due Date</label>
                            <input type="date" {...register('due_date', { required: true})}
                                className="form-control mb-2" />

                           <div className="row mt-3">
                                <div className="col-6">
                                    Add My Signature
                                    {
                                        signature &&
                                        <SignatureModal handleError={handleError} updateLoader={updateLoader} user={user} setUserLogin={setUserLogin}  />
                                    }
                                </div>
                                <div className="col-6">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input float-end" type="checkbox"   checked={!!signature} onClick={() => setSignature(!signature)} />
                                    </div>
                                </div>
                           </div>
                        </div>
                        {/* left col */}


                        {/* items col */}
                        <div className="col-12 my-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">

                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th style={{width:'200px'}}>Item</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                    <th className="text-end">Amount</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    invoiceItems && invoiceItems.length > 0 && invoiceItems.map((item, index) => (
                                                        <>
                                                        
                                                        <tr className="item-tr-{index} item-tr" data-item={index}>
                                                        <td className="pt-2">{item.item_name}<br />{item.product_code ?? ''}</td>
                                                        <td className="pt-2"><input type="number" onChange={(e) => handleItemFieldValueUpdate(index, 'quantity', e.target.value)} className="form-control quantity" value={item.quantity}  /></td>
                                                        <td className="pt-2"><input type="text" onChange={(e) => handleItemFieldValueUpdate(index, 'price', e.target.value)} className="form-control price" value={item.price}  /></td>
                                                        <td className="text-end pt-2">$<span className="item-total">{item.total_price}</span></td>
                                                        <td className="pt-2"><i className='bx bxs-trash-alt text-danger fs-5 cursor-pointer' onClick={() => removeItemFromInvoiceList(index)}></i> </td>
                                                     </tr>
                                                     <tr className="discount-tr-{index} discount-tr" data-item={index}>
                                                         <td colspan="2" className="no-border-top pb-2"><textarea className="form-control" rows="2" value={item.description ?? ''}></textarea></td>
                                                         <td colspan="1" className="no-border-top pb-2"><label className="mb-0 fw-bold text-14">Discount</label><input type="text" value={item.discount}  className="form-control discount" onChange={(e) => handleItemFieldValueUpdate(index, 'discount', e.target.value)} /></td>
                                                         <td colspan="2" className="no-border-top pb-2 pt-4">
                                                            {/* tax html */}
                                                         </td>
                                                     </tr>
                                                        </>
                                                    )) 
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr className="border-top">
                                                    <td colspan="5">
                                                        <label for="" className="mb-0 font-weight-bold mt-4">Add Item</label>
                                                        <div className="input-container w-100 mb-4">
                                                            <input type="text" className="form-control" id="item-input"  value={itemQuery}
                                                            onChange={(e) => setItemQuery(e.target.value)} 
                                                                placeholder="Type your item to add" />
                                                            <span className="item-icon-button icon-button" onClick={() => setDisplayItemsList(!displayItemsList)}>
                                                                <i className='bx bx-chevron-down fs-4'></i>
                                                            </span>
                                                            {
                                                                displayItemsList &&
                                                                <div className="list-container item">
                                                                    <ul id="item-list">
                                                                        {
                                                                            itemsList && itemsList.data && itemsList.data.length > 0 && itemsList.data.map(itemRow => (
                                                                                <li key={itemRow.id} onClick={() => addToInvoiceItems(itemRow)}>{itemRow.item_name}</li>
                                                                            ))
                                                                        }
                                                                        <AddNewItemModal addToInvoiceItems={addToInvoiceItems} updateLoader={updateLoader} handleError={handleError} />
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="border-top">
                                                    <td colspan="2">
                                                        <ShippingAndDiscountModal discount={discount} shipping={shipping} setDiscount={setDiscount} setShipping={setShipping} />
                                                    </td>
                                                    <td colspan="2">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="mb-2">Subtotal</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-2 text-end">$<span className="subtotal_span">{subtotal}</span></p>
                                                            </div>
                                                        </div>

                                                        {
                                                            discount && discount > 0 ?
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <p className="mb-2">Discount</p>
                                                                </div>
                                                                <div className="col-6">
                                                                    <p className="mb-2 text-end">${discount}</p>
                                                                </div>
                                                            </div>
                                                            :''
                                                        }
                                                 
                                                        
                                                        <TaxModal taxType={taxType} setTaxType={setTaxType} taxTypeOne={taxTypeOne} setTaxTypeOne={setTaxTypeOne} taxTypeTwo={taxTypeTwo} setTaxTypeTwo={setTaxTypeTwo} taxOneTotal={taxOneTotal} taxTwoTotal={taxTwoTotal}  />
                                                        {
                                                            shipping && shipping > 0 ?
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <p className="mb-2">Shipping</p>
                                                                </div>
                                                                <div className="col-6">
                                                                    <p className="mb-2 text-end"> ${shipping}</p> 
                                                                </div>
                                                            </div>
                                                            :''
                                                        }

                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="mb-2">Total</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-2 text-end">$<span className="subtotal_span">{total}</span></p>
                                                            </div>
                                                        </div>
                                                        
                                                    </td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td colspan="2"></td>
                                                    <td>Amount Due</td>
                                                    <td className="text-end">$<span className="amount_due_span">{amountDue}</span></td>
                                                    <td></td>
                                                </tr>
                                       
                                        </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* note */}
                        <div className="col-12 my-3">
                            <label htmlFor="">Note</label>
                            <textarea className='form-control' {...register('note')}></textarea>
                        </div>
                        {/* images */}
                        <div className='col-sm-12 mb-3 mt-5'>
                            <label  className="form-label">Upload Images</label>
                            <DropZone setComponentFiles={setImages} componentFiles={images} allowedFileTypes={['image/png','image/jpg','image/jpeg']} multiple={true} />
                            <small>Only png, jpg, jpeg are allowed</small>
                            <FilesPreview files={images} setFiles={setImages} />
                          
                        </div>

                    </div>


              
                   
                </form>
            </div>
        </div>
    </section>
    
    
    
    </>
  )
}

export default AddInvoice