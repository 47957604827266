
import { _get, _post, _delete } from '../../../Client/apiClient'; 


// Fetch all News
export const fetchNews = async (params) => {
   
        const data = await _get(`/news`, params);
        return data;
    
};

// Fetch single News
export const fetchSingleNews = async (id) => {
  
        const data = await _get(`/news/edit/${id}`);
        return data;
   
};

//create new role
export const createNews = async (formData) => {
    
        const data = await _post('/news/store',formData);
        return data;
    
};

// Update a user
export const updateNews = async (formData,id) => {
    
        const data = await _post(`news/update/${id}`, formData);
        return data;
    
};

// Delete a user
export const deleteNews = async (id) => {
  
      const data = await _delete(`/news/delete/${id}`);
      return data;
   
 };


