
import { _get, _post, _patch, _delete } from '../../../Client/apiClient'; 



export const fetchTeam = async (params) => {
    
        const data = await _get(`/team`, params);
        return data;
   
};


export const fetchSingleTeam = async (id) => {
 
        const data = await _get(`/team/edit/${id}`);
        return data;
   
};


export const createTeam = async (formData) => {
    
        const data = await _post('/team/store',formData);
        return data;
    
};


export const updateTeam = async (formData,id) => {
   
        const data = await _post(`team/update/${id}`, formData);
        return data;
   
};


export const deleteTeam = async (id) => {
    
      const data = await _delete(`/team/delete/${id}`);
      return data;
    
  };



