
import { _get, _post } from '../../../Client/apiClient'; 


export const fetchInvoices = async (params) => {
  
        const data = await _get(`/invoice`, params);
        return data;
   
};

export const fetchArchivedInvoices = async (params) => {
   
        const data = await _get(`/invoice/archive/list`, params);
        return data;
   
};

export const fetchCustomers = async (params) => {
   
        const data = await _get(`/user/all`, params);
        return data;
   
};

export const fetchItems = async (params) => {
    
        const data = await _get(`/invoice-item`, params);
        return data;
   
};

export const handleArchive = async (type,id) => {
   
        const data = await _get(`/invoice/${type}/${id}`);
        return data;
   
};



export const fetchCreateInvoiceData = async () => {
 
        const data = await _get(`/invoice/create`);
        return data;
   
};

export const fetchSingleInvoice = async (id) => {
  
        const data = await _get(`/invoice/${id}/edit`);
        return data;
  
};






export const AddNewItem = async (formData) => {
   
        const data = await _post(`/invoice-item` ,formData);
        return data;
  
};

export const createNewInvoice = async (formData) => {
    
    const data = await _post(`/invoice` ,formData);
    return data;
};

export const saveUserSignature = async (formData) => {
    
        const data = await _post(`/user/store-signature` ,formData);
        return data;
};

export const deleteInvoice = async (id) => {
   
    const data = await _get(`/invoice/delete/${id}`);
    return data;
};

export const deleteInvoiceMedia = async (invocieId,key) => {
   
        const data = await _get(`/invoice/delete-media/${invocieId}/${key}`);
        return data;
};

export const addOrUpdateCustomer = async (formData) => {
   
        const data = await _post(`/user/addOrUpdate`, formData);
        return data;
};

export const addDeposit = async (formData) => {
   
        const data = await _post(`/invoice/save-deposit`, formData);
        return data;
};

export const deleteTransaction = async (id) => {
   
        const data = await _get(`/invoice/delete/payment/${id}`);
        return data;
};

export const addPayment = async (formData) => {
   
        const data = await _post(`/invoice/add-payment`,formData);
        return data;
};

export const sendEmail = async (formData) => {
   
        const data = await _post(`/invoice/send-email`,formData);
        return data;
};

 





