
import { _get, _post } from '../../../Client/apiClient'; 


// Fetch all Team
export const fetchJudges = async (params) => {
   
        const data = await _get(`/judges_all`, params);
        return data;
   
};


//judge application approve
export const JudgeApplicationApprove = async (id) => {
   
        const data = await _post(`/judge_approve/${id}`,{});
        return data;
  
};

//judge application reject
export const JudgeApplicationReject = async (id) => {

        const data = await _post(`/judge_reject/${id}`,{});
        return data;
   
};

