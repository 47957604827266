import React,{useState,useEffect} from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHasPermission } from '../../../util/useHasPermission';
import { createOrUpdateGuidebook, deleteGuideBook, fetchGuideBooks } from '../services/GuidebookService';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const CriteriaGuideBook = () => {
    const {updateLoader} = useFilterContext()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const handleError = useErrorHandler()
    const [criteriaGuideBooks, setCriteriaGuideBooks] = useState(null);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalData, setModalData] = useState(null);
    const openModal = (data=null) => { setModalToggle(true);setModalData(data)};
    const closeModal = () => { setModalToggle(false);setModalData(null) };
    const canAddGuidebook = useHasPermission('add_criteria_guidebook')
    const canEditGuidebook = useHasPermission('edit_criteria_guidebook')
    const canDeleteGuidebook = useHasPermission('delete_criteria_guidebook')


    const getData = async() => {
        try {
            updateLoader(true)
            const result = await fetchGuideBooks();
            setCriteriaGuideBooks(result)
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (modalData) {
            reset(modalData);
        } else {
            reset({criteria_guidebook_name:"",link:""}); // Reset to default if no modalData
        }
    }, [modalData, reset]);

    const onSubmit = async(data) => {
        try {
            updateLoader(true)
            const url = modalData ? "update/"+modalData.id : "add";
            const result = await createOrUpdateGuidebook(url, data);
            if(result)
            {
                result.message && toast.success(result.message)
                reset();
                closeModal()
                getData()
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    const handleDelete = async(id) => {
        const isConfirmed = window.confirm("Are you sure you want to perform this action?");
        if(isConfirmed)
        {
            try {
                updateLoader(true)
                const result = await deleteGuideBook(id);
                if(result)
                {
                    result.status && toast.success(result.message)
                    getData()
                }
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
       
    }
  return (
    <>
   
      <section>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-md-4'>
                <div className="col-md-4 mb-3">
                    <h3>Criteria Guidebook</h3>
                </div>
                <div className='col-md-4 mb-3'>
                    
                </div>
                <div className="col-md-4 mb-3">
                    {
                        canAddGuidebook &&
                        <button type="button" className='btn primary-btn float-end'  onClick={() => openModal()}>Add Criteria Guidebook</button>
                    }
                </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {/* <h5 className='card-title'>List of Items</h5> */}
                <div className='table-responsive'>
                  <table className='custom-table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th></th>
                        {
                            (canEditGuidebook || canDeleteGuidebook) &&
                            <th className='text-end'>Action</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                        
                     {
                        criteriaGuideBooks && criteriaGuideBooks.data && criteriaGuideBooks.data.length > 0 && criteriaGuideBooks.data.map(item => (

                        <tr key={item.id}>
                            <td>{item.criteria_guidebook_name}</td>
                            <td>
                                <Link to={item.link} target="_blank" className='btn info-btn-outline'>
                                    Download
                                </Link>
                            </td>
                            {
                                (canEditGuidebook || canDeleteGuidebook) && 
                                <td className='text-end'>
                                    <div className='action-buttons'>
                                        {
                                            canEditGuidebook &&
                                            <button className='edit-btn' type="button" onClick={() => openModal(item)}>
                                            <i className='bx bxs-edit'></i> Edit
                                            </button>
                                        }
                                        {
                                            canDeleteGuidebook &&
                                            <button className='del-btn' type="button" onClick={() => handleDelete(item.id)}>
                                            <i className='bx bxs-trash'></i> Delete
                                            </button>
                                        }
                                    </div>
                                </td>
                            }
                        </tr>
                        ))
                     }
                    </tbody>
                  </table>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Add Modal */}
      <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{modalData ? 'Edit Criteria Guidebook' : 'Add Criteria Guidebook'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Name */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Criteria Guidebook Name</label>
                        {
                            modalData ? 
                            <input type="text" className="form-control" {...register('criteria_guidebook_name', { required: true})} defaultValue={modalData.criteria_guidebook_name}  />
                            :
                            <input type="text" className="form-control" {...register('criteria_guidebook_name', { required: true})}  />
                        }
                    </div>
                    {/* Link */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Criteria Guidebook Link</label>
                        {
                            modalData ? 
                            <input type="text" className="form-control" {...register('link', { required: true})} defaultValue={modalData.link}  />
                            :
                            <input type="text" className="form-control" {...register('link', { required: true})}  />
                        }
                    </div>

                    <div className="col-12"><button className="btn primary-btn" type="submit">Save</button></div>

                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default CriteriaGuideBook