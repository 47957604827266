
import { _get, _post, _delete } from '../../../Client/apiClient'; // Adjust the import path as necessary


export const fetchRoles = async (params) => {
 
    const data = await _get('/role/all',params);
    return data;
  
};

export const fetchRolePermissions = async (id) => {
   
      const data = await _get('/rolepermission/edit/'+id);
      return data;
   
};

export const updateRolePermissions = async (formData) => {
  
      const data = await _post('/rolepermission/edit',formData);
      return data;
   
};


export const createRole = async (roleData) => {
    
        const data = await _post('/role/store',roleData);
        return data;
    
};


export const updateRole = async (roleData) => {
 
        const data = await _post(`role/update`, roleData);
        return data;
   
};


export const deleteRole = async (id) => {
  
      const data = await _delete(`/role/delete/${id}`);
      return data;
   
};



