import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DropZone from '../../../Components/DropZone';
import FilesPreview from '../../../Components/FilesPreview';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { updateNews,fetchSingleNews } from '../services/NewsService';
import { useParams } from 'react-router-dom';
import { useFilterContext } from '../../../context/filterContext';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const NewsEdit = () => {
    const handleError = useErrorHandler()
    const {updateLoader} = useFilterContext()
    const { id } = useParams();
    const [news, setNews] = useState(null)
    const [images, setImages] = useState([]);
    const [description, setDescription] = useState('');
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

      const getData = async() => {
        try {
            updateLoader(true)
            const result = await fetchSingleNews(id)
            if(result)
            {
                setNews(result.data)
                
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, [id]);

    useEffect(() => {
        if(news){
            reset({
                title: news.title || '',
                date_added: news.date_added || ''
            });
            setDescription(news.description)
        }
    }, [news])
    

    const onSubmit = async(data) => {
        if(description === '')
        {
            toast.error('News Description is required');
        }else{
            try {
                updateLoader(true)
                const formData = new FormData();
                formData.append('title', data.title);
                formData.append('description', description);
                formData.append('date_added', data.date_added);
                if(images && images[0])
                {
                    formData.append('image', images[0]);
                }
    
                const result = await updateNews(formData, news.id)
                if(result)
                {
                    result.message && toast.success(result.message)
                    // reset();
                    getData()
                }
            
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
        
    }

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setDescription(data);
    };
  return (
    <>
   
      <section>
        <div className='card'>
          <div className='card-body'>
          <div className="row">
        <div className="col-md-6 mb-4">
            <h3>Edit News</h3>
        </div>
        <div className="col-md-6 mb-4">
        </div>
        
    </div>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className='row'>
                    {/* Name */}
                    <div className='col-md-8 mb-3'>
                        <label  className="form-label">News Title</label>
                        <input type="text" className="form-control" {...register('title', { required: true})} />
                        {errors.title && <p className='error-msg'>Title is required</p>}
                    </div>
                    {/* Date */}
                    <div className='col-md-4 mb-3'>
                        <label  className="form-label">Date</label>
                        <input type="datetime-local" className="form-control" {...register('date_added', { required: true})}  />
                        {errors.date_added && <p className='error-msg'>Date is required</p>}
                    </div>
                    
                    {/* Description */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Description</label>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={description}
                            onChange={handleEditorChange}
                        />
                    </div>
                    {/* Award Image */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">News Image</label>
                        {
                            images.length < 1 &&
                            <>
                            <DropZone setComponentFiles={setImages} componentFiles={images} allowedFileTypes={['image/png','image/jpg','image/jpeg']}/>
                            <small>Only png, jpg, jpeg are allowed</small>
                            </>
                        }
                        <FilesPreview files={images} setFiles={setImages} />
                    </div>
                    {
                        news && news.image &&
                        <div className='col-md-6 mb-3 text-end'>
                            <img src={news.image} alt={news.title} width="100px" height="auto" className='mt-4' />
                        </div>
                    }
                      

                    <div className="col-12"><button className="btn primary-btn" type="submit">Save</button></div>

                </div>
            </form>
          </div>
        </div>
      </section>

      
    </>
  )
}

export default NewsEdit