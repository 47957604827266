import React, { useState, useEffect  } from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { server } from '../../../util/Server';
import { toast } from 'react-toastify';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { CriteriaHeadingAnswerStore, SubmitAwardApplication,AwardApplicationApply } from '../services/ApplicationService';
import FileUploaded from './inc/FileUploaded';
import MediaFiles from './inc/MediaFiles';
import AwardApplyTabEditor from './inc/AwardApplyTabEditor';
import { useErrorHandler } from '../../../hooks/useErrorHandler';


const ApplicationAwardApply = () => {
    const { user,updateLoader } = useFilterContext()
    const handleError = useErrorHandler()
    const { awardId, applicationId } = useParams();
    const [award, setAward] = useState(null)
    const [application, setApplication] = useState(null)
    const [criterias, setCriterias] = useState([])
    const [activeTab, setActiveTab] = useState(0)
    const [answers, setAnswers] = useState([])
    const [agreeToTerms, setAgreeToTerms] = useState(false)

    const getData = async () => {
        try {
            updateLoader(true)
            const params = {
                'user_id' : user.id
            }
            const result = await AwardApplicationApply(awardId,applicationId,params);
            if(result)
            {
                setAward(result.award)
                setApplication(result.application)
                setCriterias(result.criterias)
                result.application && setAnswers(result.application.answers)
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, [awardId, applicationId]);

    const segments = (text) => {
        const splitText = text.split(':').map(segment => segment.trim());
        return "<p class='pt-3 mb-0'>"+splitText[0]+"</p><p>"+(splitText[1] ? splitText[1] : '')+"</p>"; 
    } 

    const headingAnswer = (headingId) => {
        const answer = answers.find(answer => 
            answer.heading_id === headingId && 
            answer.application_id === application.id
        );
        return answer ? answer.text : ''; 
    }

    const onChange = (newValue, headingId) => {
        const html = newValue;
        setAnswers(prevAnswers => {
            // Check if the answer for this heading exists
            const existingAnswerIndex = prevAnswers.findIndex(answer =>
                answer.heading_id === headingId && answer.application_id === application.id
            );
    
            if (existingAnswerIndex !== -1) {
                // Answer exists, so update it
                return prevAnswers.map((answer, index) =>
                    index === existingAnswerIndex
                        ? { ...answer, text: html } // Update the specific answer
                        : answer
                );
            } else {
                // Answer does not exist, so add a new one
                return [
                    ...prevAnswers,
                    {
                        heading_id: headingId,
                        application_id: application.id,
                        text: html,
                        user_id:user.id
                    }
                ];
            }
        });
    };

    // Save value on blur
    const saveAnswerOnBlur = async (headingId, content) => {
        // const answer = answers.find(answer => 
        //     answer.heading_id === headingId && 
        //     answer.application_id === application.id
        // );

        const formData = new FormData();
        formData.append('application_id', application.id);
        formData.append('heading_id', headingId);
        formData.append('answer', content);

        try {
            // updateLoader(true)
            const result = await CriteriaHeadingAnswerStore(formData)
            if(result)
            {
                result.message && toast.success(result.message)
            }
        } catch (error) {
            handleError(error)
        }finally{
            // updateLoader(false)
        }

    
    };


    const submistHandler = async() => {
        if(!agreeToTerms)
        {
            toast.error('Please agree to terms and conditions to proceed!')
        }else{
            const isConfirmed = window.confirm("Are u sure? This will submit the application to judes");
            if(isConfirmed)
            {

                //submit form
                try {
                    updateLoader(true)
                    const result = await SubmitAwardApplication({'application_id':applicationId, 'award_id':awardId })
                    if(result)
                    {
                        result.message && toast.success(result.message)
                    }
                } catch (error) {
                    handleError(error)
                }finally{
                    updateLoader(false)
                }
            }

        }
    }

    const downloadAwardPdf = async() => {
        try{
            updateLoader(true)
            const url = `${server}/application/pdf/${award.id}/${user.id}/${application.id}`;

            // Fetch the PDF file from the endpoint
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/pdf',
                'Authorization': user.token ? `Bearer ${user.token}` : null
                }
            });

            if (!response.ok) {
                toast.error('Unexpected Error')
            }else{

                // Convert the response to a blob
                const blob = await response.blob();
                
                // Create a URL for the blob
                const blobUrl = URL.createObjectURL(blob);
                
                // Create a link element
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = `${award.name}-${application.id}.pdf`; // Set the filename for the download
                // Append the link to the document
                document.body.appendChild(link);
            
                // Programmatically click the link to trigger the download
                link.click();
    
                // Remove the link from the document
                document.body.removeChild(link);
    
                // Revoke the object URL to free up memory
                URL.revokeObjectURL(blobUrl);
            }



        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    return (
    <>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <h3>Apply for {award && award.name}</h3>
                </div>
                <div className="col-md-6 mb-4">
                {
                    award &&
                    <>
                    <Link to={award.award_file} className='btn primary-btn ms-2 float-end' download>Download Word File</Link>
                    <button type="button" onClick={() => downloadAwardPdf()} className='btn primary-btn float-end'>Download PDF</button>
                    </>
                }
               
                </div>

            </div>

            <div className="page mb-5">
                <div className="pcss3t pcss3t-effect-scale pcss3t-theme-1 pb-5">
                    <input type="radio" name="pcss3t" checked={activeTab == 0 ? true : false} id="tab0" onChange={() => setActiveTab(0)} className="tab-content-first" />
                    <label htmlFor="tab0" ><p className='pt-3 mb-0'>Award</p><p>Detail</p></label>

                    {
                        criterias && criterias.length > 0 && criterias.map((criteria, index)=> {
                            const tabIndex = index + 1;
                            return (<>
                            <input type="radio" name="pcss3t" id={"tab"+(tabIndex)} checked={activeTab == (tabIndex) ? true : false} className={"tab-content-"+(tabIndex)} onChange={() => setActiveTab(tabIndex)} />
                            <label htmlFor={"tab"+(tabIndex)}  onClick={() => setActiveTab((tabIndex))} dangerouslySetInnerHTML={{ __html: segments(criteria.text)}} />
                              
                            </>
                            )
                        })
                    }
                

                    <ul>
                        <li className="tab-content tab-content-first typography">
                            {
                                award &&
                                <>
                                <h1>HBC Award Model</h1>
                                {
                                    award.modal_image &&  <img src={award.modal_image} height="auto" width="80%" className='d-block m-auto' />
                                }
                               
                                <div dangerouslySetInnerHTML={{__html:award.description}} />
                                <button className='btn primary-btn mt-5 rounded' onClick={() => setActiveTab(1)}>Next </button>
                                </>
                            }
                        </li>
                        {
                            criterias && criterias.length > 0 && criterias.map((criteriaRow,index )=> {
                                const tabIndex = index + 1;
                                return(
                                <>
                                 <li className={`tab-content tab-content-${tabIndex} typography`}  key={'tab-data-'+tabIndex}>
                                    {
                                        criteriaRow.weight &&
                                        <p className='fw-bold fs-5 text-center'>Criteria Weight: {criteriaRow.weight}</p>
                                    }
                                    {
                                        criteriaRow.headings && criteriaRow.headings.length > 0 && 
                                        criteriaRow.headings.map(heading => (
                                            <>
                                           
                                            <h2>{heading.text}</h2>
                                            <p>Guidance Criteria...</p>
                                            {
                                                heading.questions && heading.questions.length > 0 && heading.questions.map(question => (
                                                    <p className='fw-bold'>{question.number}. {question.text}</p>
                                                ))
                                            }

                                            <AwardApplyTabEditor headingId={heading.id} headingAnswer={headingAnswer} saveAnswerOnBlur={saveAnswerOnBlur} />

                                            {/* <CKEditor
                                                editor={ ClassicEditor }
                                                data={headingAnswer(heading.id)}
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    onChange(data, heading.id);
                                                } }
                                                onBlur={ ( event, editor ) => {
                                                    onBlur(heading.id)
                                                } }
                                              
                                            /> */}

                                            {/* <SimpleMDEditor value={headingAnswer(heading.id)}   onChange={(newValue) => onChange(newValue, heading.id)}  onBlur={() => onBlur(heading.id)} /> */}
                                            </>
                                        ))
                                    }

                                    {/* supporting documents */}
                                    <hr />
                                    <h4 className='mb-2'>Supporting Documents </h4>
                                    {
                                        criteriaRow.media.length > 0 &&
                                        <MediaFiles files={criteriaRow.media} />
                                    }
                                    <br />
                                    <FileUploaded criteriaId={criteriaRow.id} applicationId={application.id} setCriterias={setCriterias} userId={user.id} handleError={handleError} />
                                  



                                    {    
                                        tabIndex == criterias.length &&
                                        <div className="form-check mt-2">
                                        <input className="form-check-input" type="checkbox" value="1" checked={agreeToTerms}  onClick={() => setAgreeToTerms(!agreeToTerms)} id="agree" />
                                        <label className="form-check-label" htmlFor="agree">
                                        I have read and agreed to HBC<Link to="https://hbcworld.org/terms-conditions" className='color-primary'> Terms</Link>, conditions and<Link className='color-primary' to="https://hbcworld.org/privacy-policy"> privacy policy</Link></label>
                                        </div>
                                    }

                                    <button className='btn info-btn me-2 mt-5 rounded' type="button"  onClick={() => setActiveTab(tabIndex-1)}>Back </button>
                                    {
                                        tabIndex == criterias.length ?
                                        <button className='btn primary-btn mt-5 rounded' type="button"  onClick={() => submistHandler()}>Submit </button>
                                        :
                                        <button className='btn primary-btn mt-5 rounded' type="button" onClick={() => setActiveTab(tabIndex+1)}>Next </button>
                                    }
                                  
                                </li>
                                </>
                                )
                            })
                        }
                       

                        
                    </ul>
                </div>

             
            </div>

    </>
    )
}

export default ApplicationAwardApply