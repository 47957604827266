import React,{useState,} from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ReactMultiEmail } from "react-multi-email";
import { sendEmail } from '../../services/InvoiceService';

const SendEmail = ({invoiceData,updateLoader,handleError}) => {
    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false)};

    const [toEmails, setToEmails] = useState([invoiceData.user.email])
    const [bccEmails, setBccEmails] = useState([])
    const [description, setDescription] = useState("Thank you for your business.")

    const handleSubmit = async(e) => {
        e.preventDefault()
        if(toEmails.length === 0)
        {
            toast.error('Please add atleast one email')
        }else{
            try {
                updateLoader(true) 
              
                const result = await sendEmail({
                    'to_email':toEmails,
                    'bcc_email':bccEmails,
                    'message':description,
                    'id': invoiceData.id
                })
                if(result)
                {
                    toast.success(result.message)
                    setToEmails([invoiceData.user.email])
                    setBccEmails([])
                    setDescription("Thank you for your business.")
                    closeModal()
                }
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
     
        
    }

    //to emails
    const handleChange = (newEmails) => {
        setToEmails(newEmails);
    };

  // Function to generate the label for each email
  const getLabel = (email, index, removeEmail) => {
    return (
      <div data-tag key={index}>
        {email}
        <span data-tag-handle onClick={() => removeEmail(index)}>
          ×
        </span>
      </div>
    );
  };

    //bcc emils
    const handleBccEmailChange = (newEmails) => {
        setBccEmails(newEmails);
    };
    const getbccEmailLabel = (email, index, removeEmail) => {
        return (
          <div data-tag key={index}>
            {email}
            <span data-tag-handle onClick={() => removeEmail(index)}>
              ×
            </span>
          </div>
        );
      };
  return (
    <>
    <button type="button" className='btn info-btn float-end ms-2' onClick={() => openModal()} > Send</button>


      {/* Add Modal */}
   <Modal show={modalToggle} onHide={() => closeModal()} size="lg">
           <Modal.Header closeButton>
               <Modal.Title>Send document</Modal.Title>
           </Modal.Header>
           <Modal.Body>
               <form>
                   <div className="row">
                        <div className="col-12 mb-3">
                            <label htmlFor="">To</label>
                            <ReactMultiEmail
                                placeholder="Input Email Address and Type Enter"
                                emails={toEmails}
                                onChange={handleChange}
                                getLabel={getLabel}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <label htmlFor="">Bcc</label>
                            <ReactMultiEmail
                                placeholder="Input Email Address and Type Enter"
                                emails={bccEmails}
                                onChange={handleBccEmailChange}
                                getLabel={getbccEmailLabel}
                            />
                        </div>

                        <div className="col-12 mb-3">
                            <textarea name="description" value={description} onChange={(e) => setDescription(e.target.value)} className='form-control'></textarea>
                        </div>




                       <div className="col-12"><button className="btn primary-btn" type="button" onClick={(e) => handleSubmit(e)}>Save</button></div>
                   </div>


               </form>
           </Modal.Body>
       </Modal>
   </>
  )
}

export default SendEmail