import React,{useState} from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { deleteTransaction } from '../../services/InvoiceService';
import { Link } from 'react-router-dom';
import AddPayment from './AddPayment';

const TransactionModal = ({invoiceData,getData,handleError,updateLoader}) => {


    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false)};
    
    const handleDelete = async(id) => {
        const isConfirmed = window.confirm("Are you sure you want to perform this action?");
        if(isConfirmed)
        {

            try {
                updateLoader(true)
                const result = await deleteTransaction(id)
                if(result)
                {
                    result.message && toast.success(result.message)
                    getData(); 
                }
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
    }
    
  return (
    <>
        <Link to="#"  onClick={() => openModal()} className="color-primary">View transactions</Link>
    

    {/* Add Modal */}
    <Modal show={modalToggle} onHide={() => closeModal()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>View transactions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className='table'>
                    <thead>
                        <tr>
                            <td className="no-border-top">Date</td>
                            <td className="no-border-top">Note</td>
                            <td className="no-border-top">Method</td>
                            <td className="no-border-top text-end">Amount</td>
                            <td className="no-border-top"></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            invoiceData.transactions && invoiceData.transactions.length > 0 && invoiceData.transactions.map(transaction => (
                                <tr>
                                    <td>{transaction.date_format}</td>
                                    <td>{ transaction.note }</td>
                                    <td>{ transaction.method }</td>
                                    <td className="text-end">${ transaction.amount }</td>
                                    <td className="text-end">
                                        {
                                            invoiceData.status !== 3 &&
                                            <button className='btn danger-btn m-1' type="button" onClick={() => handleDelete(transaction.id)}>
                                            <i className='bx bxs-trash text-light'></i> Delete
                                            </button>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={5}>
                                <AddPayment invoiceData={invoiceData} handleError={handleError} updateLoader={updateLoader}  getData={getData} />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default TransactionModal