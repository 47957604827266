import React from 'react'
import pdf from '../../../../img/pdf.png'
import doc from '../../../../img/doc.png'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteInvoiceMedia } from '../../services/InvoiceService';

const MediaFiles = ({files,updateLoader,invoiceId,getData}) => {
    const getFileIcon = (file, extension) => {
        if (extension === 'pdf') {
          return pdf; // Return image icon for image files
        } else if (extension === 'docx') {
          return doc; // Return PDF icon for PDF files
        } else {
          return file.original_url
        }
      
    };


    const deleteImage = async(key) => {
      const isConfirmed = window.confirm("Are you sure you want to perform this action?");
      if(isConfirmed)
      {
          try {
              updateLoader(true)
              const result = await deleteInvoiceMedia(invoiceId, key)
              if(result)
              {
                toast.success(result.message)
                getData()

              }
          } catch (error) {
              toast.error('Unexpected Error') 
          }finally{
              updateLoader(false)
          }
      }
    }

  return (
    <div className="media-files">
        {files.length > 0 ? (
        files.map((file, index) => {
            const fileIcon = getFileIcon(file, file.extension);
            
            return (
                <div key={index} className="d-inline-block m-2 position-relative">
                <Link to={file.original_url} target="_blank" className='d-block'>
                  <img src={fileIcon} alt={file.name} width="120px" />
                </Link>
                
                {/* Delete Icon */}
                <button 
                  className="position-absolute btn btn-danger btn-sm py-1 px-2"
                  style={{ margin: '5px',top:'-10px',right:'-16px' }} 
                  onClick={() => deleteImage(index)}
                 
                >
                  <i className='bx bxs-trash-alt' style={{color:'#fff'}}></i>
                </button>
              </div>
            );
        })
        ) : (
        <p>No image uploaded.</p>
        )}
    </div>
  )
}

export default MediaFiles