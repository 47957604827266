import React from 'react'
import { Link } from 'react-router-dom'

const PermissionDenied = () => {
  return (
    <>
    
        
    <div className="container">
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
                <div className="col-lg-12 text-404">
                    403
                </div>
                <div className="col-lg-12 text">
                    <b> ACCESS DENIED </b>
                </div>
                <div className="col-lg-12 text-btn">
                    <Link to="/dashboard"  className="btn primary-btn">Go TO Dashboard</Link>
                </div>
            </div>
            <div className="col-lg-2"></div>
        </div>
    </div>
    
    
    </>
  )
}

export default PermissionDenied