
import { _get, _post, _put, _delete } from '../../../Client/apiClient'; // Adjust the import path as necessary


export const fetchGuideBooks = async (params) => {
 
    const data = await _get('/criteriaguidebook',params);
    return data;
 
};

export const createOrUpdateGuidebook = async (url,formData) => {

      const data = await _post(`criteriaguidebook/${url}`,formData);
      return data;
   
};


export const deleteGuideBook = async (id) => {
 
      const data = await _delete(`/criteriaguidebook/delete/${id}`);
      return data;
   
};


