import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const DropZone = ({setComponentFiles, allowedFileTypes, multiple=false}) => {


  const accept = allowedFileTypes.reduce((acc, type) => {
    acc[type] = [];
    return acc;
  }, {});

  const onDrop = useCallback((acceptedFiles) => {
      setComponentFiles(prevFiles => [
            ...prevFiles,
            ...acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        ]);
  }, []);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
    multiple: multiple,  // Allows only one file to be dropped
  });


  return (
    <section>
      <div {...getRootProps({ className: 'dropzone' })} className='rounded main-dropzone'>
        <input {...getInputProps()} />
        <div className='inner-dropzone rounded height-60'>
          <div className='height-60 d-flex flex-column align-items-center justify-content-center'>
            <p className='mb-0'>Drag & Drop Files Here Or Click To Select Files</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DropZone;
