import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { SubmitAwardApplicationCriteriaFiles } from '../../services/ApplicationService';

const FileUploaded = ({criteriaId,applicationId,setCriterias,userId,handleError}) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false); // Track upload state

  const onDrop = useCallback((acceptedFiles) => {
    // Update state with new files
    setFiles(prevFiles => [
      ...prevFiles,
      ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    ]);

    // Upload files
    handleUpload(acceptedFiles);
  }, [files]);

  const handleUpload = async (filesToUpload) => {
    setUploading(true); // Set loading state to true
    try {
        // Replace with your actual upload API
        const formData = new FormData();
        filesToUpload.map(file => (
            formData.append('files[]', file)
        ));
        formData.append('criteria', criteriaId);
        formData.append('application_id', applicationId);
        formData.append('user_id', userId);
        
        const result = await SubmitAwardApplicationCriteriaFiles(formData);
        if(result)
        {
            setCriterias(result.criterias)
            toast.success(result.message);
        }
    } catch (error) {
      handleError(error)
    } finally {
      setUploading(false); // Reset loading state
    }
  };

  // Revoke data uris to avoid memory leaks
  useEffect(() => {
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    disabled: uploading // Disable dropzone during upload
  });

  

  return (
    <section>
      <div {...getRootProps({ className: 'dropzone' })} className='rounded main-dropzone'>
        <input {...getInputProps()} />
        <div className='inner-dropzone rounded height-60'>
          <div className='height-60 d-flex flex-column align-items-center justify-content-center'>
            <p className='mb-0'>{uploading ? 'Uploading files... Please Wait' : 'Drag & Drop Files Here Or Click To Select Files'}</p>
          </div>
        </div>
      </div>
      <small>Only png, jpg, jpeg, pdf, docx are allowed</small>
    </section>
  );
};


export default FileUploaded