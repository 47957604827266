import React, { useRef, useEffect,useState,useCallback } from 'react';
import JoditEditor from 'jodit-react';

const TabEditor = ({ criteriaId,user_id,type,saveData,judgeCriteriaWeight }) => {
    const editorRef = useRef(null);
  

    const saveEditorDataOnBlur = (newContent) => {
        if (typeof saveData === 'function') {
            saveData(criteriaId,user_id,type,newContent)
        } else {
            console.error('saveData is not a function');
        }
       
    }

    const config = {
        readonly: false,
        height: 'auto',
        toolbarButtonSize: "small",
        showTooltip:false,
        removeButtons: ['hr', 'source','spellcheck','speechRecognize','aicommands','aiassisstant','source', 'print', 'fullscreen', 'video']
    };

    return (
        <>
            <JoditEditor
                ref={editorRef}
                value={judgeCriteriaWeight(criteriaId,user_id,type)}
                // onChange={onChange}
                config={config}
                onBlur={newContent => saveEditorDataOnBlur(newContent)} 
            />
        </>
    );
};

export default TabEditor