import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { addPayment } from '../../services/InvoiceService';

const AddPayment = ({invoiceData,handleError,updateLoader,getData}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();


    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false);reset()};

    useEffect(() => {
      reset({
        method:'Cash',
        amount:invoiceData.amount_due
      })
    }, [invoiceData])
    


    const onSubmitPayment = async(data, e) => {
        e.preventDefault(); // Prevents the form from submitting the parent form
       
        
        try {
            updateLoader(true)
            data['invoice_id'] = invoiceData.id
            const result = await addPayment(data);
            if(result)
            {
                result.message && toast.success(result.message)
                reset();
                closeModal()
                getData()

            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }
    return (
        <>
     
     {
        invoiceData.status !== 3 &&
        <button  onClick={() => openModal()} className="btn primary-btn float-end mt-2">Add Payment</button>
     }

     
        
    
        {/* Add Modal */}
        <Modal show={modalToggle} onHide={() => closeModal()} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Add Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form >
                        <div className="row">
                        <div className="col-md-12 mb-2">
                            <label for="" className="mb-0">Amount Paid</label>
                            <input type="text" className="form-control" {...register('amount',{required:true})} />
                            {errors.amount && <p className='error-msg'>Amount is required</p>}
                        </div>
                        <div className="col-md-6 mb-2">
                            <label for="" className="mb-0">Date</label>
                            <input type="date" className="form-control" {...register('date',{required:true})} />
                            {errors.date && <p className='error-msg'>Date is required</p>}
                        </div>

                        <div className="col-md-6 mb-2">
                            <label for="" className="mb-0">Method</label>
                            <select {...register('method',{required:true})}  className="form-control">
                                <option value="Cash">Cash</option>
                                <option value="Check">Check</option>
                                <option value="Credit Card">Credit Card</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                            </select>
                            {errors.method && <p className='error-msg'>Method is required</p>}
                        </div>

                        <div className="col-md-12 mb-2">
                            <label for="" className="mb-0">Note</label>
                            <textarea {...register('note')}  className="form-control"></textarea>
                        </div>
    
                            <div className="col-12"><button className="btn primary-btn" type="button" onClick={handleSubmit(onSubmitPayment)}>Save</button></div>
                        </div>
    
    
                    </form>
                </Modal.Body>
            </Modal>
        </>
      )
}

export default AddPayment