
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFilterContext } from '../context/filterContext';

export const useErrorHandler = () => {
  const navigate = useNavigate();
  const {unsetUserLogin} = useFilterContext()

  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
      unsetUserLogin()
      // navigate('/'); // Navigate to the unauthenticated page
    }else if (error.response && error.response.status === 403) {
      navigate('/403'); // Navigate to the unauthorized page
    } else if (error.response && error.response.status === 422) {
      const errors = error.response.data.errors;
      Object.keys(errors).forEach((key) => {
        errors[key].map((item) => {
          toast.error(item);
        });
      });
    }else if (error.response && error.response.status === 500) {
        error.response.data.message && toast.error(error.response.data.message);
    } else {
      toast.error('Unexpected Error');
    }
  };

  return handleError;
};
