import React,{useState,useEffect} from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useHasPermission } from '../../../util/useHasPermission';
import { deleteAward, fetchAwards } from '../services/AwardService';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import ReactPaginate from 'react-paginate';

const AwardsList = () => {
    const handleError = useErrorHandler()
    const {updateLoader} = useFilterContext()
    const [awards, setAwards] = useState(null);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const canEditAward = useHasPermission('edit_award')
    const canDeleteAward = useHasPermission('delete_award')
    const canAddAward = useHasPermission('add_award') 
    const canAddCriteria = useHasPermission('criteria') 

    const getData = async(pageNumber, search='') => {
        try {
          updateLoader(true)
            const params = {
                'page' :pageNumber,
                'search':search
            }
            const result = await fetchAwards(params);
            if(result)
            {

              setAwards(result)
            }
        } catch (error) {
          handleError(error)
        }finally{
          updateLoader(false)
        }
    }

    useEffect(() => {
      getData(page, search);
    }, [page]);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        getData(1, search); 
    };

    const clearSearch = () => {
        setSearch('')
        setPage(1);
        getData(1); 
    }

    const handleDelete = async(id) => {
      const isConfirmed = window.confirm("Are you sure you want to perform this action?");
      if(isConfirmed)
      {

        try {
          updateLoader(true)
            const result = await deleteAward(id);
            if(result)
            {
              result.status && toast.success(result.message)
              !result.status && toast.error(result.message)
              getData()
            }
        } catch (error) {
          handleError(error)
        }finally{
            updateLoader(false)
        }
      }
   
    }
  return (
    <>
      <section>
        <div className='card'>
          <div className='card-body'>
          <div className='row mb-4'>
              <div className="col-md-4 mb-3">
                <h3>All Awards</h3>
              </div>
              <div className='col-md-4 mb-3'>
                <div className="input-group search-area">
                  <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                  <span className="input-group-text">
                    <button type="submit" className="btn" onClick={(e) => handleSearchSubmit(e)}>
                      <i className='bx bx-search'></i>
                    </button>
                      
                  </span>
                  {
                    search &&
                    <i className='bx bx-x fs-3 text-danger mt-2 ms-2 cursor-pointer' onClick={(e) => clearSearch(e)}></i>
                  }
                </div>
              </div>
                <div className="col-md-4 mb-3">
                {
                  canAddAward &&
                  <Link to="/award/add" className='btn primary-btn float-end'>Add Award</Link>
                }
                </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='table-responsive'>
                  <table className='custom-table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Date Added</th>
                        <th className='text-end'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        
                     {
                        awards && awards.data && awards.data.length > 0 && awards.data.map(item => (

                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.date_added}</td>
                            <td className='text-end text-no-wrap'>
                                <div className='action-buttons'>
                                  {
                                    canAddCriteria &&
                                    <Link className='edit-btn m-1' to={"/award/criteria/"+item.id} >
                                    <i className='bx bxs-edit'></i> Criteria
                                    </Link>
                                  }
                                    {
                                       canEditAward && 
                                      <Link className='edit-btn m-1' to={"/award/edit/"+item.id} >
                                      <i className='bx bxs-edit'></i> Edit
                                      </Link>
                                    }
                                    {
                                       canDeleteAward && 
                                      <button className='del-btn m-1' type="button" onClick={() => handleDelete(item.id)}>
                                      <i className='bx bxs-trash'></i> Delete
                                      </button>
                                    }
                                </div>
                            </td>
                        </tr>
                        ))
                     }
                    </tbody>
                  </table>
                
                </div>
              </div>
              {
                awards && awards.meta && awards.meta.last_page > 1 &&
                <div className="col-12">
                     <ReactPaginate
                        pageCount={awards.meta.last_page}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={(event) => {
                            setPage((event.selected)+1);
                        }}
                        containerClassName={"pagination"}
                        pageClassName={"page-link"}
                        activeClassName={"active"}
                        previousLabel='<'
                        nextLabel='>'
                    />
                </div>
            }
            </div>
          </div>
        </div>
      </section>

     
    </>
  )
}

export default AwardsList