import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useHasPermission } from '../../../util/useHasPermission';
import { useFilterContext } from '../../../context/filterContext';
import { deleteInvoice, fetchInvoices, handleArchive } from '../services/InvoiceService';
import InvoiceFilterBar from './inc/InvoiceFilterBar';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import ReactPaginate from 'react-paginate';

const InvoiceList = () => {
    const handleError = useErrorHandler()
  const {updateLoader} = useFilterContext()
    const [invoices, setinvoices] = useState(null);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const canAddInvoice = useHasPermission('add_invoices')
    const canEditInvoice = useHasPermission('edit_invoice')
    const canDeleteInvoice = useHasPermission('delete_invoice') 

    const getData = async(pageNumber, search='') => {
        try {
          updateLoader(true)
            const searchParams = { ...search, page: pageNumber };
            const data = await fetchInvoices(searchParams)
            setinvoices(data);
        } catch (error) {
            handleError(error)
        }finally{
          updateLoader(false)
        }
    }

    useEffect(() => {
        setPage(1)
        getData(1,search);
    }, [search]);
  
    useEffect(() => {
      getData(page, search);
    }, [page]);

   

    const handleDelete = async(id) => {
      const isConfirmed = window.confirm("Are you sure you want to perform this action?");
      if(isConfirmed)
      {

        try {
          updateLoader(true)
            const result = await deleteInvoice(id)
            if(result)
            {
                result.message && toast.success(result.message)
                getData(1); 
            }
        } catch (error) {
            handleError(error)
        }finally{
          updateLoader(false)
        }
      }
    }

    const addToArchive = async(id) => {
          try {
            updateLoader(true)
              const result = await handleArchive('archive',id)
              if(result)
              {
                  result.message && toast.success(result.message)
                  getData(1); 
              }
          } catch (error) {
            handleError(error)
          }finally{
            updateLoader(false)
          }
    
    }

  return (
    <>
    
      <section>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className="col-md-6 mb-4">
                  <h3>Invoices</h3>
              </div>
            
              <div className="col-md-6 mb-4">
                {
                  canAddInvoice &&
                    <Link to="/invoice/create" className='btn primary-btn float-end ms-2'>Add Invoice</Link>
                }
                    <Link to="/invoice/archive/list" className='btn info-btn float-end'>Archived</Link>
              
              </div>
            </div>
            <InvoiceFilterBar setSearch={setSearch} />
            <div className='row'>
              <div className='col-12'>
                <div className='table-responsive'>
                  <table className='custom-table'>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Invoice</th>
                        <th>Customer</th>
                        <th>Amount</th>
                        <th>Due</th>
                        <th>Issued</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        
                     {
                        invoices && invoices.data && invoices.data.length > 0 && invoices.data.map(item => (

                        <tr key={item.id}>
                            <td>
                              <span className={`badge ${item.status == 0 ? 'bg-danger' : 'bg-success'}`}>{item.status_text}</span>
                              </td>
                            <td>{item.invoice_no}</td>
                            <td>{item.user.name}</td>
                            <td>${item.total}</td>
                            <td>{item.due_date_format}</td>
                            <td>{item.invoice_date_format}</td>
                            <td className='text-no-wrap'>
                                <div className='action-buttons'>
                                {
                                     
                                     <button className='edit-btn m-1' type="button" onClick={() => addToArchive(item.id)}>
                                    <i className='bx bx-archive-in'></i> Archive
                                     </button>
                                 }
                                  {
                                     item.status !== 3 && canEditInvoice &&
                                      <Link className='edit-btn m-1'to={"/invoice/"+item.id+"/edit"} >
                                      <i className='bx bxs-edit'></i> Edit
                                      </Link>
                                  }
                                    {
                                   
                                      <Link className='edit-btn m-1'to={"/invoice/"+item.id} >
                                      <i className='bx bx-detail'></i> Preview
                                      </Link>
                                  }
                                  {
                                    canDeleteInvoice &&
                                    <button className='del-btn m-1' type="button" onClick={() => handleDelete(item.id)}>
                                    <i className='bx bxs-trash'></i> Delete
                                    </button>
                                  }
                                </div>
                            </td>
                        </tr>
                        ))
                     }
                    </tbody>
                  </table>
                
                </div>
              </div>

            {
                invoices && invoices.meta && invoices.meta.last_page > 1 &&
                <div className="col-12">
                     <ReactPaginate
                        pageCount={invoices.meta.last_page}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={(event) => {
                            setPage((event.selected)+1);
                        }}
                        containerClassName={"pagination"}
                        pageClassName={"page-link"}
                        activeClassName={"active"}
                        previousLabel='<'
                        nextLabel='>'
                    />
                </div>
            }
            </div>
          </div>
        </div>
      </section>

     
    </>
  )
}


export default InvoiceList