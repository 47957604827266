import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useFilterContext } from '../../../context/filterContext';
import { server } from '../../../util/Server';
import { toast } from 'react-toastify';
import { useNavigate,Link,useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AddOrUpdateAwardCriteria, fetchAwardCriteria } from '../services/AwardService';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const AwardCriteria = () => {
    const handleError = useErrorHandler()
    const {user,updateLoader} = useFilterContext()
    const navigate = useNavigate()
    const { id } = useParams();
    const [award, setAward] = useState(null);
    const [criterias, setCriterias] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

      const getData = async() => {
        try {
            updateLoader(true)
            const result = await fetchAwardCriteria(id);
            if(result)
            {
                setAward(result.award)
                result.criterias.length > 0 ? setCriterias(result.criterias) : addCriteria(1)
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, [id]);

    const onSubmit = async(data) => {
        
        try {
            updateLoader(true)
            const result = await AddOrUpdateAwardCriteria(award.id,{criterias:criterias});
            if(result)
            {
                result.message && toast.success(result.message)
                reset()
                getData()
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    // Function to add a new criteria
    const addCriteria = (number) => {
        const newCriteria = {
        id: 0, 
        text: '',      
        weight: 0,      
        number: number,  
        headings: [
            {
            id: 0, 
            text: '',         
            number: 1,
            questions: [
                {
                id: 0, 
                text: '' ,          
                number:1
                }
            ]
            }
        ]
        };

       
        
        setCriterias([...criterias, newCriteria]);
       
        
    };

    // Function to add a new heading to a specific criteria
    const addHeading = (criteriaIndex,number) => {
        const newCriterias = [...criterias];
        newCriterias[criteriaIndex].headings.push({
          id: 0,
          text: '',
          number:number,
          questions: [
            {
              id: 0,
              text: '',
              number:1
            }
          ]
        });
        setCriterias(newCriterias);
    };

    // Function to add a new question to a specific heading in a criteria
    const addQuestion = (criteriaIndex, headingIndex,number) => {
        const newCriterias = [...criterias];
        newCriterias[criteriaIndex].headings[headingIndex].questions.push({
        id: 0,
        text: '',
        number:number
        });
        setCriterias(newCriterias);
    };

    // Function to handle changes in criteria
    const handleChangeCriteria = (index, event) => {
        const { name, value } = event.target;
        const updatedCriterias = [...criterias];
        updatedCriterias[index] = { ...updatedCriterias[index], [name]: value };
        setCriterias(updatedCriterias);
    };

    // Function to handle changes in heading
    const handleChangeHeading = (criteriaIndex, headingIndex, event) => {
        const { name, value } = event.target;
        const updatedCriterias = [...criterias];
        updatedCriterias[criteriaIndex].headings[headingIndex] = {
        ...updatedCriterias[criteriaIndex].headings[headingIndex],
        [name]: value
        };
        setCriterias(updatedCriterias);
    };

    // Function to handle changes in question
    const handleChangeQuestion = (criteriaIndex, headingIndex, questionIndex, event) => {
        const { name, value } = event.target;
        const updatedCriterias = [...criterias];
        updatedCriterias[criteriaIndex].headings[headingIndex].questions[questionIndex] = {
        ...updatedCriterias[criteriaIndex].headings[headingIndex].questions[questionIndex],
        [name]: value
        };
        setCriterias(updatedCriterias);
    }

  const downloadPdf = async() => {
    try{
        updateLoader(true)
        const url = `${server}/award/criteria_pdf/${id}`;

        // Fetch the PDF file from the endpoint
        const response = await fetch(url, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/pdf',
            'Authorization': user.token ? `Bearer ${user.token}` : null
            }
        });

        if (!response.ok) {
            toast.error('Unexpected Error')
        }else{

            // Convert the response to a blob
            const blob = await response.blob();
            
            // Create a URL for the blob
            const blobUrl = URL.createObjectURL(blob);
            
            // Create a link element
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${award.name}.pdf`; // Set the filename for the download
    
            // Append the link to the document
            document.body.appendChild(link);
            // Programmatically click the link to trigger the download
            link.click();
    
            // Remove the link from the document
            document.body.removeChild(link);
    
            // Revoke the object URL to free up memory
            URL.revokeObjectURL(blobUrl);
        }

    

    } catch (error) {
        handleError(error)
    }finally{
        updateLoader(false)
    }

    
  }

 
  
  return (
    <>
    
    
      <section>
        <div className='card'>
          <div className='card-body'>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <h3>Handle Criteria for {award && award.name}</h3>
                </div>
                <div className="col-md-6 mb-4 ">
                    <button className='btn primary-btn float-end' type="button" onClick={() => downloadPdf()}>Download PDF</button>
                </div>
        
            </div>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            {
               criterias && criterias.length > 0 && criterias.map((criteria, criteriaIndex)  => (
                <div className='criteria-div w-100 px-3 mb-5' key={'criteria-'+criteria.number}>
                    <div className="row">
                        <div className="col-md-7">
                            <label className='fw-bold'>Criteria {criteria.number}</label>
                            <input type="text" className='form-control' required name="text" value={criteria.text}    onChange={(e) => handleChangeCriteria(criteriaIndex, e)} />
                        </div>
                        <div className="col-md-3">
                            <label className='fw-bold'>Criteria Weights</label>
                            <input type="text" className='form-control' value={criteria.weight} name="weight"  onChange={(e) => handleChangeCriteria(criteriaIndex, e)}/>
                        </div>
                        <div className="col-md-2">
                            {
                                criteriaIndex === criterias.length - 1 &&
                                <button className='btn primary-btn mt-4' onClick={() => addCriteria(criteria.number+1)}><i className='bx bx-plus text-light'></i></button>
                            }
                        </div>
                    </div>
                    {
                        criteria.headings && criteria.headings.length > 0 && criteria.headings.map((heading,headingIndex )=> (
                            <div className='heading-div w-100 px-3 mb-5 mt-2' key={'heading-'+heading.number}>
                                 <div className="row">
                                    <div className="col-md-7">
                                        <label className='fw-bold'>Heading {heading.number}</label>
                                        <input type="text" className='form-control' required value={heading.text} name="text" onChange={(e) => handleChangeHeading(criteriaIndex, headingIndex, e)} />
                                    </div>
                                    <div className="col-md-3">
                                        {
                                            headingIndex === criteria.headings.length - 1 &&
                                            <button className='btn primary-btn mt-4' onClick={() => addHeading(criteriaIndex, heading.number+1)}><i className='bx bx-plus text-light'></i></button>
                                        }
                                    </div>
                                </div>
                                {
                                    heading.questions && heading.questions.length > 0 && heading.questions.map((question,questionIndex )=> (
                                        <div className="row px-3 mt-2" key={'question-'+question.number}>
                                            <div className="col-md-7">
                                                <label className='fw-bold'>Question {question.number}</label>
                                                <input type="text" className='form-control' required value={question.text} onChange={(e) => handleChangeQuestion(criteriaIndex, headingIndex, questionIndex, e)} name="text" />
                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    questionIndex === heading.questions.length - 1 &&
                                                    <button className='btn primary-btn mt-4' onClick={() => addQuestion(criteriaIndex, headingIndex, question.number+1)}><i className='bx bx-plus text-light'></i></button>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                        ))
                    }
                </div>
               )) 
            }

            <div className="row">
                <div className="col-12">
                    <button className='btn primary-btn' type="submit">Save</button>
                </div>
            </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default AwardCriteria