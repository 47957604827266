import React from 'react'
import { useFilterContext } from '../context/filterContext'

const Loader = () => {
    const {isLoading} = useFilterContext()

    if(!isLoading) {return null}
  return (
    <>
        <div className='loading-container' >
            <div className="loader-div">

        <div className="loader"></div> 
            </div>

        </div>
    </>
  )
}

export default Loader