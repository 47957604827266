import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFilterContext } from '../../../context/filterContext'
import { fetchSingleInvoice } from '../services/InvoiceService'
import { useErrorHandler } from '../../../hooks/useErrorHandler'
import DepositModal from './inc/DepositModal'
import TransactionModal from './inc/TransactionModal'
import SendEmail from './inc/SendEmail'

const InvoicePreview = () => {
    const {id} = useParams()
    const {updateLoader} = useFilterContext()
    const handleError = useErrorHandler()
    //invoice
    const [invoiceData, setInvoiceData] = useState(null)

    const getData = async() => {
        try {
            updateLoader(true)
            const result = await fetchSingleInvoice(id)
            if(result && result.invoice)
            {
                // console.log(result);
                setInvoiceData(result.invoice)
            
            }
        } catch (error) {
            handleError(error)
        }  finally{
            updateLoader(false)
        } 
    }


    useEffect(() => {
        getData()
    }, [id]);

    const Print = () =>{     
        //console.log('print');  
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents; 
      }

      useEffect(() => {
        // Function to handle after print event
        const handleAfterPrint = () => {
            window.location.reload();
        };
    
        // Add event listener for after print event
        window.addEventListener('afterprint', handleAfterPrint);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('afterprint', handleAfterPrint);
        };
      }, []);

      const transactionSum = (transactions) => {
        return transactions.reduce((total, transaction) => total + transaction.amount, 0);
      };
  return (
    <>
    {
        invoiceData &&
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <h4>Invoice # {invoiceData && invoiceData.invoice_no}</h4>
                            </div>
                            <div className="col-md-6">
                                {
                                    invoiceData &&
                                    <>
                                    <button type="button" className='btn primary-btn float-end ms-2' onClick={() => Print()} > Print</button>
                                    <SendEmail invoiceData={invoiceData} updateLoader={updateLoader} handleError={handleError} />
                                    
                                    {
                                        invoiceData.status !== 3 &&
                                        <Link className='btn primary-btn float-end' to={`/invoice/${invoiceData.id}/edit`}>Edit</Link>
                                    }
                                    
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* print div */}
            <div className="col-md-8 mt-4 " id="printablediv">
                <div className="card">
                    <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h5 className='mb-2'>BILL TO</h5>
                                    <p className='mb-0'>{invoiceData.user.name}</p>
                                    <p className='mb-0'>{invoiceData.user.email}</p>
                                    {
                                        invoiceData.user.mobile && <p className='mb-0'>{invoiceData.user.mobile}</p>
                                    }
                                    {
                                        invoiceData.user.business_address && invoiceData.user.city && invoiceData.user.postal_code && invoiceData.user.country &&
                                        <p className='mb-0'>{invoiceData.user.business_address}, {invoiceData.user.city}, {invoiceData.user.postal_code}, {invoiceData.user.country}</p>
                                    }
                                </div>   
                                <div className="col-sm-6">
                                    <div className="row pt-3">
                                        <div className="col-6">
                                            <b>Invoice#</b>
                                        </div>
                                        <div className="col-6 text-end">
                                            {invoiceData.invoice_no}
                                        </div>
                                        <div className="col-6">
                                            <b>Date</b>
                                        </div>
                                        <div className="col-6 text-end">
                                            {invoiceData.invoice_date_format}
                                        </div>
                                        <div className="col-6">
                                            <b>Due Date</b>
                                        </div>
                                        <div className="col-6 text-end">
                                            {invoiceData.due_date_format}
                                        </div>
                                    </div>
                                </div>        
                            </div>

                            <table className='table mt-3'>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th className='text-end'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        invoiceData.items && invoiceData.items.length > 0 &&
                                        invoiceData.items.map(item => (
                                            <>
                                            <tr>
                                                <td>{item.item_name}</td>
                                                <td>{item.quantity}</td>
                                                <td>
                                                    {
                                                        item.discount > 0 ? 
                                                        <>
                                                        <del>${item.price}</del>
                                                        ${item.price-item.discount}
                                                        </>
                                                        :
                                                        <>
                                                        ${item.price}
                                                        </>
                                                    }
                                                </td>
                                                <td className='text-end'>
                                                ${item.total_price} 
                                                </td>
                                            </tr>
                                            {
                                                item.description &&
                                                <tr>
                                                    <td colSpan={4}>{item.description}</td>
                                                </tr>
                                            }
                                            </>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={2}></td>
                                        <td colSpan={2}>
                                            <div className="row">
                                                <div className="col-6">
                                                    Subtotal
                                                </div>
                                                <div className="col-6 text-end">
                                                ${invoiceData.subtotal}
                                                </div>

                                                {
                                                    invoiceData.total_discount > 0 ?
                                                    <>
                                                        <div className="col-6">Discount</div>
                                                        <div className="col-6 text-end">${invoiceData.total_discount}</div>
                                                    </>
                                                    :''
                                                }
                                                 {
                                                    (invoiceData.tax_code_one && invoiceData.tax_rate_one) ?
                                                    <>
                                                        <div className="col-6">{invoiceData.tax_code_one}{invoiceData.tax_rate_one}%</div>
                                                        <div className="col-6 text-end">$0</div>
                                                    </>
                                                    :''
                                                }
                                                 {
                                                    (invoiceData.tax_code_two && invoiceData.tax_rate_two) ?
                                                    <>
                                                        <div className="col-6">{invoiceData.tax_code_two}{invoiceData.tax_rate_two}%</div>
                                                        <div className="col-6 text-end">$0</div>
                                                    </>
                                                    :''
                                                }
                                                {
                                                    invoiceData.shipping_cost > 0 ?
                                                    <>
                                                        <div className="col-6">Shipping</div>
                                                        <div className="col-6 text-end">${invoiceData.shipping_cost}</div>
                                                    </>
                                                    :''
                                                }
                                                <div className="col-6">
                                                Total
                                                </div>
                                                <div className="col-6 text-end">
                                                ${invoiceData.total}
                                                </div>
                                            </div>
                                            
                                            
                                            </td>
                                    </tr>
                                    {
                                        invoiceData.transactions && invoiceData.transactions.length > 0 &&
                                        <>
                                            <tr>
                                                <td colSpan={2}></td>
                                                <td colSpan={2}>
                                                    <div className="row">
                                                        {
                                                            invoiceData.transactions.map(transaction => (
                                                                <>
                                                                <div className="col-6">
                                                                    Paid On {transaction.date_format}
                                                                </div>
                                                                <div className="col-6 text-end">
                                                                    ${transaction.amount}
                                                                </div>
                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    <tr>

                                        <td colSpan={2}></td>
                                        <td colSpan={2} >
                                            {
                                                (invoiceData.status !== 3 && invoiceData.deposit_per && invoiceData.deposit_per > 0) &&
                                                <div className="row">
                                                    <div className="col-6">
                                                    Amount Due
                                                    </div>
                                                    <div className="col-6 text-end">
                                                    ${invoiceData.amount_due}
                                                    </div>
                                                </div>
                                            }
                                            <div className='bg-secodnary1 py-2 px-2'>
                                                {
                                                    (invoiceData.status !== 3 && invoiceData.deposit_per && invoiceData.deposit_per > 0) ?
                                                    <>
                                                        <p>	Deposit due | {invoiceData.deposit_date_format}</p>
                                                        <p className='text-end mb-0 fs-3 fw-bold'>${invoiceData.deposit_amount}</p>
                                                    </>
                                                    :
                                                    <>
                                                        <p>Amount Due</p>
                                                        <p className='text-end mb-0 fs-3 fw-bold'>${invoiceData.amount_due}</p>
                                                    </>
                                                }
                                            
                                            </div>
                                        </td>
                                    </tr>
                                   
                                </tfoot>
                            </table>
                        <div className="row">
                            <div className="col-12">
                                {
                                    invoiceData.note &&
                                    <>
                                        <p>{invoiceData.note}</p>
                                        <hr />
                                    </>
                                }
                            </div>
                            <div className="col-12 mb-3">
                                {
                                    invoiceData.images && invoiceData.images.length > 0 &&
                                    <>
                                    {
                                         invoiceData.images.map(image => (
                                            <img src={image.original_url} style={{display:'inline-block',width:'200px',heigt:'auto',margin:'3px'}} alt="Image" />
                                        ))
                                    }
                                    <hr />
                                    </>
                                   
                                }
                            </div>

                            
                                {
                                    invoiceData.admin_signature_status == 1 &&
                                    <>
                                        
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                            By signing this document, the customer agrees to the services and conditions described in this document.
                                            </div>
                                            <div className="col-md-6 text-center">
                                                {
                                                    invoiceData.created_by && invoiceData.created_by.signature_path &&
                                                    <>
                                                    <p>HBC International Awards Foundation</p>
                                                    <img src={invoiceData.created_by.signature_path} style={{height:'100px',width:'auto'}} />
                                                    <pre>{ invoiceData.created_at }</pre>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    
                                    </>
                                }
                        </div>
                    {/* card body */}
                    </div>
                </div>
            </div>

            {/* transactiondiv */}
            <div className="col-md-4 mt-4">
                <div className="card">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-6 mb-2">
                                Total
                            </div>
                            <div className="col-6 mb-2">
                                ${invoiceData.total}
                            </div>
                            <div className="col-6 mb-2">
                                Paid
                            </div>
                            <div className="col-6 mb-2">
                                ${transactionSum(invoiceData.transactions)}
                            </div>
                            <div className="col-12 mb-2">
                                <DepositModal invoiceData={invoiceData} getData={getData} handleError={handleError} updateLoader={updateLoader} />
                            </div>
                            <div className="col-12 mb-2">
                                <TransactionModal invoiceData={invoiceData} getData={getData} handleError={handleError} updateLoader={updateLoader} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }
    
    </>
  )
}

export default InvoicePreview