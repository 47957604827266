import { useEffect, useState } from 'react';

// Replace with your actual RECAPTCHA_KEY and RECAPTCHA_TOKEN values
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
const RECAPTCHA_TOKEN = 'token';

const showBadge = () => {
  if (!window.grecaptcha) return;
  window.grecaptcha.ready(() => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (!badge) return;
    badge.style.display = 'block';
    badge.style.zIndex = '1';
  });
};

const hideBadge = () => {
  if (!window.grecaptcha) return;
  window.grecaptcha.ready(() => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (!badge) return;
    badge.style.display = 'none';
  });
};

const useReCaptcha = () => {
  const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false);

  // Load ReCaptcha script
  useEffect(() => {
    if (typeof window === 'undefined' || reCaptchaLoaded) return;
    if (window.grecaptcha) {
      showBadge();
      setReCaptchaLoaded(true);
      return;
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
    script.addEventListener('load', () => {
      setReCaptchaLoaded(true);
      showBadge();
    });
    document.body.appendChild(script);
  }, [reCaptchaLoaded]);

  // Hide badge when unmount
  useEffect(() => {
    return () => {
      hideBadge();
    };
  }, []);

  // Get token
  const generateReCaptchaToken = (action) => {
    return new Promise((resolve, reject) => {
      if (!reCaptchaLoaded) return reject(new Error('ReCaptcha not loaded'));
      if (typeof window === 'undefined' || !window.grecaptcha) {
        setReCaptchaLoaded(false);
        return reject(new Error('ReCaptcha not loaded'));
      }
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(RECAPTCHA_KEY, { action }).then((token) => {
          localStorage.setItem(RECAPTCHA_TOKEN, token);
          resolve(token);
        });
      });
    });
  };

  return { reCaptchaLoaded, generateReCaptchaToken };
};

export default useReCaptcha;
