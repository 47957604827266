import React,{useState} from 'react'
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DropZone from '../../../Components/DropZone';
import { createTeam } from '../services/TeamService';
import FilesPreview from '../../../Components/FilesPreview';
import { useFilterContext } from '../../../context/filterContext';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const TeamAdd = () => {
    const handleError = useErrorHandler()
    const {updateLoader} = useFilterContext()
    const [images, setImages] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

    const onSubmit = async(data) => {
        if(images.length === 0)
        {
            toast.error('Team Member Image is required');
        }else{
            try {
                updateLoader(true)
                const formData = new FormData();
                formData.append('name', data.name);
                formData.append('post', data.post);
                formData.append('facebook', data.facebook);
                formData.append('instagram', data.instagram);
                formData.append('twitter', data.twitter);
                formData.append('date_added', data.date_added);
                if(images && images[0])
                {
                    formData.append('image', images[0]);
                }
    
                const result = await createTeam(formData)
                if(result)
                {
                    result.message && toast.success(result.message)
                    reset()
                    setImages([])
                }
            
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
        
    }


  return (
    <>
    
      <section>
        <div className='card'>
          <div className='card-body'>
          <div className="row">
        <div className="col-md-6 mb-4">
            <h3>Add Team</h3>
        </div>
        <div className="col-md-6 mb-4">
        </div>
        
    </div>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className='row'>
                    {/* Name */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">Name</label>
                        <input type="text" className="form-control" {...register('name', { required: true})} />
                        {errors.name && <p className='error-msg'>Title is required</p>}
                    </div>
                    {/* Post */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">Post</label>
                        <input type="text" className="form-control" {...register('post', { required: true})} />
                        {errors.post && <p className='error-msg'>Title is required</p>}
                    </div>
                     {/* Facebook */}
                     <div className='col-md-6 mb-3'>
                        <label  className="form-label">Facebook</label>
                        <input type="text" className="form-control" {...register('facebook')} />
                    </div>
                     {/* Instagram */}
                     <div className='col-md-6 mb-3'>
                        <label  className="form-label">Instagram</label>
                        <input type="text" className="form-control" {...register('instagram')} />
                    </div>
                     {/* Twitter */}
                     <div className='col-md-6 mb-3'>
                        <label  className="form-label">Twitter</label>
                        <input type="text" className="form-control" {...register('twitter')} />
                    </div>
                    {/* Date */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">Date</label>
                        <input type="datetime-local" className="form-control" {...register('date_added', { required: true})}  />
                        {errors.date_added && <p className='error-msg'>Date is required</p>}
                    </div>
                    
                    {/* Award Image */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">Team Member Image</label>
                        {
                            images.length < 1 &&
                            <>
                            <DropZone setComponentFiles={setImages} componentFiles={images} allowedFileTypes={['image/png','image/jpg','image/jpeg']}/>
                            <small>Only png, jpg, jpeg are allowed</small>
                            </>
                        }
                        <FilesPreview files={images} setFiles={setImages} />
                    </div>
                   
                      

                    <div className="col-12"><button className="btn primary-btn" type="submit">Save</button></div>

                </div>
            </form>
          </div>
        </div>
      </section>

      
    </>
  )
}

export default TeamAdd