import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import { fetchPressReleases } from '../services/UserService';
import { useFilterContext } from '../../../context/filterContext';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const UserPressReleases = () => {
    const handleError = useErrorHandler()
    const {updateLoader} = useFilterContext()
    const [pressReleases, setPressReleases] = useState(null);


    const getData = async() => {
        try {
            updateLoader(true)
            const result = await fetchPressReleases();
            if(result)
            {
                setPressReleases(result)
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);

   
    
  return (
    <>
   
      <section>
      <div className="row">
        <div className="col-md-6 mb-4">
            <h3>Press Release</h3>
        </div>
        <div className="col-md-6 mb-4">
        </div>
        
    </div>
            <div className='row'>
                {
                    pressReleases && pressReleases.data && pressReleases.data.length > 0 && pressReleases.data.map((item, index) => (
                        <div className='col-sm-6 mb-3'>
                            <div className="card" key={index}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6 fs-5 pt-2">
                                        Press Release {++index}
                                        </div>
                                        <div className="col-6 text-end">
                                        <Link to={item.press_release_file} target="_blank" className='btn primary-btn'>
                                            Download
                                        </Link>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
      </section>

     
    </>
  )
}

export default UserPressReleases