import React,{useState,useEffect} from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { fetchBlogs,deleteBlog } from '../services/BlogService';
import { useHasPermission } from '../../../util/useHasPermission';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import ReactPaginate from 'react-paginate';

const BlogList = () => {
  const handleError = useErrorHandler()
    const {updateLoader} = useFilterContext()
    const [blogs, setBlogs] = useState(null);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const canAddBlog = useHasPermission('add_blog')
    const canEditBlog = useHasPermission('edit_blog')
    const canDeleteBlog = useHasPermission('delete_blog')

    const getData = async(pageNumber, search='') => {
        try {
          updateLoader(true)
            const params = {
                'page' :pageNumber,
                'search':search
            }
            const data = await fetchBlogs(params)
            setBlogs(data);
        } catch (error) {
          handleError(error)
        }finally{
          updateLoader(false)
        }
    }

    useEffect(() => {
        getData(page, search);
    }, [page]);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        getData(1, search); 
    };

    const clearSearch = () => {
        setSearch('')
        setPage(1);
        getData(1); 
    }

    const handleDelete = async(id) => {
        const isConfirmed = window.confirm("Are you sure you want to perform this action?");
        if(isConfirmed)
        {
          
          try {
              updateLoader(true)
              const result = await deleteBlog(id)
              if(result)
              {
                  result.message && toast.success(result.message)
                  getData(1); 
              }
          } catch (error) {
            handleError(error)
          }finally{
            updateLoader(false)
          }
        }
    }
  return (
    <>
      <section>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-4'>
              <div className="col-md-4 mb-3">
                  <h3>All Blogs</h3>
              </div>
       
              <div className='col-md-4 mb-3'>
                <div className="input-group search-area">
                  <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                  <span className="input-group-text">
                    <button type="submit" className="btn" onClick={(e) => handleSearchSubmit(e)}>
                      <i className='bx bx-search'></i>
                    </button>
                      
                  </span>
                  {
                    search &&
                    <i className='bx bx-x fs-3 text-danger mt-2 ms-2 cursor-pointer' onClick={(e) => clearSearch(e)}></i>
                  }
                </div>
              </div>
              <div className="col-md-4 mb-3">
                  {
                    canAddBlog &&
                    <Link to="/blog/create" className='btn primary-btn float-end'>Add Blog</Link>
                  }
                </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='table-responsive'>
                  <table className='custom-table'>
                    <thead>
                      <tr>
                        <th>Icon</th>
                        <th>Title</th>
                        <th>Short Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        
                     {
                        blogs && blogs.data && blogs.data.length > 0 && blogs.data.map(item => (

                        <tr key={item.id}>
                            <td><img src={item.image} height="100" width="100"  /></td>
                            <td>{item.title}</td>
                            <td><div  dangerouslySetInnerHTML={{__html:item.short_description}} /></td>
                            <td className='text-no-wrap'>
                                <div className='action-buttons'>
                                  {
                                     canEditBlog &&
                                    <Link className='edit-btn m-1'to={"/blog/edit/"+item.id} >
                                    <i className='bx bxs-edit'></i> Edit
                                    </Link>
                                  }
                                  {
                                    canDeleteBlog &&
                                    <button className='del-btn m-1' type="button" onClick={() => handleDelete(item.id)}>
                                    <i className='bx bxs-trash'></i> Delete
                                    </button>
                                  }
                                </div>
                            </td>
                        </tr>
                        ))
                     }
                    </tbody>
                  </table>
                
                </div>
              </div>

            {
                blogs && blogs.meta && blogs.meta.last_page > 1 &&
                <div className="col-12">
                     <ReactPaginate
                        pageCount={blogs.meta.last_page}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={(event) => {
                            setPage((event.selected)+1);
                        }}
                        containerClassName={"pagination"}
                        pageClassName={"page-link"}
                        activeClassName={"active"}
                        previousLabel='<'
                        nextLabel='>'
                    />
                </div>
            }
            </div>
          </div>
        </div>
      </section>

     
    </>
  )
}

export default BlogList