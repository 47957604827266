import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Select from 'react-select'


const InvoiceFilterBar = ({setSearch}) => {
    const [statusOptions, setStatusOptions] = useState([{label:'Draft',value:0},{label:'Sent',value:1},{label:'Partial Paid',value:2},{label:'Paid',value:3}]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [overdue, setOverdue] = useState(false);
    const {
        register,
        handleSubmit,
        reset
      } = useForm();


      const onSubmit = async(data) => {
        if(selectedStatus.length > 0)
        {
            const statusIds = selectedStatus.map(item => item.value);
            data['status_filter'] = statusIds
        }
        if(overdue)
        {
          data['filter_amount'] = 1
        }
        setSearch(data)
    }

    const clearFilter = () => {
        reset()
        setSelectedStatus([])
        setOverdue(false)
        setSearch({})

    }
  return (
    <>
    
        <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row'>
                <div className="col-md-3 mb-3">
                    <input type="text" className="form-control" {...register('search')} placeholder="Search..." />
                </div>
                <div className="col-md-1">
                  <div className=' d-grid'>

                    <button type="button" onClick={() => setOverdue(!overdue)} className={`btn p-2 ${overdue ? 'primary-btn' :  'primary-btn-outline'}`}>Overdue</button>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                        <Select
                            options={statusOptions}
                            value={selectedStatus}
                            onChange={setSelectedStatus}
                            labelledBy="select"
                            isMulti 
                        />
                </div>
                
              
                <div className="col-md-3 mb-3">
                    <button type="submit" className='btn primary-btn'>Search</button>
                    <button type="button" onClick={() => clearFilter()} className='btn info-btn ms-2'>Reset</button>
                </div>
              
              </div>
        </form>
    
    
    </>
  )
}



export default InvoiceFilterBar