import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { server } from '../../../../util/Server';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../../util/capitalizeFirstLetter';
import { UpdateAwardStatus } from '../../services/ApplicationService';

const AwardStatusUpdateModal = ({award,appUser,getData,handleError,updateLoader}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const navigate = useNavigate()
    const [modalToggle, setModalToggle] = useState(false);
    const [modalData, setModalData] = useState(null);
    const openModal = (data=null) => { setModalToggle(true);setModalData(data)};
    const closeModal = () => { setModalToggle(false);setModalData(null) };
    useEffect(() => {
        reset({ status: modalData ? modalData.status : '' })
    }, [modalData, reset]);

    const onSubmit = async(data) => {
        try {
            updateLoader(true)
            data['award_id'] = award.id
            data['user_id'] = appUser.id
            const result = UpdateAwardStatus(data)
            if(result)
            {
                toast.success(result.message)
                reset();
                closeModal()
                getData()
            }
          
           
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }
    const getButtonColorBasedOnStatus = (status) => {
        switch(status){
            case 'pending':
                return 'bg-warning';
            case 'approved':
                return 'bg-success';
            case 'rejected':
                return 'bg-danger';
            default:
                return 'bg-secondary'
        }

    }
  return (
    <>
    <button type="button" onClick={() => openModal(award)} className='btn primary-btn float-end ms-1 mb-1'>{capitalizeFirstLetter(award.status)}</button>
    
     {/* Add Modal */}
     <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Select Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Name */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Status</label>
                        <select className='form-control' {...register('status', { required: true})}>
                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="rejected">Rejected</option>
                            <option value="assessed">Assessed</option>
                        </select>
                    </div>

                    <div className="col-12"><button className="btn primary-btn" type="submit">Save</button></div>

                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default AwardStatusUpdateModal