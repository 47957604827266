
import { _get, _post, _patch, _delete } from '../../../Client/apiClient'; 


// Fetch all blogs
export const fetchBlogs = async (params) => {
   
        const data = await _get(`/blog`, params);
        return data;
   
};

// Fetch single blogs
export const fetchSingleBlog = async (id) => {
   
        const data = await _get(`/blog/edit/${id}`);
        return data;
    
};


export const createBlog = async (formData) => {
   
        const data = await _post('/blog/store',formData);
        return data;
    
};

export const updateBlog = async (formData,id) => {

        const data = await _post(`blog/update/${id}`, formData);
        return data;
   
};


export const deleteBlog = async (id) => {
  
      const data = await _delete(`/blog/delete/${id}`);
      return data;
   
 };



