import React,{useState} from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

const ShippingAndDiscountModal = ({discount,shipping,setDiscount,setShipping}) => {
    const [modalShiping, setModalShipping] = useState(shipping)
    const [modalDiscount, setModalDiscount] = useState(discount)
  
    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false)};
    

    const saveShippingModalData = async(e) => {
        e.preventDefault()
        try {
            setDiscount(modalDiscount)
            setShipping(modalShiping)
            closeModal()
        } catch (error) {
           toast.error('Unexpected error')
        }
    }
  return (
    <>
    <p onClick={() => openModal()} data-toggle="modal" className="mt-3 d-block color-primary cursor-pointer"><u>Discount & Shipping</u></p>
    

    {/* Add Modal */}
    <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Discount & Shipping cost</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form >
                    <div className="row">
                        {/* Name */}
                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Discount (in $)</label>
                            <input type="text" value={modalDiscount} onChange={(e) => setModalDiscount(e.target.value)} className='form-control' />
                            
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Shipping cost (in $)</label>
                            <input type="text" value={modalShiping} onChange={(e) => setModalShipping(e.target.value)} className='form-control' />
                            
                        </div>


                        <div className="col-12"><button className="btn primary-btn" type="button" onClick={e => saveShippingModalData(e)}>Save</button></div>
                    </div>


                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}


export default ShippingAndDiscountModal