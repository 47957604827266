import React,{useState,useEffect} from 'react'
import { Modal } from 'react-bootstrap';

const JudgeDetailModal = ({detail}) => {
    const [modalToggle, setModalToggle] = useState(false);
    const [modalData, setModalData] = useState(null);
    const openModal = (data=null) => { 
        setModalToggle(true);
        setModalData(data)
    };
    const closeModal = () => { setModalToggle(false);setModalData(null) };
  return (
    <>

            <button className='edit-btn m-1' type="button" onClick={() => openModal(detail)}>
                Details
            </button>

             {/* Add Modal */}
     <Modal show={modalToggle} onHide={() => closeModal()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Judge Application Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <b>Company: </b>{modalData && modalData.company}
                    </div>
                    <div className="col-md-6 mb-2">
                        <b>Industry: </b>{modalData && modalData.industry}
                    </div>
                    <div className="col-md-6 mb-2">
                        <b>Name: </b>{modalData && modalData.name}
                    </div>
                    <div className="col-md-6 mb-2">
                        <b>Job: </b>{modalData && modalData.job}
                    </div>
                    <div className="col-md-6 mb-2">
                        <b>Email: </b>{modalData && modalData.email}
                    </div>
                    <div className="col-md-6 mb-2">
                        <b>Phone: </b>{modalData && modalData.phone}
                    </div>
                    <div className="col-md-6 mb-2">
                        <b>Country: </b>{modalData && modalData.country}
                    </div>
                    <div className="col-md-6 mb-2">
                        <b>URL: </b>{modalData && modalData.url}
                    </div>
                    <div className="col-md-12">
                        <b>Comments: </b>{modalData && modalData.comments}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default JudgeDetailModal