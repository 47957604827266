import React,{useEffect,useState} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import axios from "axios";
import { server } from '../../../util/Server';
import { useFilterContext } from '../../../context/filterContext';
import { baseUrl } from '../../../util/Server';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import useReCaptcha from '../../../hooks/useReCaptcha';


const RegistrationForm = () => {
    const navigate = useNavigate();
    const handleError = useErrorHandler()
    const { setUserLogin, user, updateLoader } = useFilterContext();
    const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha()
    const [agreeToTerms, setAgreeToTerms] = useState(false)
    useEffect(() => {
        if (user) {
          navigate("/dashboard");
        }
    }, [user]);

    console.log(process.env.REACT_APP_RECAPTCHA_KEY);
    

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

    const onSubmit = async(data) => {
        if(!agreeToTerms)
        {
            toast.error('Please agree to our terms & conditions')
        }else{
            try {
                updateLoader(true)
                const token = await generateReCaptchaToken('login_action');
                data['g-recaptcha'] = token
                const res = await axios.post(server + "/auth/register", data);
                const result = await res.data
                setUserLogin(result);
                navigate("/dashboard");
            } catch (error) {
                handleError(error)
            }finally{
                updateLoader(false)
            }
        }
    }
      
  return (
    <>
        <div className="fix-wrapper">
            <div className="container">
               
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6">
                        <Link to={`${baseUrl}`}> <img src="/icon.png" width="60px" className='d-block m-auto mb-3' /></Link>
                       
                        <div className="login-form card mb-0 h-auto pb-3 pt-3 mt-5">
                            <div className="card-body px-5">
                                <h4 className="text-center mb-0">Register</h4>
                                <p className='text-center mb-4'>Create New Account</p>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Name</label>
                                        <input type="text" className="form-control" placeholder="Enter Full Name" {...register('name', { required: true})} />
                                        {errors.name && <p className='error-msg'>Name is required</p>}
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Email</label>
                                        <input type="email" className="form-control" placeholder="Enter Email Address" {...register('email', { required: true})} />
                                        {errors.email && <p className='error-msg'>Email is required</p>}
                                    </div>
                                    <div className="mb-sm-4 mb-3 position-relative">
                                        <label className="form-label" >Passcode</label>
                                        <input type="password" placeholder='Enter Passcode' className="form-control" {...register('password', { required: true})} />
                                        {errors.password && <p className='error-msg'>Passcode is required</p>}
                                    </div>
                                    <div className="mb-sm-4 mb-3 position-relative">
                                        <label className="form-label" >Confirm Password</label>
                                        <input type="password" placeholder='Enter Confirm Password' className="form-control" {...register('password_confirmation', { required: true})} />
                                        {errors.password_confirmation && <p className='error-msg'>Confirm Password is required</p>}
                                    </div>

                                    <div className="mb-sm-4 mb-3 position-relative">
                                    <div className="form-check mt-2">
                                        <input className="form-check-input" type="checkbox" value="1" checked={agreeToTerms}  onChange={() => setAgreeToTerms(!agreeToTerms)} id="agree" />
                                        <label className="form-check-label" htmlFor="agree">
                                        I have read and agreed to HBC<Link to="https://hbcworld.org/terms-conditions" className='color-primary'> Terms</Link>, conditions and<Link className='color-primary' to="https://hbcworld.org/privacy-policy"> privacy policy</Link></label>
                                        </div>
                                    </div>
                                    
                                    <div className="text-center">
                                        <button type="submit" className="btn primary-btn w-100" disabled={!reCaptchaLoaded}>Register</button>
                                    </div>
                                    <p className='text-center mt-3'>Already have an account? <Link to="/" className='color-primary'>Sign in instead</Link></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default RegistrationForm