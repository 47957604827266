import { _get, _post, _delete } from '../../../Client/apiClient'; // Adjust the import path as necessary


// Fetch all permissions
export const fetchPermissions = async (params) => {
  
    const data = await _get('/permission/all',params);
    return data;
 
};

//create new permission
export const createPermission = async (roleData) => {
 
        const data = await _post('/permission/store',roleData);
        return data;
   
};

// Update permission
export const updatePermission = async (roleData) => {

        const data = await _post(`permission/edit`, roleData);
        return data;
   
};

// Delete permission
export const deletePermission = async (id) => {

      const data = await _delete(`/permission/delete/${id}`);
      return data;
   
  };


