import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AddNewItem } from '../../services/InvoiceService';

const AddNewItemModal = ({addToInvoiceItems,updateLoader,handleError}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();


    const [modalToggle, setModalToggle] = useState(false);
    const openModal = () => { setModalToggle(true)};
    const closeModal = () => { setModalToggle(false)};
    

    const onSubmitInvoiceItem = async(data, e) => {
        e.preventDefault(); // Prevents the form from submitting the parent form
        try {
            updateLoader(true)
            const result = await AddNewItem(data);
            if(result)
            {
                result.message && toast.success(result.message)
                reset();
                closeModal()
                result.item && addToInvoiceItems(result.item);

            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }
  return (
    <>
    <li className='border-top' onClick={() => openModal()}><i className='bx bx-plus' ></i> Create a new item</li>
    

    {/* Add Modal */}
    <Modal show={modalToggle} onHide={() => closeModal()} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Add Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form >
                    <div className="row">
                        {/* Name */}
                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Name</label>
                            <input type="text" {...register('item_name',{required:true})} className='form-control' />
                            
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Product Code</label>
                            <input type="text" {...register('product_code')} className='form-control' />
                            
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Price</label>
                            <input type="text" {...register('price',{required:true})} className='form-control' />
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label  className="form-label">Unit Type</label>
                            <select className='form-control' {...register('unit_type')}>
                                <option value="None">None</option>
                                <option value="Hour">Hour</option>
                                <option value="Day">Day</option>
                            </select>
                        </div>

                        <div className='col-md-12 mb-3'>
                            <label  className="form-label">Description</label>
                            <textarea className='form-control' {...register('description')}></textarea>
                        </div>

                        <div className="col-12"><button className="btn primary-btn" type="button" onClick={handleSubmit(onSubmitInvoiceItem)}>Save</button></div>
                    </div>


                </form>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default AddNewItemModal