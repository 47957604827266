import React, { useState,useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import NavLinks from './NavLinks'
import { useFilterContext } from '../../context/filterContext'
const Header = () => {
    const [mobileSideBar, setMobileSideBar] = useState(false)
    const { user, unsetUserLogin } = useFilterContext();
    const navigate = useNavigate()
    useEffect(() => {
        if(!user){
          navigate('/')
        }
    }, [user])
    const getRoleNames = (roles) => {
        return roles.map(role => role.name).join(', ')
    }
    const getNameInitial = (name) => name.slice(0, 2);
    return (
        <>
            <Sidebar setMobileSideBar={setMobileSideBar} authUser={user} />
            <div className='header '>
                <div className='header-items'>
                    <div className="header">
                        <div className="header-content">
                            <nav className="navbar navbar-expand">
                                <div className="collapse navbar-collapse justify-content-between">
                                    <div className="header-left">
                                        <i className='bx bx-menu dash-icon cursor-pointer fs-1' onClick={() => setMobileSideBar(true)}></i>
                                    </div>
                                    <ul className="navbar-nav header-right">
                                        


                                        <li className="nav-item dropdown header-profile">
                                            <Link className="nav-link " to="#" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                                            <p className='d-inline-block mb-0'>
                                            <i className='bx bxs-user-circle fs-1 color-primary'></i>

                                            </p>
                                            <p className='d-inline-block mb-0'>
                                            <span className='color-primary'>{getRoleNames(user.roles)}</span><br />
                                            <strong>{user && user.name} <i className='bx bx-chevron-down'></i></strong>
                                            </p>
                                                 
                                                
                                            </Link>
                                            <div className="dropdown-menu dropdown-menu-end " data-bs-popper="static" style={{minWidth:'300px'}}>
                                                <Link>
                                                        <div className="card-inner p-4 border-bottom">
                                                        <div className="card-user">
                                                            <div className="user-avatar bg-primary1 color-light">
                                                                <span className='fs-5 text-uppercase'>{user && getNameInitial(user.name)}</span>
                                                            </div>
                                                            <div className="user-info">
                                                                <span className="d-block fs-5 fw-bold">{user && user.name}</span>
                                                                <span className="d-block">{user && user.email}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="/user/detail" className="dropdown-item ai-icon py-3">
                                                    <i className='bx bx-user fs-5 color-primary'></i>
                                                    <span className="ms-2">Profile </span>
                                                </Link>
                                                <Link to="#" onClick={() => unsetUserLogin()} className="dropdown-item ai-icon py-3">
                                                    <i className='bx bx-log-out fs-5 color-primary'></i>
                                                    <span className="ms-2">Logout </span>
                                                </Link>
                                            </div>
                                        </li>


                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            {
                mobileSideBar &&
                <div className='mobile-overlay-div'>
                    <div className="mobile-header-active mobile-header-wrapper-style sidebar-visible">
                        <div className="mobile-header-wrapper-inner">
                            <div className="mobile-header-top">
                                <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                                    <i className='bx bx-x fs-1 cursor-pointer' onClick={() => setMobileSideBar(false)}></i>
                                </div>
                            </div>
                            <div className="mobile-header-content-area">
                                <div className="mobile-menu-wrap mobile-header-border">
                                <NavLinks setMobileSideBar={setMobileSideBar} authUser={user}  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Header