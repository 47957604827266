import React from 'react'
import pdf from '../../../../img/pdf.png'
import doc from '../../../../img/doc.png'
import { Link } from 'react-router-dom';

const MediaFiles = ({files}) => {
    const getFileIcon = (file) => {
        if (file.extension == 'pdf') {
          return pdf; // Return image icon for image files
        } else if (file.extension == 'docx') {
          return doc; // Return PDF icon for PDF files
        } else {
          return file.original_url
        }
        return null; // Return null if no icon matches
    };

  return (
    <div className="media-files">
        {files.length > 0 ? (
        files.map((file, index) => {
            const fileIcon = getFileIcon(file);
            
            return (
            <div key={index} className="d-inline-block m-2">
                <Link to={file.original_url} target="_blank" >
                <img src={`${fileIcon}`} width="100px"/>
                </Link>
            </div>
            );
        })
        ) : (
        <p>No files uploaded.</p>
        )}
    </div>
  )
}

export default MediaFiles