import React,{useState,useEffect} from 'react'
import { useFilterContext } from '../../../context/filterContext';
import { toast } from 'react-toastify';
import { Link,useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import DropZone from '../../../Components/DropZone';
import FilesPreview from '../../../Components/FilesPreview';
import { fetchSingleAward, updateAward } from '../services/AwardService';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

const EditAward = () => {
    const handleError = useErrorHandler()
    const {updateLoader} = useFilterContext()
    const { id } = useParams();
    const [award, setAward] = useState(null);
    const [images, setImages] = useState([]);
    const [modalImages, setModalImages] = useState([]);
    const [docFile, setDocFile] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

      const getData = async() => {
        try {
            updateLoader(true)
            const result = await fetchSingleAward(id);
            setAward(result.data)
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, [id]);

    useEffect(() => {
        if(award){
            reset({
                name: award.name || '',
                description: award.description || '',
                date_added: award.date_added || ''
            });
        }
    }, [award])

    const onSubmit = async(data) => {
        try {
            updateLoader(true)
            const formData = new FormData();
    
            formData.append('name', data.name);
            formData.append('description', data.description);
            formData.append('date_added', data.date_added);
            if(images && images.length > 0)
            {
                formData.append('image', images[0]);
            }
            if(modalImages && modalImages.length > 0)
            {
                formData.append('model_image', modalImages[0]);
            }
            if(docFile && docFile.length > 0)
            {
                formData.append('word_file', docFile[0]);
            }
           
            const result = await updateAward(id,formData);
            if(result)
            {
                result.message && toast.success(result.message)
                reset()
                getData()
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }
  return (
    <>
    
      <section>
        <div className='card'>
          <div className='card-body'>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <h3>Edit award</h3>
                </div>
                <div className="col-md-6 mb-4">
                </div>
                
            </div>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className='row'>
                    {/* Name */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Name</label>
                        <input type="text" className="form-control" {...register('name', { required: true})} />
                        {errors.name && <p className='error-msg'>Name is required</p>}
                    </div>
                   
                    {/* Description */}
                    <div className='col-sm-12 mb-3'>
                        <label  className="form-label">Description</label>
                        <input type="text" className="form-control" {...register('description', { required: true})}  />
                        {errors.description && <p className='error-msg'>Description is required</p>}
                    </div>
                    {/* Date */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">Date</label>
                        <input type="datetime-local" className="form-control" {...register('date_added', { required: true})}  />
                        {errors.date_added && <p className='error-msg'>Date is required</p>}
                    </div>
                    <div className="col-md-6"></div>
                     
                    {/* Award Image */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">Award Image</label>
                        {
                            images.length < 1 &&
                            <>
                            <DropZone setComponentFiles={setImages} componentFiles={images} allowedFileTypes={['image/png','image/jpg','image/jpeg']}/>
                            <small>Only png, jpg, jpeg are allowed</small>
                            </>
                        }
                        <FilesPreview files={images} setFiles={setImages} />
                    </div>
                    {/* Award Image */}
                    <div className='col-md-6 mb-3'>
                        {
                            award && award.image && award.image != '' && <img src={award.image} height="100px" className='mb-3 float-end' />
                        }
                    </div>
                    {/* Award Model Image */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">Award Model Image</label>
                        {
                            modalImages.length < 1 &&
                            <>
                            <DropZone setComponentFiles={setModalImages} componentFiles={modalImages} allowedFileTypes={['image/png','image/jpg','image/jpeg']}/>
                            <small>Only png, jpg, jpeg are allowed</small>
                            </>
                        }
                        <FilesPreview files={modalImages} setFiles={setModalImages} />
                    </div>
                     {/* Award Model Image */}
                     <div className='col-md-6 mb-3'>
                        {
                            award &&  award.modal_image && award.modal_image != '' && <img src={award.modal_image} height="100px" className='mb-3 float-end' />
                        }
                    </div>
                    {/* Award Model Image */}
                    <div className='col-md-6 mb-3'>
                        <label  className="form-label">Award Word file {award && award.award_file && award.award_file != '' && <Link to={award.award_file} download className='text-primary'>(Download Word File)</Link>}</label>
                        {
                            docFile.length < 1 &&
                            <>
                            <DropZone setComponentFiles={setDocFile} componentFiles={docFile} allowedFileTypes={[]}/>
                            <small>Only word file is allowed</small>
                            </>
                        }
                        <FilesPreview files={docFile} setFiles={setDocFile} />

                    </div>
                    

                    <div className="col-12"><button className="btn primary-btn" type="submit">Save</button></div>

                </div>
            </form>
          </div>
        </div>
      </section>

      
    </>
  )
}

export default EditAward